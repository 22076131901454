import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFetch } from "../Global/useFetch";
import AddItemCategory from "../Inventory/AddItemCategory";
import { useFetch1 } from "../Global/useFetch1";


const AddItem = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  const [key, setkey] = useState("");
  const [icid, seticid] = useState("");


  // Form Fields
  const [formField, setformField] = useState({ des: "", item: "" });
  const [item_type, setitem_type] = useState('product');
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //  Add Item Function
  //  Add Item Function
  function itemAdd(e) {
    e.preventDefault();
    if (formField.des && formField.item && item_type) {
      setissending(true);
      const data = new FormData();
      data.append("details", formField.des);
      data.append("item", formField.item);
      data.append("sid", key);
      data.append("icid", icid);
      data.append("type", item_type);
      data.append("usertoken", token);
      data.append("stoken", stoken);
      data.append("btoken", btoken);
      data.append("apptoken", apptoken);
      axios
        .post(`${endpoint}/addItem`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.getItemFn();
            props.onHide()
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }

  //  Get All Supplier

  const datas = { btoken, stoken }

  const { loading, data, fetchData } = useFetch('getSuppliers', datas)

  const suppliers = data?.suppliers?.map(({ name, id }, i) => {
    return (
      { label: name, value: id }
    )
  });
  //  Get All Supplier

  //  Get All Item Categories

  const { loading1, data1, fetchData1 } = useFetch1('getItemCategories', datas)

  const categories = data1?.data?.map(({ category, id }, i) => {
    return (
      { label: category, value: id }
    )
  });
  //  Get All Item Categories

  //  Add Item Function
  //  Add Item Function
  function clear() {
    setformField({ item: "", des: "" })
    fetchData()
  }


  // Open Add Item Category Modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);

  // Close Add Inventory Modal
  const [removeaddinvent, setremoveaddinvent] = useState("");
  const handleremoveaddinvent = () => setremoveaddinvent(props.onHide);

  function workModal() {
    handleShowremove();
    handleremoveaddinvent();
  }
  // Open Add Item Category Modal

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Create a new Item
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">

                    {loading && <span className="d-flex justify-content-center align-items-center"><i className="bx bx-loader bx-spin bx-md pry-text mr-3" /> Loading Data... </span>}

                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Category:</label>
                                            <Select
                          options={categories}
                          isSearchable={true}
                          onChange={e => seticid(e.value)}
                          placeholder='Select category'

                        />
                        <p
                          className="red-text text-right pointer small"
                          onClick={(e) => workModal()}
                        >
                          Category not found? Click here to add new
                        </p>
                      </div>
                      <div className="col-md-12 ">
                        <label className="mb-0"> Item name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="item"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.item}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="mb-0"> Supplier <span className="small"> <i> (optional)</i></span>:</label>

                        <Select
                          options={suppliers}
                          isSearchable={true}
                          onChange={e => setkey(e.value)}
                          placeholder='Select supplier'

                        />
                      </div>
                      <div className="col-md-12 ">
                        <label className="mb-0"> Details:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="des"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.des}
                        />
                      </div>


                      <div className="col-md-12">
                        <label className="mb-2"> Item Type:</label>
                        <small className="ml-2 text-uppercase">{item_type} </small>
                        <div className="d-flex">
                          <div className="col-md-6 sec-bold pry-bold-text font-weight-bold p-3 mb-4 ml-1 ">
                            <div className="custom-control custom-radio mb-2 ">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="groupOfDefaultRadios"
                                id="product"
                                onChange={e => setitem_type(e.target.value)}
                                value="product"
                                defaultChecked
                              />
                              <label className="custom-control-label" for="product">
                                <span className="text-uppercase">
                                  PRODUCT
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 sec-bold pry-bold-text font-weight-bold p-3 mb-4 ml-1 ">
                            <div className="custom-control custom-radio mb-2 ">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="groupOfDefaultRadios"
                                id="service"
                                onChange={e => setitem_type(e.target.value)}
                                value="service"
                              />
                              <label className="custom-control-label" for="service">
                                <span className="text-uppercase">
                                  SERVICES
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"

                                onClick={(e) => itemAdd(e)}
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* Add Item Modal */}
      <AddItemCategory
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        getItemCat={e => fetchData1()}
        showItemAdd={props.onShow}

      />
      {/* Add Item Modal */}
    </>
  );
};

export default AddItem;
