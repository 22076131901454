import React from "react";
import { Link } from "react-router-dom";

function Title({ title, btnName, link, btnIcon, none }) {
  return (
    <div className="dash-title">
      <div className="row align-items-center">
        <div className="col pry-bold-text">
          <h5>{title}</h5>
        </div>
        <div className={`col ${none}`}>
          <Link to={link}>
            <button className="btn btn-pry float-right ">
              <i className={`bx bi ${btnIcon} mr-2 `} />
              {btnName}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Title;
