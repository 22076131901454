import React, { useState, useEffect } from 'react';
import Wrapper from '../../../../Wrapper';
import AppLoader from '../../../AppLoader';
import TitlenBack from '../../Global/TitlenBack';
import { Link, useHistory } from "react-router-dom";
import img from '../img/Vector9.svg';
import axios from "axios";
import { toast } from 'react-toastify';



const AllStoreReport = () => {
    // Page Feature
    const pagename = "reports";
    // Page Feature

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [load, setload] = useState(false);
    const [storereports, setstorereports] = useState([]);
    let history = useHistory();

    //  Get All report
    function getReports(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
            stoken: stoken,
        };
        axios.post(`${ endpoint }/storeReportsOverview`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setstorereports(res.data.data);
                }
            })
            .catch((error) => {
                setload(false);
            });

    }
    useEffect(() => {
        getReports();
    }, []);


    // Route to Each Store Report
    function toStore(stoken, overview, storename) {
        history.push({
            pathname: `/reports/stores/${ stoken }`,
            state: { ...overview, storename },

        });
    }
    // Route to Each Store Report

    return (
        <>
            <Wrapper pagename={pagename}>
                <TitlenBack title={"REPORT"} title1={'STORE'} none={"d-none"} />
                <div className="min-height-lg">

                    <div className="row mt-5 justify-content-center">
                        {storereports.length === 0 ?
                            <div className="col-md-6 mt-5 text-center grey-text">
                                <i className='bi bi-shop h1' />
                                <p>
                                    No Store Available
                                </p>
                            </div> :
                            <>

                                {storereports.map(({ storename, location, created, stoken, overview }, i) => (
                                    <div className="col-md-4 col-md-3 mb-4" key={i}>
                                        <div className="light-bg br-md shadow h-100 row m-0 p-0 align-items-end" onClick={e => toStore(stoken, overview, storename)}>
                                            <div className="py-3 px-4 col-md-12 m-0 p-0">
                                                <h4 className="pry-bold-text text-break">
                                                    {storename}
                                                </h4>
                                                <h5 className="m-0 mb-2 pry-text font-weight-light">
                                                    Location:    {location}
                                                </h5>
                                                <p className="m-0 mb-2 pry-text font-weight-light">
                                                    Date Created:   {created}
                                                </p>
                                            </div>
                                            <div className="col-md-12 m-0 p-0">
                                                <img src={img} width='100%' className='br-md ' />
                                            </div>
                                        </div>
                                    </div>
                                )
                                )}
                            </>
                        }
                    </div>

                </div>
            </Wrapper>
            {load ? (<AppLoader />) : (
                <> </>)}




        </>
    );
};

export default AllStoreReport;