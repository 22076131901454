import React, { useState } from "react";
import InventDetails from "../../Components/User/Inventory/InventDetails";
import AllInventory from "../../Components/User/Inventory/AllInventory";
import Title from '../../Components/User/Global/Title'
import Wrapper from "../../Wrapper";
function Inventory() {
const pagename = "inventory"

  return (
    <>
      <Wrapper pagename={pagename}>
        <Title title={"INVENTORY"} link={"/inventory/items"} btnName={"Item Page"} btnIcon={"bxs-basket"} />
        <InventDetails />
        <AllInventory />
      </Wrapper>

    </>
  );
}

export default Inventory;
