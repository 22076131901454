import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';


const CurrentPlan = () => {

    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));

    const [load, setload] = useState(false);
    const [cdata, setcdata] = useState("");

    const [amount, setamount] = useState('');
    const [timestamp, settimestamp] = useState('');
    const [expire, setexpire] = useState('');

    const [name, setname] = useState('');
    const [no_locations, setno_locations] = useState('');
    const [no_users, setno_users] = useState('');
    const [duration_days, setduration_days] = useState('');

    const [currentStaff, setcurrentStaff] = useState('');
    const [currentStore, setcurrentStore] = useState('');

    const [check_plan, setcheck_plan] = useState(false);


    function getCurrentSub(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
        }
        axios.post(`${endpoint}/getCurrentBizSub`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setcdata(res.data);

                    setcurrentStaff(res.data.currentSubData.subData.dataCount.staff)
                    setcurrentStore(res.data.currentSubData.subData.dataCount.stores)

                    setamount(res.data.currentSubData.amount_th)
                    settimestamp(res.data.currentSubData.start_date)
                    setexpire(res.data.currentSubData.expire_date)

                    setname(res.data.currentSubData.packageDetails.name)
                    setno_locations(res.data.currentSubData.packageDetails.no_locations)
                    setno_users(res.data.currentSubData.packageDetails.no_users)
                    setduration_days(res.data.currentSubData.duration_days)

                }
            })
            .catch((error) => {
                setload(false);
                toast.warn('Something went wrong!');
            });
    }
    useEffect(() => {
        getCurrentSub()
    }, []);

    function checkPlan() {
        setcheck_plan(!check_plan)
    }

    return (
        <>

            {/* Plan Title */}

            <div className="sec-bold py-4 container br-md mt-4 pry-bold-text">
                <h5>
                    Billing for {bData.name}
                </h5>

                {load === true ?
                    <div className="text-center my-5">
                        <ClipLoader color={"#023676"} loading={load} speedMultiplier="1.2" />
                    </div> : (
                        <>
                            {cdata.currentSubData === false ? (
                                <div className="grey-text text-center mt-5">
                                    <i className="bi bi-exclamation-circle bx-md" />
                                    <p>  No active Business Plan </p>
                                </div>
                            ) : (
                                <p>Current billing period runs from <span className="font-weight-bold">{timestamp} </span> to <span className="font-weight-bold">{expire} </span> .</p>
                            )}
                        </>)
                }
            </div>

            {/* Plan Title */}

            {/* Current Plan Details */}

            <div className="mt-5">
                <div className=" pry-bold-text">
                    <h5 className='m-0 p-0'>
                        Current Business Plan
                    </h5>
                    <p className='m-0 p-0'>
                        Fees and feature usage
                    </p>
                </div>
                <div className="sec-bold py-4 container br-md mt-3 pry-bold-text">
                    <div className="d-flex justify-content-between border-bottom border-primary" onClick={checkPlan}>
                        <h5 className=''>
                            Business Plan
                        </h5>
                        <i className={`bi ${check_plan ? 'bi-chevron-up' : 'bi-chevron-down'}  mr-3`} />
                    </div>

                    {check_plan &&
                        <div className="border-bottom border-primary mt-3 check-plan">
                            <div className="py-2 sec-bold pry-bold-text " >
                                <h6 className="font-weight-bold ">Package Name: {name}</h6>
                                <h6 className="font-weight-bold">Amount: ₦ {amount} </h6>
                                <h6 className=" font-weight-bold"> Duration: {duration_days} days </h6>
                            </div>
                        </div>

                    }

                    {load === true ?
                        <div className="text-center my-5">
                            <ClipLoader color={"#023676"} loading={load} speedMultiplier="1.2" />
                        </div> : (
                            <>
                                {cdata.currentSubData === false ? (
                                    <div className="grey-text text-center mt-5">
                                        <i className="bi bi-exclamation-circle bx-md" />
                                        <p>  No active Business Plan </p>
                                    </div>
                                ) : (
                                    <div className="row mt-4">
                                        <div className="col-md-4 mt-3 border-none-sm right-border">
                                            <h6 className="font-weight-bold">{name} plan</h6>
                                            <small className="">Effective from {timestamp}</small>
                                        </div>
                                        <div className="col-md-4 mt-3 right-border">
                                            <h6 className="font-weight-bold">Usage</h6>
                                            <small>
                                                {currentStore} / {no_locations} Store Created
                                            </small> <br />
                                            <small>
                                                {currentStaff} / {no_users} Staff Added
                                            </small>
                                        </div>
                                        <div className="col-md-4 mt-3 ">
                                            <h6 className="font-weight-bold">Bill</h6>
                                            <h6 className="mt-3 font-weight-bold">
                                                ₦{amount}
                                            </h6>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    <div className="text-center mt-4">
                        <Link to="/settings/packages">

                            <button className='btn m-0 pry light-text w-md-100'>
                                {localStorage.getItem('active') === '1' ? 'Change Business Plan' : 'Upgrade'
                                }
                            </button>
                        </Link>
                    </div>

                </div>
            </div>

            {/* Current Plan Details */}


        </>
    );
}

export default CurrentPlan;