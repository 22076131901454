import React, { useState, useEffect } from "react";
import axios from "axios";
import { th } from "../Global/addCommaToNumber";
import { Link } from "react-router-dom";


function InventDetails() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [stat, setstat] = useState({ allItems: "0", allInventory: "0", outOfStock: "0", expiredStock: "0" });


  // Get Item Details
  const getItemData = () => {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getStats`, data,)
      .then((res) => {
        if (res.data.success == false) {
        } else {
          setstat(res.data)
        }
      })
  };
  useEffect(() => {
    getItemData();
  }, []);
  // Get Item Details


  const obj = [
    { title: "All Items", header: "pink-bold", textColor: "pink-light-text", icon: "bi-box-seam pink-light", noColor: "pink-bold-text", no: `${th(stat.allItems)}`, route: "items" },
    { title: "All Inventory", header: "green-bold", textColor: "green-light-text", icon: "bi-bag green-light", noColor: "green-bold-text", no: `${th(stat.allInventory)}`, route: "#" },
    { title: "Out of Stock", header: "yellow-bold", textColor: "yellow-light-text", icon: "bi-cart yellow-light", noColor: "yellow-bold-text", no: `${th(stat.outOfStock)}`, route: "out-of-stock" },
    { title: "Expiring Items", header: "red-bold", textColor: "red-light-text", icon: "bi-cart-x red-light", noColor: "red-bold-text", no: `${th(stat.expiredStock)}`, route: "expired" },
  ]


  return (
    <>
      <div className="text-center mt-4">

        <div className="row justify-content-center">

          {obj.map(({ title, header, textColor, icon, noColor, no, route }, i) => (
            <div className="col-md-6 col-lg-3 mb-3">
              <Link to={`/inventory/${route}`}>
                <div className="br-sm white shadow-sm">
                  <div className={` ${header} p-1 h6 br-sm`}></div>
                  <div className={` ${textColor} row text-center align-items-center justify-content-center mt-4 `}>
                    <i className={` ${icon} bx p-2 bi mr-2 bx-sm br-lg`} />
                    <span className="font-weight-bold">
                      {title}
                    </span>
                  </div>
                  <h3 className={`py-3 ${noColor}`}> {no} </h3>
                </div>
              </Link>
            </div>
          )
          )}

        </div>
      </div>
    </>
  );
}

export default InventDetails;
