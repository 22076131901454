import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { toast } from "react-toastify";
import reni from '../../img/reni.png';
import { QRCodeSVG } from 'qrcode.react';
import { th } from "../../Global/addCommaToNumber";

const Print = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [load, setload] = useState(false);
    const [allinvdata, setallinvdata] = useState([]);
    const [invid] = useState(props.match.params.id);
    const [cdata, setcdata] = useState("");
    const [bData] = useState(JSON.parse(localStorage.getItem("bData")));
    const [vatData, setvatData] = useState('');
    const [biz_logo, setbiz_logo] = useState(bData?.logo || reni);
    //  Get All nvoice
    function getInvDetails(e) {
        setload(true);
        const data = new FormData();
        data.append("invid", invid);
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);

        axios.post(`${ endpoint }/getSalesData`, data,)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setcdata(res.data);
                    setvatData(res.data.taxVatData);

                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getInvDetails();
    }, []);

    function getInvData(e) {
        setload(true);
        const data = new FormData();
        data.append("invid", invid);
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);

        axios.post(`${ endpoint }/getSalesItem`, data,)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setallinvdata(res.data);
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getInvData();
    }, []);


    return (
        <>


            <div className="white pt-5 print ">
                <div className="container">

                    {/* Title */}

                    <div className="dash-title">
                        <div className="d-flex justify-content-between border-bottom pb-3 mb-4">
                            <div className="col-md-6 pry-text ">
                                <h5 className="font-weight-light pt-3"> INVOICE

                                </h5>
                            </div>
                            <div className="col-md-6">
                                <div className="bizlogo float-right">
                                <img src={biz_logo} width="100%" alt="logo"  />
                                </div>
                            </div>
                        </div>
                    </div>


                    {load ?
                        <div className="min-height-lg py-5 text-center">
                            <h5 className="pry-text"> Loading... </h5>
                        </div>
                        :
                        <>

                            {/* Title */}

                            <div className="text-center mb-3">
                                <h4 className="pry-text text-uppercase m-0">
                                    {cdata?.bdata?.name || 'Nil'}
                                </h4>
                                <h5 className="pry-text text-uppercase m-0">
                                    {(cdata?.sdata?.sname || cdata?.sdata?.sname === '0') || 'Nil'}
                                </h5>
                                <small className="pry-text text-uppercase m-0 ">
                                    {(cdata?.bdata?.phone || cdata?.sdata?.sname === '0') || 'Nil'}
                                </small>
                            </div>

                            {/* Invoice Details */}
                            <div className="border-bottom mb-5 pb-4 row">

                                <div className=" pry-bold-text col-md-8 mb-4">
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <h5 className="mb-1 font-weight-light"> Invoice ID: </h5>
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h5 className="mb-1 font-weight-light"> {invid}</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <h5 className="mb-1 font-weight-light">  Date: </h5>
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h5 className="mb-1 font-weight-light"> {cdata?.sales_date}</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <h5 className="mb-1 font-weight-light">  Name: </h5>
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h5 className="mb-1 font-weight-light w-75 text-break"> {cdata?.customer_name ? cdata?.customer_name : 'Nil'}  </h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <h5 className="mb-1 font-weight-light">   Phone:</h5>
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h5 className="mb-1 font-weight-light w-75 text-break"> {cdata?.customer_phone || 'Nil'} </h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <h5 className="mb-1 font-weight-light">   Email:</h5>
                                        </div>
                                        <div className="col-md-8 col-8">
                                            <h5 className="mb-1 font-weight-light w-75 text-break"> {cdata?.customer_mail} </h5>
                                        </div>
                                    </div>
                                    <h6 className="alert alert-primary fit-content">
                                        Attendant Name: {cdata?.agentName || 'Nil'}
                                    </h6>
                                </div>
                                <div className="col-md-4 text-center text-md-right ">
                                    <div className="mb-5">
                                        <QRCodeSVG value={`https://reniaccounts.com/invoice/${ invid }`}
                                            bgColor="#ffffff"
                                            fgColor="#023676"
                                            level="H"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Invoice Details */}

                            {/* Item Details */}
                            <div className="">
                                <MDBTable small responsive bordered>
                                    <MDBTableHead >
                                        <tr>
                                            <th className="text-center">No</th>
                                            <th>Description</th>
                                            <th className="text-center">Qty</th>
                                            <th className="text-right pr-2">Price</th>
                                            <th className="text-right pr-2">Amount</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody color="white" >
                                        {allinvdata.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td className="pt-3-half">
                                                    {item.item_name}
                                                </td>
                                                <td className="text-center">
                                                    {item.qty}
                                                </td>
                                                <td className="text-right pr-2">
                                                    ₦{item.price_th}
                                                </td>
                                                <td className="text-right pr-2">
                                                    ₦{item.amount_th}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="text-center">=</td>
                                            <td className="pt-3-half">
                                                VAT ({bData?.vat_rate || 0.00}%)
                                            </td>
                                            <td className="text-center">

                                            </td>
                                            <td className="text-right pr-2">

                                            </td>
                                            <td className="text-right pr-2">
                                                ₦{vatData?.value || 0.00}
                                            </td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                                <h5 className="float-right mt-3 font-weight-light pry-bold-text border-bottom pb-2 "> TOTAL : <span className="ml-5 mr-2 font-weight-bold">
                                    ₦{vatData ?
                                        vatData?.vat_type === "inclusive" ?
                                            cdata?.total_amount_th :
                                            th(Number(cdata?.total_amount) + Number(vatData?.value))
                                        : cdata?.total_amount_th
                                    }
                                </span> </h5>

                            </div>
                            {cdata?.details &&
                                <div className="row justify-content-end ">
                                    <div className="col">
                                        <h5 className="mb-1 font-weight-light h6"> Description:</h5>
                                    </div>
                                    <div className="col-12 col-md-10 ">
                                        <h5 className="mb-1 font-weight-light h6 w-75 text-break"> {cdata?.details}</h5>
                                    </div>
                                </div>
                            }

                            <div className="border-bottom container mt-5 pt-5">
                                {(cdata?.bdata?.accountnumber === '0' || cdata?.bdata?.accountnumber === '')
                                    ?
                                    <>
                                    </>
                                    :
                                    <>
                                        <h6 className="pry-bold-text font-weight-bold">
                                            Bank Details:  </h6>
                                        <ul>
                                            <li className="list-item">
                                                Bank Name: {cdata?.bdata?.bankname}
                                            </li>
                                            <li className="list-item">
                                                Account Name: {cdata?.bdata?.accountname}
                                            </li>
                                            <li className="list-item">
                                                Account Number: {cdata?.bdata?.accountnumber}
                                            </li>
                                        </ul>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="text-center pb-4 light-bg ">
                <button
                    className="btn btn-pry m-0 w-lg-75 w-md-100"
                    onClick={() => window.frames['frame1'].print()}
                >
                    <i className="bi bi-receipt" />  Print Receipt
                </button>
            </div>

            {/* Invoice Reciept */}
            <div className="">
                <iframe src={`/invoice/${ invid }`} name="frame1" className="d-none w-100"></iframe>
            </div>
            {/* Invoice Reciept */}
            {/* Item Details */}

        </>
    );
};

export default Print;