import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import Features from "./Features";

const StaffFeature = (props) => {
  const [token] = useState(localStorage.getItem("token"));
  const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
  const [owner_token] = useState(bData?.owner_token);
  const [load, setload] = useState(false);
  const [status, setstatus] = useState(false);
  const [workF, setworkF] = useState(false)

  //  Get All Features
  function getFeatures(e) {
    setworkF(true)
  }

  // Form Fields
  const [formField, setformField] = useState({});
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setstatus(!status)
  }
  // Form Fields

  // All the Features 
  const obj = [
    { name: 'DASHBOARD', value: 'dashboard', status: status, id: '' },
    { name: 'EXPENSES', value: 'expenses', status: status, id: '' },
    { name: 'INVENTORY', value: 'inventory', status: status, id: '' },
    { name: 'CUSTOMERS', value: 'customers', status: status, id: '' },
    { name: 'SUPPLIERS', value: 'suppliers', status: status, id: '' },
    { name: 'DEBT MANAGEMENT', value: 'debt', status: status, id: '' },
    { name: 'SALES & INVOICE', value: 'sales', status: status, id: '' },
    { name: 'ACCOUNT STATEMENT', value: 'statement', status: status, id: '' },
    { name: 'REPORTS', value: 'reports', status: status, id: '' },
    { name: 'SETTINGS', value: 'settings', status: status, id: '' },
    { name: 'INVETORY EDIT', value: 'inventory_edit', status: status, id: '' },
    { name: 'INVETORY DELETE', value: 'inventory_delete', status: status, id: '' },
    { name: 'INVOICE EDIT', value: 'sales_edit', status: status, id: '' },
    { name: 'INVENTORY TOP UP', value: 'inventory_topup', status: status, id: '' },
  ]
  // All the Features 


  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
        onEnter={getFeatures}
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton>
            <h6 className="font-weight-bold pry-text ml-auto">Edit Staff Features</h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 text-center pry-text">
                        <i className="bi bi-person-circle h3 " />
                        <h6 className="border-bottom pb-3 text-uppercase">
                          {props.staffdetails?.staffname}
                        </h6>

                      </div>

                      {load ? (
                        <div className=" mb-5 col-md-12 text-center">
                          <PuffLoader color="#023676" size={"60"} />
                        </div>
                      ) : (
                        <>
                          <div className=" col-md-12 mb-3">
                            <h6 className="pry-text ">
                              FEATURES
                            </h6>
                          </div>

                          {props.staffdetails?.usertoken === owner_token ?
                            (<>
                              <div className="red-text text-center">

                                <i className="bi bi-emoji-frown h1 pb-3 bx-tada bx" />
                                <p>
                                  Sorry, you cannot edit the Business Owner
                                </p>
                              </div>
                            </>
                            ) : (
                              <>
                                {props.staffdetails?.usertoken === token ?
                                  <>
                                    <div className="red-text text-center">
                                      <i className="bi bi-emoji-frown h1 pb-3 bx-tada bx" />
                                      <p>
                                        Sorry, you cannot edit yourself
                                      </p>
                                    </div>
                                  </> : (
                                    <>
                                      <div className="col-md-12">
                                        <div className="row justify-content-center">
                                          {workF &&
                                            <>
                                              {obj.map((item, i) => {
                                                return (
                                                  <Features
                                                    name={item.name}
                                                    value={item.value}
                                                    work={workF}
                                                    staff={props.staffdetails?.usertoken}
                                                  />
                                                )
                                              })}
                                            </>}
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            )}

                          <div className="col-md-10 mx-auto text-center">
                            <div className="user-btn mb-4 mr-auto text-center">

                              <button
                                type="button"
                                className="btn btn-border px-5"
                                onClick={props.onHide}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StaffFeature;
