import React from 'react';
import { Link } from 'react-router-dom';
import img from './img/started.png'


const GetStarted = () => {
    const obj = [
        {
            number: "1",
            title: "Create your account",
            details: ""
        },
        {
            number: "2",
            title: "Account Activation",
            details: ""
        },
        {
            number: "3",
            title: "Continue from dashboard",
            details: ""
        },
    ]


    return (
        <>
            <section className="get-started ">
                <div className="container">

                    <div className="my-5 text-center">
                        <div className="pry pt-1 px-5 fit-content mx-auto mb-4"></div>
                        <h4 className="pry-text">
                            Wanna get started? See how easy it is
                        </h4>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-5 d-none d-sm-block">
                            <img src={img} alt="#" width={'60%'} />
                        </div>
                        <div className="col-md-7 mt-5">
                            <h5 className='mb-4'>
                                Steps to begin your management journey.
                            </h5>

                            {obj.map(({ number, title }, i) => {
                                return (
                                    <div className="d-flex my-5">
                                        <div className="">
                                            <h5 className='pry-text sec-bold mr-5 px-2 br-lg'>{number}</h5>
                                        </div>
                                        <div className="">
                                            <h6 className="font-weight-bold m-0">
                                                {title}
                                            </h6>
                                            <p className="m-0">
                                                You can help the organization account for everything. This is good for growth.
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>


                    </div>
                        <div className="mt-5 text-center">
                            <Link to="/register">
                                <button className="btn pry light-text br-sm btn-lg">
                                    Get Started
                                </button>
                            </Link>
                        </div>
                </div>


            </section>

        </>
    );
}

export default GetStarted;