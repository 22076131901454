import Title from '../../Components/User/Global/Title';
import Wrapper from '../../Wrapper';
import img from "../../Components/User/Report/img/bizreport.svg";
import img1 from "../../Components/User/Report/img/storereport.svg";
import { Link } from 'react-router-dom';

function Report() {


    //  Get All report

    // Page Feature
    const pagename = "reports"
    // Page Feature
    return (
        <>

            <Wrapper pagename={pagename}>
                <Title title={"REPORT"} none={"d-none"} />

                <div className="report mt-5 min-height-lg">
                    <div className="row justify-content-around">
                        <div className="col-md-5 text-center h-100 mb-5">
                            <div className="pry light-text py-2 first">
                                Overall Business
                            </div>
                            <Link to="/reports/business" >
                                <div className="light-bg shadow-sm p-5 second">
                                    <img src={img} alt="" width={"100%"} />
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-5 text-center h-100  mb-5">
                            <div className="pry light-text py-2 first">
                                Stores
                            </div>
                            <Link to="/reports/stores" >
                                <div className="light-bg shadow-sm p-5 second">
                                    <img src={img1} alt="" width={"100%"} />
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
            </Wrapper>


        </>
    );
}

export default Report;