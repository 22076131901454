import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";

const ChooseStores = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [sname] = useState(localStorage.getItem("sname"));
  const [allstores, setallstores] = useState([]);
  const [load, setload] = useState(false);
  const [storing, setstoring] = useState(false);
  const [rad, setrad] = useState("0");
  let history = useHistory()

  //  Get All Stores
  function getStore(e) {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", localStorage.getItem("btoken"));
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserStores`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          getStore();
        } else {
          setload(false);
          setallstores(res.data);
          localStorage.setItem('stores', JSON.stringify(res.data))
          toast.success('We found these stores for you');
        }
      })
      .catch((error) => {
        setload(false);
      });
  }

  const stores = allstores.map((item, i) => {
    return (
      <div className="custom-control custom-radio mb-2" key={i}>
        <input
          type="radio"
          className="custom-control-input"
          name="groupOfDefaultRadios"
          id={item.stoken}
          value={item.stoken}
          onChange={(e) => setrad(e.target.value)}
        />
        <label className="custom-control-label" for={item.stoken}>
          {item.storename}<br />
          <small className="grey-text">Location: {item.location}</small>
        </label>
      </div>
    );
  });
  //  Get All Stores

  // Set Store
  function setStore(e) {
    e.preventDefault()
    if (rad) {
      setstoring(true);
      if ((rad === "0")) {
        setstoring(false);
        toast.error("Select a Store");
      } else {

        setTimeout(() => {
          localStorage.setItem("stoken", rad);
          setstoring(false);
          toast.success("Store Updated!");
          setTimeout(() => {
            history.push('/dashboard')
            setTimeout(() => {
              window.location.reload()
            }, 500);
          }, 10);
        }, 2500);
      }
    } else {
      setstoring(false);
      toast.error("Something went wrong");
    }
  }
  // Set Store

  //  Get Current Stores
  const currentstore = allstores
    .filter((x) => x.stoken.includes(rad))
    .map(({ storename }, i) => {
      return (
        <input
          type="text"
          className=" input-style"
          name="qty"
          value={storename}
          autoComplete="off"
          disabled
        />
      );
    });
  //  Get Current Stores




  function removerad() {
    setrad("0");
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
        onEnter={getStore}
        onExit={removerad}
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header >
            <h6 className="font-weight-bold pry-text mx-auto">All Stores</h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <h6 className="pry-text border-bottom pb-3">
                          CURRENT STORES
                        </h6>
                        <input
                          type="text"
                          className=" input-style"
                          name="qty"
                          value={sname ? sname : 'Choose Store!'}
                          autoComplete="off"
                          disabled
                        />
                      </div>

                      {load ? (
                        <div className=" mb-5 col-md-12 text-center">
                          <ClipLoader color="#023676" size={"40"} speedMultiplier="1.2" />
                        </div>
                      ) : (
                        <>
                          {allstores.length === 0 ? (
                            <div className="grey-text text-center mt-5">
                              <i className="bi bi-exclamation-circle bx-md" />
                              <p>  No store available </p>
                            </div>
                          ) : (
                            <>
                              <div className=" col-md-12 mb-3 pb-3 border-bottom ">
                                <h6 className="pry-text">
                                  ALL STORES
                                </h6>
                                <small className="pry-text">select a store!</small>
                              </div>

                              <div className="col-md-12">{stores}</div>
                              {/* {currentstore} */}

                              <div className="col-md-10 mx-auto text-center">
                                <div className="user-btn mb-4 mr-auto text-center">
                                  {storing ? (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn btn-pry"
                                        disabled
                                      >
                                        <i className="bx bx-loader bx-spin bx-sm white-text" />
                                      </button>
                                    </>
                                  ) : (
                                    <>

                                      <button
                                        type="submit"
                                        className="btn btn-pry px-5"
                                        onClick={(e) => setStore(e)}
                                      >
                                        Save
                                      </button>
                                    </>
                                  )}
                                  {localStorage.getItem('stoken') ?
                                    <button
                                      className="btn btn-border px-5"
                                      onClick={props.onHide}
                                    >
                                      Cancel
                                    </button> : <></>
                                  }
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChooseStores;
