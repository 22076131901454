import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import DebtorTitle from "./DebtorTitle";
import DeleteModal from "../Global/DeleteModal";
import EditDebtor from "./EditDebtor";
import { th } from "../Global/addCommaToNumber";


export default function AllDebtor() {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [load, setload] = useState(false);

    // Edit Modal
    const [showremove1, setShowremove1] = useState(false);
    const handleCloseremove1 = () => setShowremove1(false);
    const handleShowremove1 = () => setShowremove1(true);
    const [data, setdata] = useState('');

    function workModal1(e) {
        setdata(e);
        handleShowremove1();
    }
    // Edit Modal




    //  Get All Supplier
    function getDebtor(e) {

        setload(true);
        const data = {
            btoken: btoken,
            stoken: stoken,
            apptoken: apptoken
        };
        axios
            .post(`${ endpoint }/getDebtors`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    const Debtor = res.data?.debtors.map((item, i) => {
                        const { id, name, mail, phone, date, due_date, paid_status, amount } = item;
                        const ids = { did: id };
                        return (
                            {
                                id: `${ id }`,
                                date: `${ date }`,
                                due_date: `${ due_date }`,
                                amount: `₦${ th(amount) }`,
                                name: `${ name }`,
                                mail: `${ mail }`,
                                phone: `${ phone }`,
                                action:
                                    <h6 className="d-flex fit-content border-bottom pointer border-success green-text"
                                        onClick={(e) => workModal1(item)}
                                    >
                                        <i className="bi bi-pen-fill h6 mr-2"
                                        /> Edit</h6>
                                ,
                                paid_status: paid_status === "0" ?
                                    <h6 className="badge  badge-warning br-sm p-1 text-center"
                                    > UNPAID</h6>
                                    :
                                    <h6 className="badge  badge-success br-sm p-1 text-center"
                                    > PAID</h6>
                                ,
                                delete:
                                    <DeleteModal
                                        ids={ids}
                                        name={name}
                                        url={'deleteDebt'}
                                        Fn={getDebtor}
                                        from={'Debtors'}
                                    >
                                        <h6 className="d-flex fit-content  border-bottom pointer border-danger red-text"
                                        >
                                            <i className="bi bi-trash3-fill h6 mr-2"
                                            /> Delete</h6>
                                    </DeleteModal>
                            }
                        );
                    });
                    setDatatable({
                        ...datatable,
                        rows: Debtor
                    });
                }
            })
            .catch((error) => {
                setload(false);
            });
    }

    useEffect(() => {
        getDebtor();
    }, []);
    //  Get All Supplier

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: "ID",
                field: "id",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Id",
                },
            },
            {
                label: "Date Added",
                field: "date",
                width: 200,
            },
            {
                label: "Due Date ",
                field: "due_date",
                width: 200,
            },
            {
                label: "Amount",
                field: "amount",
                width: 200,
            },
            {
                label: "Name",
                field: "name",
                width: 270,
            },
            {
                label: "Email",
                field: "mail",
                width: 270,
            },
            {
                label: "Phone",
                field: "phone",
                width: 270,
            },
            {
                label: "Status",
                field: "paid_status",
                width: 270,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },
            {
                label: "Delete",
                field: "delete",
                width: 200,
            },
        ],
        rows: []
    });




    return (
        <>
            <DebtorTitle getDebtor={e => getDebtor()} />

            <div className="mt-3 min-height-lg">
                <MDBDataTableV5
                    hover
                    fullPagination
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    pagingTop
                    searchTop
                    responsiveLg
                    theadColor="sec-bold pry-bold-text"
                    maxHeight="700px"
                    searchBottom={false}
                    className="white br-md"
                />
            </div>


            {/* Edit Debtor Modal */}
            <EditDebtor
                show={showremove1}
                onHide={handleCloseremove1}
                animation={false}
                data={data}
                getDebtor={e => getDebtor()}
            />
            {/* Edit Debtor Modal */}

            {load && <AppLoader />}

        </>

    );
}
