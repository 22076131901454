import React from 'react';
import Navbar from '../Components/Homepage/Navbar/Navbar';
import Footer from '../Components/Homepage/Footer';
import Navigation from '../Components/Homepage/HowToUse/Navigation';

const HowTOUse = () => {
    return (
        <>
            <main className='white'>
                <Navbar />
                <div className="container">
                    <Navigation />
                </div>
                <Footer />
            </main>
        </>
    );
}

export default HowTOUse;