import React from 'react';
import AllExp from '../../Components/User/Expenses/AllExp';
import Wrapper from '../../Wrapper';

function Expenses() {

    const pagename = "expenses"

    return (
        <>
            <Wrapper pagename={pagename}>
                <AllExp />
            </Wrapper>
        </>
    );
}

export default Expenses;