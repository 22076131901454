import React from 'react';
import AllInvoice from '../../Components/User/Invoice-Sales/Invoice/AllInvoice';
import Title from '../../Components/User/Global/Title';
import Wrapper from '../../Wrapper'
import { Link } from 'react-router-dom';
import BackButton from '../../Components/User/BackButton';
import TitlenBack from '../../Components/User/Global/TitlenBack';

function Invoice() {
    const pagename = "sales"

    return (
        <>
            <Wrapper pagename={pagename}>

            <TitlenBack title={'SALES'} title1={'INVOICE'} to={'sales'} btnName={'Add Sales'} btnIcon={'bx-plus '} />
                <AllInvoice />
            </Wrapper>
        </>
    );
}

export default Invoice;