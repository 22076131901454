import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AppLoader from '../../AppLoader';
import bizlogo from '../img/reni.png';

function BizProfile() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [bData, setbData] = useState(JSON.parse(localStorage.getItem('bData')));

  const [cname, setcname] = useState("");
  const [address, setaddress] = useState("");
  const [phone, setphone] = useState("");
  const [totalWorkers, settotalWorkers] = useState("");
  const [des, setdes] = useState("");
  const [bankname, setbankname] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [accountname, setaccountname] = useState("");
  const [tax_rate, settax_rate] = useState("");
  const [vat_rate, setvat_rate] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [load, setload] = useState(false);
  const [issending, setissending] = useState(false);



  // Select Image for Upload
  function convert(e) {
    // check max. file size is not exceeded
    // size is in bytes
    if (e.target.files[0].size > 1000000) {
      toast.warn("File too large");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    reader.onerror = error => {
      console.log("Error: ", error);
    };
  };
  // Select Image for Upload

  //  Update Business Details Function
  function bizUpdate(e) {
    if (cname == "" && address == "" && phone == "" && des == "" && totalWorkers == "") {
      toast.error("Empty Fields!");
      setissending(false);
    } else {
      e.preventDefault();
      setissending(true);
      const data = {
        name: cname,
        address,
        description: des,
        phone,
        no_workers: totalWorkers,
        apptoken,
        usertoken: token,
        btoken,
        bankname,
        accountnumber,
        accountname, tax_rate, vat_rate,
        logo: selectedFile
      };
      axios.post(`${ endpoint }/updateBusiness`,
        data)
        .then((res) => {
          if (res.data.success === false) {
            setissending(false);
            toast.warn(res.data.message);
          } else {
            setissending(false);
            getbizData();
            toast.success(res.data.message);
            seteditme(true);
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    }
  }
  //  Update Business Details Function

  //  Get Business Details Function

  const getbizData = () => {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${ endpoint }/getBusinessDetails`, data,)
      .then((res) => {
        if (res.data.success == false) {
          setload(false);

        } else {
          setload(false);
          localStorage.setItem('bData', JSON.stringify(res.data.details));
          setaddress(res.data.details.address);
          setcname(res.data.details.name);
          setdes(res.data.details.description);
          setphone(res.data.details.phone);
          setbankname(res.data.details.bankname);
          setaccountnumber(res.data.details.accountnumber);
          setaccountname(res.data.details.accountname);
          settotalWorkers(res.data.details.no_workers);
          settax_rate(res.data.details.tax_rate);
          setvat_rate(res.data.details.vat_rate);
          setSelectedFile(res.data.details.logo);
        }
      }).catch((error) => {
        setload(false);
      });
  };
  useEffect(() => {
    getbizData();
  }, []);
  //  Get Business Details Function

  // Edit Function 
  const [editme, seteditme] = useState(true);
  function onEdit() {
    seteditme(false);
    if (editme === true) {
      toast.success('You can now edit...');
    } else { }
  }
  // Edit Function 
  return (
    <>

      <div className="form mt-0">
        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" > BUSINESS PROFILE </h6>
        </div>
        <div className="row mt-2 ml-2">
          <h6 className="pry-text sec fit-content br-sm p-2 mr-3">
            Created:  <span className="font-weight-bold"> {bData?.real_date} </span>
          </h6>
          <h6 className="pry-text sec fit-content br-sm p-2">
            Business ID: <span className="font-weight-bold"> {btoken}</span>
          </h6>
        </div>
        <div className="text-right mb-4">
          <button className="btn shadow-none" onClick={onEdit}>
            <h6 className=" border-bottom border-primary pry-text">
              <i className="bi bi-pen-fill h6"
              /> EDIT
            </h6>
          </button>
        </div>
        <div className="container">
          <form enctype="multipart/form-data" onSubmit={(e) => bizUpdate(e)}>
            <center>
              <div className="d-flex ml-auto justify-content-center">
                <div className=" py-4 ppic mb-4" >
                  <img src={selectedFile || bizlogo} alt={'Business Logo'} width="100%" />
                </div>

                {
                  !editme &&
                  <>
                    <div class="file-upload ml-3 mt-3">
                      <input
                        accept="image/*"
                        type="file"
                        className=" input-style"
                        onChange={convert}
                      />
                      <i class="bi bi-upload"></i>
                    </div>
                  </>
                }
              </div>
            </center>
            <div className="row justify-content-center">
              <div className="col-md-6 ">
                <label> Company Name: </label>
                <input
                  type="text"
                  className="input-style"
                  name=""
                  placeholder="Enter Name"
                  onChange={(e) => setcname(e.target.value)}
                  value={cname}
                  required
                  disabled={editme ? true : false}
                />
              </div>

              <div className="col-md-6 ">
                <label> Company Address: </label>
                <input
                  type="text"
                  className=" input-style"
                  name=""
                  placeholder="Enter Address"
                  onChange={(e) => setaddress(e.target.value)}
                  value={address}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-6 ">
                <label> Phone Number: </label>
                <input
                  type="number"
                  className=" input-style"
                  name=""
                  placeholder="e.g. 08012345678"
                  onChange={(e) => setphone(e.target.value)}
                  value={phone}
                  required
                  disabled={editme ? true : false}
                />
              </div>

              <div className="col-md-6 ">
                <label> Number of workers: </label>
                <input
                  type="number"
                  className=" input-style"
                  name=""
                  placeholder="Number of Workers"
                  onChange={(e) => settotalWorkers(e.target.value)}
                  value={totalWorkers}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-6 ">
                <label> Bank Name: </label>
                <input
                  type="text"
                  className=" input-style"
                  name=""
                  placeholder="Bank Name"
                  onChange={(e) => setbankname(e.target.value)}
                  value={bankname}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-6 ">
                <label> Bank Account Number: </label>
                <input
                  type="number"
                  className=" input-style"
                  name=""
                  placeholder="Bank Account Number"
                  onChange={(e) => setaccountnumber(e.target.value)}
                  value={accountnumber}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-12 ">
                <label> Back Account Name: </label>
                <input
                  type="text"
                  className=" input-style"
                  name=""
                  placeholder="Back Account Name"
                  onChange={(e) => setaccountname(e.target.value)}
                  value={accountname}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-6 ">
                <label> Tax Rate (%): </label>
                <input
                  type="number"
                  className=" input-style"
                  name=""
                  placeholder="Tax Rate"
                  onChange={(e) => settax_rate(e.target.value)}
                  value={tax_rate}
                  required
                  disabled={editme ? true : false}
                />
              </div>    <div className="col-md-6 ">
                <label> VAT Rate (%): </label>
                <input
                  type="number"
                  className=" input-style"
                  name=""
                  placeholder="VAT Rate"
                  onChange={(e) => setvat_rate(e.target.value)}
                  value={vat_rate}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-12 ">
                <label> Store Description: </label>

                <textarea
                  type="text"
                  disabled={editme ? true : false}
                  className="input-style textarea-style"
                  placeholder="Description *"
                  onChange={(e) => setdes(e.target.value)}
                  value={des}
                ></textarea>
              </div>
              {!editme &&
                <div className="col-md-12  mx-auto text-center">
                  <div className="mb-4">
                    {issending ? (
                      <>
                        <button
                          className="btn btn-pry m-0 w-lg-75 w-md-100"
                          disabled
                        >
                          <i className="bx bx-loader bx-spin bx-sm white-text" />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-pry m-0 w-lg-75 w-md-100"
                        >
                          submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
          </form>
        </div>
      </div>
      {load ? <AppLoader /> : <></>}

    </>
  );
}

export default BizProfile;
