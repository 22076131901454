import React from 'react';
import AboutShowcase from '../Components/Homepage/AboutPage/AboutShowcase';
import Navbar from '../Components/Homepage/Navbar/Navbar';
import '../Components/Homepage/AboutPage/Aboutpage.css'
import AboutDetails from '../Components/Homepage/AboutPage/AboutDetails';
import Footer from '../Components/Homepage/Footer'
import OurPricing from '../Components/Homepage/OurPricing';
const AboutPage = () => {
    return ( 
        <>
        <Navbar />
        <div className="about light-bg">
        <AboutShowcase />
        <AboutDetails />
        <OurPricing />
        <Footer />
        </div>

        </>
     );
}
 
export default AboutPage;