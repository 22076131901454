import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";

const CheckUserAuth = () => {
    const { pathname } = useLocation();
    let history = useHistory();

    const [token] = useState(localStorage.getItem("token"));
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);


    //  Get User details Function
    //  Get User details Function
    function getData(e) {
        const data = {
            apptoken
        };
        axios
            .post(`${ endpoint }/checkUserAuth`, data)
            .then((res) => {
            })
            .catch((error) => {
            });
    }
    useEffect(() => {
        if (!token) {
            getData();
        }
    }, [pathname]);
    //  Get User details Function
    //  Get User details Function


    return (
        <></>
    );
};

export default CheckUserAuth;