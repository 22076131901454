import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import AdminSideNav from "./AdminSideNav";
import GetUserData from "./GetUserData";
import BottomNav from "./BottomNav";
import NavDropdown from "./NavDropdown";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UpgradeBtn from "../Global/UpgradeBtn";
import axios from "axios";


const AdminNavbar = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [sname] = useState(localStorage.getItem("sname"));
  const [bname] = useState(localStorage.getItem("bname"));
  const [uname] = useState(localStorage.getItem("uname"));
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
  const [owner_token] = useState(bData?.owner_token);
  const [unread, setunread] = useState(0);


  // Open Navbar 
  const openNav = (e) => {
    document.getElementById("mySidenav").style.width = "300px";
  };
  // Open Navbar 

  // Get User Access
  // Get User Access
  function getFeatures(e) {
    const data = new FormData();
    data.append("staffusertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserAccess`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
        } else {
          localStorage.setItem('features', JSON.stringify(res.data))
        }
      })
  }
  // Get User Access
  // Get User Access


  // Function to Check if a user has Access to a Page or Not
  let history = useHistory();
  const checkfeature = (feature) => {
    if (localStorage.getItem('features')) {
      const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature)
      if (data >= 0) {
        return true
      } else {
        history.push('/dashboard')
        setTimeout(() => {
          toast.info('Page not Authorized to User!!!')
        }, 10);
        return false
      }
    }
  }

  // Function to Check if a user has Access to a Page or Not


  // Check if a business is active or inactive before routing
  function checkActive(e) {
    if (localStorage.getItem('active') === '0') {

      // Check if user is biz owner, if not route to inactive biz page
      if (token !== owner_token) {
        history.push("/settings/noAccess");
      } else {

        // check if page is billing, packages or make payment, if not route to biz inactive page
        if (e !== 'upgrade') {
          history.push("/settings/noAccess");
          setTimeout(() => {
            toast.warn('This Business is Inactive..');
          }, 10);
        }
      }
    }
  }

  // Check if Business Token and Store Token is availble 
  function checkTokens() {
    if (!stoken || !btoken) {
      history.push("/login");
      setTimeout(() => {
        toast.warn('Please Login again..');
      }, 10);

    }
  }

  // Check if Business Token and Store Token is availble 

  useEffect(() => {
    getFeatures()
    checkfeature(props.pagename)
    checkActive(props.route)
    checkTokens()
  }, []);

  // Check if a business is active or inactive before routing

  // Get First letter of user's name
  let name = uname.charAt(0)

  // Get First letter of user's name

  //  Get Notification
  // const [unread, setunread] = useState(0);

  function fetchData(e) {
    const data = {
      apptoken: apptoken,
      usertoken: token,
      btoken
    }
    axios.post(`${endpoint}/getTotalUnreadNotifications`, data)
      .then((res) => {
        setunread(res.data.notifications);
      })
  }
  useEffect(() => { fetchData() }, []);

  //  Get Notification

  return (
    <>
      <div className="usernav">
        <nav
          className="navbar navbar-expand-sm navbar-light fixed-top"
          id="navbar main"
        >
          <div className="container-fluid" id="logo">
            <a
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#basicExampleNav"
              aria-controls="basicExampleNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="navbar-toggler-icon toggler"
                onClick={(e) => openNav(e)}
              >
                <i className="bx bx-menu mr-2 bx-sm pry-text ico " />
              </span>
            </a>
            <Link to="/admin" className="ml-5 nav-name">
              <h5> LOGO </h5>
            </Link>
            <li className="list-unstyled mr-auto ml-5 pl-4 text-truncate">
              <span className="pry-text title-text font-weight-bold ">{bname}<i className='bi bi-arrow-right mx-1' />{sname} </span>
            </li>

            <li className="nav-item dropdown">
              <span className="upgrade-btn">
                <UpgradeBtn />
              </span>
              <Link to="/notifications">
                <i className="bx bxs-bell mr-3 bx-tada-hover h4 pt-2 ico light-bold-text position-relative" >

                  {unread >= 1 &&
                    <span class="position-absolute bell top-0 start-100 translate-middle badge rounded-pill pry "
                      style={{ fontSize: '9px' }}
                    >
                      {unread > 99 ? '99+' : unread}
                    </span>
                  }
                  {/* {unread >= 1 &&
                  <span class="position-absolute bell p-1 pry border border-5 border-white rounded-circle">
                  </span>
                  } */}
                </i>

              </Link>

              <div className="pry-text dropdown-toggle d-flex align-items-center pointer" id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span className=" name-head sec br-lg d-flex align-items-center justify-content-center">
                  {name}
                </span>
              </div>

              <NavDropdown />
            </li>

          </div>
        </nav>
        <AdminSideNav />

      </div>
      <BottomNav />
      <GetUserData />
    </>
  );
};

export default AdminNavbar;
