import React, { useState } from 'react';
import Wrapper from '../../../../Wrapper';
import AppLoader from "../../../AppLoader";
import { formatDate } from '../../Global/GetDate';
import ReportData from './ReportData';
import { MDBDataTableV5 } from "mdbreact";
import { useEffect } from 'react';
import { th } from '../../Global/addCommaToNumber';
import { toast } from 'react-toastify';


const FilterReport = ({ filterData, load, data }) => {

    // Page Feature
    const pagename = "reports";
    // Page Feature

    const [minDate] = useState(JSON.parse(localStorage.getItem('bData')));
    const [to, setto] = useState(formatDate(new Date(`${ minDate?.real_date_1 }`)));
    const [from, setfrom] = useState(formatDate(new Date()));


    useEffect(() => {
        if (data?.financeRecords) {
            const filteredData = data?.financeRecords?.map(({ id, month, year, description, type, amount }, i) => {
                return (
                    {
                        id: `${ id }`,
                        time_recorded: `${ month } ${ year }`,
                        type: `${ type }`,
                        description: `${ description }`,
                        amount: `₦${ th(amount) }`,
                    }
                );
            });
            setDatatable({
                ...datatable,
                rows: filteredData
            });
        } else {
            toast.warn('No data...');
        }
    }, [data]);

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: "ID",
                field: "id",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Id",
                },
            },
            {
                label: "Period Added",
                field: "time_recorded",
                width: 200,
            },
            {
                label: "Type",
                field: "type",
                width: 200,
            },
            {
                label: "Description",
                field: "description",
                width: 270,
            },
            {
                label: "Amount ",
                field: "amount",
                width: 200,
            },
        ],
        rows: []
    });


    return (
        <>
            <Wrapper pagename={pagename}>

                <form onSubmit={(e) => filterData(e, from, to)}>
                    <div className="row justify-content-center align-items-end py-3">
                        <div className="col-md-3 col-6">
                            <label className='h6'>
                                From:
                            </label>
                            <input
                                type='date'
                                className="input-style input-sm mb-0"
                                required
                                onChange={(e) => setto(e.target.value)}
                                value={to}
                                min={formatDate(new Date(`${ minDate?.real_date_1 }`))}
                                max={formatDate(new Date())}
                            />
                        </div>
                        <div className="col-md-3 col-6">
                            <label className='h6'>
                                To:
                            </label>
                            <input
                                type='date'
                                className="input-style input-sm mb-0"
                                onChange={(e) => setfrom(e.target.value)}
                                value={from}
                                required
                                min={formatDate(new Date(`${ minDate?.real_date_1 }`))}
                                max={formatDate(new Date())}
                            />

                        </div>
                        <div className="col-md-3 col-10 text-center text-md-left">
                            <button
                                action="submit"
                                className="btn btn-pry btn-sm m-0">
                                filter
                            </button>

                        </div>

                    </div>
                </form>

                <ReportData title={data ? `from ${ data?.to } to ${ data?.from }` : ''} data={data} />

                <div className="mt-3 min-height-lg">
                    <MDBDataTableV5
                        hover
                        fullPagination
                        entries={10}
                        pagesAmount={4}
                        data={datatable}
                        pagingTop
                        searchTop
                        responsive
                        theadColor="sec-bold pry-bold-text"
                        maxHeight="700px"
                        searchBottom={false}
                        className="white br-md"
                    />
                </div>


            </Wrapper>


            {load && (<AppLoader />)}

        </>
    );
};

export default FilterReport;