import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import ItemTitle from "./SupplierTitle";
import DeleteModal from "../Global/DeleteModal";
import EditSupplier from "./EditSupplier";


export default function AllSupplier() {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [load, setload] = useState(false);

    // Edit Modal
    const [showremove1, setShowremove1] = useState(false);
    const handleCloseremove1 = () => setShowremove1(false);
    const handleShowremove1 = () => setShowremove1(true);
    const [data, setdata] = useState('');

    function workModal1(e) {
        setdata(e);
        handleShowremove1();
    }
    // Edit Modal


    //  Get All Supplier
    function getSupplier(e) {

        setload(true);
        const data = {
            btoken: btoken,
            stoken: stoken,
            apptoken: apptoken
        };
        axios
            .post(`${ endpoint }/getSuppliers`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    const Supplier = res.data.suppliers.map((item, i) => {
                        const { id, name, mail, phone, real_date } = item;
                        const ids = { id: id };
                        return (
                            {
                                id,
                                real_date,
                                name,
                                mail,
                                phone,
                                action:
                                    <h6 className="d-flex fit-content  border-bottom pointer border-success green-text"
                                        onClick={(e) => workModal1(item)}
                                    >
                                        <i className="bi bi-pen-fill h6 mr-2"
                                        /> Edit</h6>
                                ,
                                delete:
                                    <DeleteModal
                                        ids={ids}
                                        name={name}
                                        url={'deleteSupplier'}
                                        Fn={getSupplier}
                                        from={'Suppliers'}

                                    >
                                        <h6 className="d-flex fit-content  border-bottom pointer border-danger red-text"
                                        >
                                            <i className="bi bi-trash3-fill h6 mr-2"
                                            /> Delete</h6>
                                    </DeleteModal>
                            }
                        );
                    });
                    setDatatable({
                        ...datatable,
                        rows: Supplier
                    });
                }
            })
            .catch((error) => {
                setload(false);
            });
    }

    useEffect(() => {
        getSupplier();
    }, []);
    //  Get All Supplier




    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: "ID",
                field: "id",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "Id",
                },
            },
            {
                label: "Date Added",
                field: "real_date",
                width: 200,
            },
            {
                label: "Name",
                field: "name",
                width: 270,
            },
            {
                label: "Email",
                field: "mail",
                width: 270,
            },
            {
                label: "Phone",
                field: "phone",
                width: 270,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },
            {
                label: "Delete",
                field: "delete",
                width: 200,
            },
        ],
        rows: []
    });




    return (
        <>
            <ItemTitle getSupplier={e => getSupplier()} />

            <div className="mt-3 min-height-lg">
                <MDBDataTableV5
                    hover
                    fullPagination
                    entries={10}
                    pagesAmount={4}
                    data={datatable}
                    pagingTop
                    searchTop
                    responsiveLg
                    theadColor="sec-bold pry-bold-text"
                    maxHeight="700px"
                    searchBottom={false}
                    className="white br-md"
                />
            </div>

            {/* Edit Supplier Modal */}
            <EditSupplier
                show={showremove1}
                onHide={handleCloseremove1}
                animation={false}
                data={data}
                getSupplier={e => getSupplier()}
            />
            {/* Edit Supplier Modal */}

            {load && <AppLoader />}

        </>

    );
}
