import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UpgradeBtn from "../Global/UpgradeBtn";


const AdminNavLinks = () => {
  const [token] = useState(localStorage.getItem("token"));
  const [bData] = useState(JSON.parse(localStorage.getItem('bData')));


  const checkfeature = (feature) => {
    const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature);

    if (data >= 0) {
      return true;
    } else {
      return false;
    }
  };

  let navLink = [
    { title: "Dashboard", to: "dashboard", icon: "bxs-category-alt", name: "dashboard" },
    { title: "Inventory", to: "inventory", icon: "bxs-package", name: 'inventory' },
    { title: "Invoice and Sales", to: "sales", icon: "bxs-report", name: 'sales' },
    { title: "Waybill", to: "waybill", icon: "bxs-package", name: 'waybill' },
    { title: "Expenses", to: "expenses", icon: "bxs-wallet-alt", name: 'expenses' },
    { title: "Customers", to: "customers", icon: "bxs-group", name: 'customers' },
    { title: "Suppliers", to: "suppliers", icon: "bxs-cart", name: 'suppliers' },
    { title: "Debt Management", to: "debt-management", icon: "bx-money-withdraw", name: 'debt' },
    { title: "Account Statement", to: "account-statement", icon: "bxs-badge-dollar", name: 'statement' },
    { title: "Report", to: "reports", icon: "bxs-bar-chart-alt-2", name: 'reports' },
    { title: "Settings", to: "settings", icon: "bxs-cog", name: 'settings' },
  ];
  // Logout Function
  let history = useHistory();
  const HandleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("btoken");
    localStorage.removeItem("stoken");
    localStorage.removeItem("uname");
    localStorage.removeItem("umail");
    localStorage.removeItem("ppic");
    localStorage.removeItem("bData");
    localStorage.removeItem("sname");
    localStorage.removeItem("stores");
    localStorage.removeItem("features");
    localStorage.removeItem("user");
    localStorage.removeItem("bname");
    localStorage.removeItem("active");
    toast.success('Logout successful! Redirecting...');
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };
  // Logout Function



  return (
    <>
      {" "}
      <div className="ml-3">
        <Link to="/dashboard" className="navbar-brand white-text">
          <h4>RENI</h4>
        </Link>
      </div>
      <div className="text-center">
        <UpgradeBtn />
      </div>
      <ul className="navbar-nav ml-auto mr-auto mt-3">

        {token === bData?.owner_token ?
          <>
            {navLink.map(({ title, to, icon }, i) => {
              return (
                <li className="nav-item top-nav" key={i}>
                  <NavLink tag={Link} activeClassName="active1" to={`/${ to }`}>
                    <i className={`bx ${ icon } mr-2`} />
                    {title}
                  </NavLink>
                </li>
              );
            })}
          </> :
          <>
            {navLink.map(({ title, to, icon, name }, i) => {
              return (
                checkfeature(name) && <>
                  <li className="nav-item top-nav" key={i}>
                    <NavLink tag={Link} activeClassName="active1" to={`/${ to }`}>
                      <i className={`bx ${ icon } mr-2`} />
                      {title}
                    </NavLink>
                  </li>
                </>
              );
            })}
          </>
        }

      </ul>
      <ul className="justify-content-center">
        <li className="nav-item justify-content-center">
          <button onClick={HandleLogout} className="btn btn-light">
            <i className="bx bx-log-out-circle mr-2" />
            Logout
          </button>
        </li>
      </ul>
    </>
  );
};

export default AdminNavLinks;
