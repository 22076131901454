import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import AddItems from '../Items/AddItem'
import { toast } from "react-toastify";
import Select from "react-select";
import { useFetch } from "../Global/useFetch";


const AddInventory = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  const [issending, setissending] = useState(false);
  const [allitems, setallitems] = useState([]);
  const [key, setkey] = useState("");
  const [unit_key, setunit_key] = useState("");

  const [formField, setformField] = useState({ item_name: "", qty: "", batch_no: "", expiry_date: "", cost_price: "", selling_price: "", details: "", qty_limit: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }

  // Open Add Item Modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);

  // Close Add Inventory Modal
  const [removeaddinvent, setremoveaddinvent] = useState("");
  const handleremoveaddinvent = () => setremoveaddinvent(props.onHide);

  function workModal() {
    handleShowremove();
    handleremoveaddinvent();
  }
  // Open Add Item Modal

  function clear() {
    setformField({ item_name: "", qty: "", batch_no: "", expiry_date: "", cost_price: "", selling_price: "", details: "", qty_limit: "" });
    setkey("");
    setunit_key("");
  }


  function inventAdd(e) {
    e.preventDefault();
    if (key && formField.qty && formField.cost_price && formField.selling_price) {
      setissending(true);
      const data = new FormData();
      data.append("item_name", key);
      data.append("qty", formField.qty);
      data.append("batch_no", formField.batch_no);
      data.append("expiry_date", formField.expiry_date);
      data.append("cost_price", formField.cost_price);
      data.append("selling_price", formField.selling_price);
      data.append("details", formField.details);
      data.append("qty_limit", formField.qty_limit);
      data.append("unit", unit_key);
      data.append("btoken", btoken);
      data.append("stoken", stoken);
      data.append("usertoken", token);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/addInventory`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.getInventoryFn();
            props.onHide()
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error !!!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }
  //  Get All Items
  function getItems() {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getItem`, data)
      .then((res) => {
        if (res.data.success === false) {
        } else {
          setallitems(res.data);
        }
      })
  }
  useEffect(() => {
    getItems()
  }, []);

  const items = allitems.map(({ item }, i) => {
    return (
      { label: item, value: item }
    )
  });

  //  Get All Items

  //  Get All Unit
  const { data } = useFetch('getUnitOfMeasure')

  const units = data?.Units?.map(({ unit, id }, i) => {
    return (
      { label: unit, value: unit }
    )
  });
  //  Get All Unit

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton>
            <h6 className="font-weight-bold pry-text ml-auto">
              Create new Inventory
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Item name:</label>

                        <Select
                          options={items}
                          isSearchable={true}
                          onChange={e => setkey(e.value)}
                          placeholder='Select Item'

                        />
                        <p
                          className="red-text text-right pointer small"
                          onClick={(e) => workModal()}
                        >
                          Item not found? Click here to add new item
                        </p>

                      </div>
                      <div className="12"></div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Cost Price <span className="small"> <i> (per unit)</i></span>:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="cost_price"
                          placeholder="e.g. 1000"
                          onChange={handleChange}
                          required
                          value={formField.cost_price}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Selling Price <span className="small"> <i> (per unit)</i></span>:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="selling_price"
                          placeholder="e.g. 1500"
                          onChange={handleChange}
                          required
                          value={formField.selling_price}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Quantity:</label>

                        <input
                          autoComplete="off"
                          type="number"
                          className=" input-style"
                          name="qty"
                          onChange={handleChange}
                          required
                          value={formField.qty}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Quantity Limit <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="number"
                          className=" input-style"
                          onChange={handleChange}
                          name="qty_limit"
                          required
                          value={formField.qty_limit}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Unit of Measure <span className="small"> <i> (optional)</i></span>:</label>

                        <Select
                          options={units}
                          isSearchable={true}
                          onChange={e => setunit_key(e.value)}
                          placeholder='Select Unit'
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Batch No <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="text"
                          className=" input-style"
                          onChange={handleChange}
                          name="batch_no"
                          required
                          value={formField.batch_no}
                        />
                      </div>
                      <div className="col-md-12 ">
                        <label className="mb-0"> Expiry Date  <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="date"
                          className=" input-style"
                          name="expiry_date"
                          onChange={handleChange}
                          required
                          value={formField.expiry_date}
                        />
                      </div>

                      <div className="col-md-10 mx-auto text-center">

                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"

                                onClick={(e) => inventAdd(e)}
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* Add Item Modal */}
      <AddItems
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        getItemFn={e => getItems()}

      />
      {/* Add Item Modal */}
    </>
  );
};

export default AddInventory;
