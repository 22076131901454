


const AboutDetails = () => {
    return (
        <>

            <div className="sec text-center my-5 py-5 pry-bold-text">
                <div className="container">
                    <div className="col-md-8 mx-auto my-5">
                        <i className="bi bi-gem bx bx-md mb-4" />
                        <h5 className="mb-4">
                            What we provide
                        </h5>
                        <p className="">
                            We have a staff of active customer service representatives who are prepared to assist you with onboarding your stores if necessary. A trial will convince you that Reni is all you need to run your hassle free business.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutDetails;