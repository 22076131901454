import React from 'react';
import logo from '../Homepage/img/renifinance.png'
const UserFooter = () => {
    return (
        <>
            <section className="user-footer text-center">
                <footer className=" light-bg pt-2 pb-1">
                    <div className="mb-1">
                        <img className='' src={logo} alt="reni logo" width="30px" />
                    </div>
                    <small className="pry-bold-text">
                        Copyright 2023. FireSwitch Technologies Ltd.
                    </small>
                </footer>

            </section>


        </>
    );
}

export default UserFooter;