import React, { useState } from 'react';

const CustomersDetails = ({ handleChange, formField }) => {
    const [showDetails, setshowDetails] = useState(false);
    return (
        <>
            <div className="mb-4">
                <h6 className="pry-bold-text br-sm p-2 sec-bold"> Customer Details</h6>
            </div>

            <form>
                <div className="row justify-content-center">
                    <div className="col-md-4 ">
                        <label className="m-0"><small>  Invoice Number:</small> </label>
                        <input
                            type="text"
                            name="invid"
                            className=" input-style input-sm"
                            placeholder="INV-212334"
                            onChange={handleChange}
                            value={formField.invid}
                            required
                            disabled
                        />
                    </div>

                    <div className="col-md-4 col-6">
                        <label className="m-0"><small>  Date:</small> </label>
                        <input
                            type="date"
                            name="sales_time"
                            className=" input-style input-sm"
                            placeholder=""
                            onChange={handleChange}
                            value={formField.sales_time}
                            required
                        />
                    </div>

                    <div className="col-md-4 col-6">
                        <label className="m-0"><small>  Customer Phone:</small> </label>

                        <input
                            type="number"
                            name="customer_phone"
                            className=" input-style input-sm"
                            placeholder="e.g. 08012345678"
                            onChange={handleChange}
                            value={formField.customer_phone}
                            required
                        />
                    </div>

                    <div className="col-md-6 ">
                        <label className="m-0"><small>  Customer Name:</small> </label>
                        <input
                            type="text"
                            name="customer_name"
                            className=" input-style input-sm"
                            placeholder="e.g. Jane Doe"
                            onChange={handleChange}
                            value={formField.customer_name}
                            required
                        />
                    </div>

                    <div className="col-md-6 ">
                        <label className="m-0"><small>  Customer Email:</small> </label>
                        <input
                            type="text"
                            name="customer_mail"
                            className=" input-style input-sm"
                            placeholder="e.g. example@email.com"
                            onChange={handleChange}
                            value={formField.customer_mail}
                            required
                        />
                    </div>

                    <div className="col-md-12 ">
                        <div className="">
                            <input name="details" type="checkbox" id="details" value="details"
                                onClick={(e) => setshowDetails(!showDetails)}
                                checked={showDetails}
                            />
                            <label className="ml-2 m-0 small" for="details"> Description </label>
                        </div>

                        {showDetails &&
                            <>
                                <textarea
                                    type="text"
                                    className="input-style textarea-style"
                                    name="details"
                                    rows="3"
                                    onChange={handleChange}
                                    required
                                    placeholder="Description (optional) "
                                    value={formField.details}
                                ></textarea>
                            </>
                        }
                    </div>
                </div>
                <div className="my-4">
                    <h6 className="pry-bold-text br-sm p-2 sec-bold"> Item Details</h6>
                </div>

            </form>

        </>
    );
}

export default CustomersDetails;