import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Nav.css";


const BottomNav = () => {
    const [token] = useState(localStorage.getItem("token"));
    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));

    const checkfeature = (feature) => {
        const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature)

        if (data >= 0) {
            return true
        } else {
            return false
        }
    }


    let navLink = [
        { title: "Dashboard", to: "dashboard", icon: "bi-house-fill", name: 'dashboard' },
        { title: "Inventory", to: "inventory", icon: "bi-inboxes-fill", name: 'inventory' },
        { title: "Sales", to: "sales", icon: "bi-bag-plus-fill", name: 'sales' },
        { title: "Settings", to: "settings", icon: "bi-gear-fill", name: 'settings' },
    ];
    return (
        <>
            <div className="bottom-nav pb-5">
                <nav
                    className="navbar light-bg navbar-expand-sm navbar-light fixed-bottom br-md mb-3 mx-1"
                    id="navbar main ">
                    <div className="container justify-content-between" id="logo">

                        {token === bData?.owner_token ?
                            <>
                                {navLink.map(({ title, to, icon }, i) => {
                                    return (
                                        <li className="list-unstyled text-center py-1" key={i}>
                                            <NavLink tag={Link} activeClassName="active" to={`/${to}`}>
                                                <i className={`bi ${icon} h4`} />
                                                <div className="small-text"> {title}</div>
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </> :
                            <>
                                {navLink.map(({ title, to, icon, name }, i) => {
                                    return (
                                        checkfeature(name) && <>
                                            <li className="list-unstyled text-center py-1" key={i}>
                                                <NavLink tag={Link} activeClassName="active" to={`/${to}`}>
                                                    <i className={`bi ${icon} h4`} />
                                                    <div className="small-text"> {title}</div>
                                                </NavLink>
                                            </li>
                                        </>
                                    );
                                })}
                            </>
                        }
                    </div>
                </nav>
            </div>
        </>
    );
};

export default BottomNav;
