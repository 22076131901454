import React from 'react';
import { ScaleLoader } from 'react-spinners';

const AppLoader = (props) => {
    return ( 
        <>
      <main className="main-dash">
        <div className="app">
          <ScaleLoader color={"#023676"}  loading={props.loading} height="70" margin="4" radius="10" speedMultiplier="1.2" />
        </div>
        </main>
       
      </>
     );
}
 
export default AppLoader;