import React, { useState, useEffect } from 'react';

import AdminNavLinks from "./AdminNavLinks";

const AdminSideNav = () => {


  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const closeNav = (e) => {
    document.getElementById("mySidenav").style.width = "0";
  };
  if (document.getElementById("mySidenav")) {
  
    if (window.innerWidth > 580) {
      document.getElementById("mySidenav").style.width = "200px";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }    
  }

  return (
    <>
      <nav className="">
        <div id="mySidenav" className="sidenav">
            <i className="bx bx-x mr-2 sec-text closebtn"
              onClick={(e) => closeNav(e)}
            />

          <AdminNavLinks />

          <hr style={{ backgroundColor: "whitesmoke" }} className="m-3" />
        </div>
      </nav>
    </>
  );
};

export default AdminSideNav;
