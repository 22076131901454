import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";

function UserLogin() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [issending, setissending] = useState(false);
  let history = useHistory();



  // Check if User is logged in, if yes go to dashboard
  if (token && btoken && stoken) {
    history.push('/dashboard');
  }
  // Check if User is logged in, if yes go to dashboard


  // Form Fields
  const [formField, setformField] = useState({});
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //   Login Function
  //   Login Function
  function login(e) {
    e.preventDefault();
    if ((formField.pword && formField.email)) {
      setissending(true);
      const data = new FormData();
      data.append("email", formField.email);
      data.append("pword", formField.pword);
      data.append("apptoken", apptoken);

      axios
        .post(`${ endpoint }/login`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            localStorage.setItem("uname", res.data.data.data.fullname);
            localStorage.setItem("umail", res.data.data.data.email);
            localStorage.setItem("ppic", res.data.data.data.photo);
            localStorage.setItem("token", res.data.data.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data.data));
            setissending(false);
            history.push("/set-profile");
            setTimeout(() => {
              toast.success(res.data.data.message);
            }, 10);
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error !!!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields !!!");
    }
  }
  //   Login Function
  //   Login Function

  // Password Show Function 
  const [eye, seteye] = useState(false);

  function myInput() {
    var x = document.getElementById("security");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    seteye(!eye);
  }
  // Password Show Function 

  // Route to Reni Trust
  function toForgetPW(e) {
    // e.preventDefault()
    if (window.confirm('You will redirected to your Renitrust Account, Confirm?')) {
      window.open('https://renitrust.com/reset-password', '_blank');
    }
  }
  // Route to Reni Trust



  return (
    <>
      <Navbar />

      <section className="login">
        <div className="container">
          <div className="form col-md-6 ml-auto mr-auto shadow br-sm light-bg">
            <div className="container">
              <div className="text-center mb-5 pry-text">
                <h5>Sign In</h5>
                <small>Welcome back, Let’s get you into your RENI account.</small>
              </div>
              <form encType="multipart/form-data">
                <div className="row justify-content-center text-left">
                  <div className="col-md-12 ">
                    <label className="mb-0"> Email:</label>
                    <div className="input-group">
                      <input
                        type="email"
                        className=" input-style"
                        name="email"
                        placeholder="example@example.com"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <label className="mb-0"> Password:</label>
                    <div className="input-group">
                      <input
                        type="password"
                        className=" input-style"
                        name="pword"
                        placeholder="************"
                        onChange={handleChange}
                        required
                        id="security"
                      />
                      <a
                        className={`bi toggle-eye ${ eye ? "bi-eye-slash" : "bi-eye" }`}
                        id="togglePassword"
                        onClick={myInput}
                      >

                      </a>
                    </div>
                  </div>

                  <div className="col-md-12 mx-auto text-center">
                    <div className="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button type="submit" className="btn btn-pry m-0 w-100" disabled>
                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                          </button>
                        </>
                      ) : (
                        <>

                          <button
                            type="submit"
                            className="btn btn-pry m-0 w-100"

                            onClick={(e) => login(e)}
                          >

                            Login
                          </button>
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      <small>
                        Forgot your password?

                        <span onClick={toForgetPW} className="pry-text pointer"> Reset it here.</span>

                      </small>
                      <hr className="m-2" />

                      <small>
                        Don't have an account?

                        <Link to="/register" className="pry-text"> Sign Up</Link>

                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
      />
    </>
  );
}

export default UserLogin;
