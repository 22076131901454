import React from 'react';
import AppLoader from '../../AppLoader'
import { useState } from 'react';
import { useFetch } from '../Global/useFetch';
import axios from 'axios';
import DOMPurify from "dompurify";

const AllNotifications = () => {

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT); 
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));

    //  Get All packages
    const datas = { btoken }

    const { loading, data, fetchData } = useFetch('getNotifications', datas)

    //  Get All packages

    // mark read
    function markRead(e) {
        const data = {
            apptoken,
            usertoken: token,
            id: e
        }
        axios.post(`${endpoint}/openNotification`, data)
            .then((res) => {
                fetchData()
            })
    }
    // mark read

    return (
        <>
            {data?.length === 0 ?
                <div className="col-md-6 mt-5 text-center grey-text mx-auto min-height-lg">
                    <i className='bi bi-bell display-3' />
                    <p>
                        No notifications available
                    </p>
                </div> :
                <>
                    <div className=" min-height-lg col-md-9 col-lg-7 p-0 pt-4">
                        {data?.notifications?.map(({ body, real_date, status, id }, i) =>
                            <div className={`${status === '0' ? 'notification' : 'notification-read'} py-3 br-md light-bg mb-4 container`} key={i}>
                                <h6 className='mb-4' 
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(body)
                                    }}
                                />
                                
                                <div className="d-flex justify-content-between">
                                    <div className="">

                                        <small className="text sec p-2 br-sm ">
                                            <i>
                                                {real_date}
                                            </i>
                                        </small>
                                    </div>
                                    <div className="">

                                        {status === '0' &&
                                            <button className="btn p-0 px-2 sec mr-auto shadow-none"
                                                onClick={e => markRead(id)}
                                            >
                                                mark read
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        ).reverse()}
                    </div>
                </>
            }
            {loading && (<AppLoader />)}

        </>
    );
}

export default AllNotifications;