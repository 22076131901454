import React from 'react';
import { Link } from "react-router-dom";
import BackButton from '../BackButton';
const TitlenBack = ({ title, title1, title2, to, none, btnName, btnIcon }) => {
    return (
        <>
            <div className="dash-title">
                <div className="row align-items-center">
                    <div className="col pry-bold-text d-flex align-items-center">
                        <BackButton title={title} />
                        <span className="h6 mt-1">/ {title1}</span>
                        <span className="h6 mt-1 ml-2 text-truncate"> {title2}</span>
                    </div>
                    <div className={`col ${none}`}>
                        <Link to={`/${to}`}>
                            <button className="btn btn-pry float-right ">
                                <i className={`bx bi ${btnIcon} mr-2 `} />
                                {btnName}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TitlenBack;