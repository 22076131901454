import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Route, Redirect } from 'react-router-dom';

const ProtectUser = ({ component: Component, ...rest }) => {

  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));

  // Get User Access
  // Get User Access
  function getFeatures(e) {
    const data = new FormData();
    data.append("staffusertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserAccess`, data)
      .then((res) => {
        if (res.data.success === false) {
        } else {
          localStorage.setItem('features', JSON.stringify(res.data))
        }
      })
      .catch((error) => {
        toast.error(error.name)
      });

  }
  useEffect(() => {
    getFeatures()
  }, []);
  // Get User Access
  // Get User Access

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (localStorage.getItem('token')) {
            return <Component {...props} />
          }
          else {
            return <Redirect to={
              {
                pathname: "/noAccess"
              }
            } />

          }
        }
      } />
  )
}

export default ProtectUser;
