import React, { useState, useEffect } from 'react';
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import AppLoader from "../../../AppLoader";
import OrderDetails from './OrderDetails'
import AddServicesFromSales from './AddServicesFromSales';


const ServicesItemDetails = ({ props }) => {

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));

    const [load, setload] = useState(false);

    const [obj, setobj] = useState([]);
    const [allservices, setallservices] = useState([]);


    // Form Fields
    const [tableItems, settableItems] = useState({ invtid: "", qty: "1", price: "", amount: "", item_name: "", description: "" });

    // Add New Button
    const addNew = (e) => {
        e.preventDefault();
        if (tableItems.price && tableItems.qty && tableItems.item_name) {
            calc()
            setobj([...obj, tableItems]);
            document.getElementById("itemForm").reset();
            settableItems({ invtid: "", qty: "1", price: "", amount: "", item_name: "", description: "" })
        } else {
            toast.warn('Select a service and add price...')
        }
    };
    const calc = () => {
        tableItems.amount = tableItems.price * tableItems.qty;
    }
    calc()

    // Add New Button


    const handleChange = (e) => {
        settableItems({ ...tableItems, [e.target.name]: e.target.value })
    }
    // Form Fields

    const getServiceInfo = (e) => {
        if (e == "0") {
            let item_name = "Empty Field";
            settableItems({ item_id: e, item_name: item_name })
            calc()
        } else {
            // props.salesFn();
            let idIndex = allservices.findIndex(x => x.id === e)
            let item_name = allservices[idIndex].item;
            let description = allservices[idIndex].details;
            settableItems({ item_id: e, item_name: item_name, description: description, qty: "1", price: "", amount: "" })
            calc()
        }
        calc()
    }


    //  Get All Services
    function getServices(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            btoken: btoken,
            stoken: stoken,
            usertoken: token,
        }
        axios.post(`${endpoint}/getServices`, data
        )
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    setallservices([]);
                } else {
                    setload(false);
                    setallservices(res.data);
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getServices();
    }, []);

    const services = allservices?.map((({ item, id }, i) => {
        return { label: item, value: id }
            ;
    }));
    //  Get All Services

    // Add Item and Inventory Modal 
    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    function workModal() {
        handleShowremove();
    }
    // Add Item and Inventory Modal
    const [showDes, setshowDes] = useState(false);

    return (
        <>
            <form autoComplete="off" id="itemForm">
                <div className="row">
                    <div className="col-md-4 col-lg-5">
                        <label className="mt-3 m-0"> Services:</label>
                        <Select
                            options={services}
                            isSearchable={true}
                            onChange={e => getServiceInfo(e.value)}
                            placeholder='Select Service'

                        />
                        <div className="d-flex ">
                            <input name="des" type="checkbox" id="des" value="des"
                                onClick={(e) => setshowDes(!showDes)}
                                checked={showDes}
                            />
                            <label className="ml-2 m-0 small" for="des"> Description </label>
                        </div>
                        <p
                            className="red-text pointer small"
                            onClick={(e) => workModal()}
                        >
                            Service not found? Click here to add new service
                        </p>
                        {showDes &&
                            <textarea
                                type="text"
                                className="input-style textarea-style"
                                name="description"
                                rows="3"
                                required
                                disabled
                                value={tableItems.description}
                            ></textarea>
                        }
                    </div>

                    <div className="col-md-3 col-lg-2">
                        <label className="mt-3 m-0">Qty: </label>

                        <div className="d-flex align-items-center br-sm ">

                            <input
                                className=" input-style pb-3 px-0 mb-2 text-center d-flex align-items-center"
                                name="qty"
                                type="number"
                                placeholder="qty"
                                onChange={handleChange}
                                value={tableItems.qty}
                                required
                                disabled
                            />

                        </div>
                    </div>
                    <div className="col">
                        <label className="mt-3 m-0">Price:</label>

                        <input
                            name="price"
                            type="number"
                            className="input-style pl-4"
                            placeholder="price"
                            onChange={handleChange}
                            value={tableItems.price}
                            required
                        />
                        <span className="withSign">₦</span>
                    </div>
                    <div className="col ">
                        <label className="mt-3 m-0">Amount:</label>

                        <input
                            name="amount"
                            type="number"
                            className=" input-style pl-4"
                            placeholder="amount"
                            onChange={handleChange}
                            value={tableItems.amount}
                            required
                            disabled
                        />
                        <span className="withSign">₦</span>
                    </div>
                </div>

            </form>
            <div className="text-center mx-auto mb-4">
                <button
                    type="submit"
                    className="btn btn-pry m-0 w-md-100"
                    onClick={e => addNew(e)}
                >
                    Add New
                </button>
            </div>



            {/* Order Details */}
            {/* Order Details */}

            <OrderDetails
                obj={obj}
                setobj={setobj}
                props={props}
                api={'addSalesService'}
            />

            {/* Order Details */}
            {/* Order Details */}

            {load ? <AppLoader /> : <></>}

            {/* Add Services Modal */}
            <AddServicesFromSales
                show={showremove}
                onHide={handleCloseremove}
                animation={false}
                getServices={e => getServices()}
            />
            {/* Add Services Modal */}


        </>
    );
}

export default ServicesItemDetails;