import React from 'react';
import img from './img/security.png'

const AboutShowcase = () => {
    return (
        <>
            <div className="about-showcase">

                <div className="container">

                    <div className="col-md-8 text-justify pb-5">
                        <p className="font-weight-bold pry-text m-0">
                            ABOUT US
                        </p>
                        <footer className='blockquote-footer m-0 mb-4'>
                            RENI, all you need to run a hassle-free business
                        </footer>
                        <h3 className="pt-4 ">
                            Reni Brand is a business suite that specializes in developing solutions for small and medium-sized enterprises.
                        </h3>
                        <p className="pt-4">
                            We are continually creating concepts and developing business solutions to facilitate business operations and boost productivity.
                            
                        </p>
                        <p className="pt-2">
                            We are developing enterprise-wide software that encompasses payment, accounting, inventory, commerce, communication, and logistics solutions.
                        </p>
                        <p className="pt-2">
                            Reni accounts is an accounting and inventory software that provides an effective tracking and bookkeeping solution for businesses to eliminate the everyday headaches of storing and managing inventory flow and transaction data.
                        </p>
                        <p className="pt-2">
                            Operating a business may be difficult, time-consuming, and intricate. To address these issues, we have developed a solution adapted to your everyday business requirements. Whether you own a small business or a business with several locations, Reni Accounts offers a variety of alternatives to meet your business's requirements.
                        </p>
                       
                    </div>

                </div>
            </div>

        </>
    );
}

export default AboutShowcase;