import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios'


export const useFetch1 = (url, datas) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));

    const [loading1, setloading1] = useState(false);
    const [data1, setdata1] = useState([]);

    function fetchData1(e) {
        setloading1(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            ...datas
        }
        axios.post(`${endpoint}/${url}`, data
        )
            .then((res) => {
                if (res.data.success === false) {
                    setloading1(false);
                    toast.warn(res.data.message);
                } else {
                    setloading1(false);
                    setdata1(res.data);
                }
            })
            .catch((error) => {
                setloading1(false);
            });
    }

    useEffect(() => {
        fetchData1()
    }, []);

    return { loading1, data1, fetchData1 }
}
