import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import ExpTitle from './ExpTitle'
import { th } from "../Global/addCommaToNumber";
import { useFetch } from "../Global/useFetch";



export default function AllExp() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);
  const [allExpenses, setallExpenses] = useState([]);
  const [filter, setfilter] = useState('');

  //  Get All expenses
  function getexpenses(e) {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getExpenses`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          setallExpenses(res.data)
        }
      })
      .catch((error) => {
        setload(false);
      });
  }
  useEffect(() => {
    getexpenses()
  }, []);

  useEffect(() => {
    const expenses = allExpenses?.filter((e) => e.expenseCatData.name?.includes(filter))?.map(({ id, details, timeago, datetime, amount, expenseCatData }, i) => {
      let category = expenseCatData.name === '' ? 'Nil' : expenseCatData?.name
      return (
        {
          id: `${id}`,
          timeago: `${timeago}`,
          datetime: `${datetime}`,
          details: <pre className=""> {details} </pre>,
          category: <pre className=""> {category} </pre>,
          amount: <span className="red-text"> ₦{th(amount)}  </span>,
        }
      );
    });

    setDatatable({
      ...datatable,
      rows:
        expenses
    })
  }, [allExpenses, filter]);
  //  Get All expenses


  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Time Added",
        field: "timeago",
        width: 200,
      },
      {
        label: "Date",
        field: "datetime",
        width: 200,
      },
      {
        label: "Item",
        field: "details",
        width: 270,
      },
      {
        label: "Category",
        field: "category",
        width: 270,
      },
      {
        label: "Amount ",
        field: "amount",
        width: 200,
      },
    ],
    rows: []
  });

  //  Get All Exp Categories
  const datas = { btoken, stoken }


  const { loading, data, fetchData } = useFetch('getExpenseCategories', datas)

  const categories = data?.categories?.map(({ name, id }, i) => {
    return (
      <option value={name} key={id}>{name}</option>

    )
  });
  //  Get All Exp Categories
  return (
    <>
      <ExpTitle getexpenses={e => getexpenses()} getExpCat={fetchData} />

      <div className="mt-3 min-height-lg light-bg br-md">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="col-md-4">
            <select name="filter"
              className="input-style input-sm py-0"
              onChange={(e) => setfilter(e.target.value)}
            >
              <option value="">-select category-</option>
              <option value="">All</option>
              {categories}
            </select>
          </div>
        </form>
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsive
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          searchBottom={false}
          className="white"
        />
      </div>
      {load ? (<AppLoader />) : (
        <> </>)}
    </>

  );
}
