import React from "react";
import { Link } from "react-router-dom";

function DashDetails() {
  const obj = [
    {
      title: "Invoice",
      tcolor: "yellow-light-text",
      to: "/sales/invoice",
      bg: "yellow-light-text",
      icon: "bxs-report yellow-light",
    },
    {
      title: "Track My Inventory",
      tcolor: "green-light-text",
      to: "/inventory",
      bg: "green-light-text",
      icon: "bxs-package green-light",
    },
    {
      title: "My Report",
      tcolor: "pink-light-text",
      to: "/reports",
      bg: "pink-light-text",
      icon: "bxs-pie-chart-alt-2 pink-light",
    },
  ];

  return (
    <>
      <div className="text-center mt-4">
        
        {/* First Section */}
        <div className="row justify-content-center">
          <div className="col-md-5 mb-3 ">
            <Link to="/sales">
              <div className="br-sm white shadow-sm">
                <i className="bx bxs-bar-chart-alt-2 bx-tada-hover mr-2 bx-md p-3 my-4 pry-bold sec-bold-text br-lg " />
                <div className="p-4 sec-bold h6 br-sm">
                  <span className="pry-bold-text font-weight-bold">
                    RECORD SALES
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-5 mb-3">
            <Link to="/expenses">
              <div className="br-sm white shadow-sm">
                <i className="bx bxs-wallet bx-tada-hover mr-2 bx-md p-3 my-4 pry-bold sec-bold-text br-lg " />
                <div className="p-4 sec-bold h6 br-sm">
                  <span className="pry-bold-text font-weight-bold">
                    RECORD EXPENSES
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* First Section */}

        {/* Second Section */}
        <div className="col-md-10 mt-5 ml-auto mr-auto">
          {obj.map(({ title, bg, to, icon }, i) => {
            return (
              <Link to={to} key={i}>
                <div className="row align-items-center white br-sm mt-4">
                  <div className={`${bg}`}>
                    <i className={`bx bx-tada-hover bx-md p-2 p-1 br-sm ${icon}`} />
                  </div>
                  <div className="col-7 ml-auto mr-auto">
                    <span className={`font-weight-bold ${bg}`}>
                      {title}
                    </span>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {/* Second Section */}

      </div>
    </>
  );
}

export default DashDetails;
