import React, { useCallback, useEffect, useState } from "react";
import Wrapper from '../../../../Wrapper';
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import PayWithWallet from './PayWithWallet';
import TitlenBack from "../../Global/TitlenBack";




const MakePayment = (props) => {

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [public_key] = useState(process.env.REACT_APP_FLUTTERWAVE_PUBLICKEY);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [umail] = useState(localStorage.getItem("umail"));
    const [bphone] = useState(JSON.parse(localStorage.getItem("bData")).phone);
    const [bname] = useState(localStorage.getItem('bname'));
    const [load, setload] = useState(false);

    const [amount, setamount] = useState('');
    const [amountth, setamountth] = useState('');
    const [name, setname] = useState('');
    const [pid, setpid] = useState('');
    const [days, setdays] = useState('');
    let history = useHistory();

    // Check for Empty Fields 
    useEffect(() => {
        if (props.location.state) {
            setamount(props.location.state.amount)
            setamountth(props.location.state.amount_th)
            setname(props.location.state.name)
            setpid(props.location.state.id)
            setdays(props.location.state.duration_days)
        } else {
            toast.warning('Choose a Package!')
            history.push('/settings/packages')
        }
    }, []);
    // Check for Empty Fields 

    const walletObj = { amount, amountth, pid, name, days }

    // Pay With Wallet

    const [showremove1, setShowremove1] = useState(false);
    const handleCloseremove1 = () => setShowremove1(false);
    const handleShowremove1 = () => setShowremove1(true);
    const [iid, setiid] = useState('');

    function workModal1(e) {
        setiid(e);
        handleShowremove1();
    }
    // Pay With Wallet

    // FLUTTERWAVE PAYMENT
    // FLUTTERWAVE PAYMENT
    const configflutter = {
        public_key: public_key,
        tx_ref: Date.now(),
        amount: Number(amount),
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: umail,
            phone_number: bphone,
            name: bname,
        },
        customizations: {
            title: "Package Payment",
            description: `Payment for ${bname} `,
            logo: "https://renitrust.com/theimages/logo.png",
        },
    };

    const handleFlutterPayment = useFlutterwave(configflutter);

    let random = '' // setting a token to prevent callback twice
    const FlutterHookBtn = () => {
        return (
            <button
                className="btn btn-yellow pry-text mt-3"
                onClick={(e) => {
                    random = Date.now()
                    handleFlutterPayment({
                        callback: (response) => {
                            if (response.status === 'successful') {
                                closePaymentModal();
                                updatePay(response.tx_ref);
                                // this will close the modal programmatically
                            } else {
                                closePaymentModal(); // this will close the modal programmatically
                                alert('unsuccessful')
                            }
                        },
                        onClose: () => { },
                    });
                }}
            >
                FLUTTERWAVE
            </button>
        );
    };

    // FLUTTERWAVE PAYMENT
    // FLUTTERWAVE PAYMENT

    // On Success or Failure of Payment
    // On Success or Failure of Payment

    const updatePay = (e) => {
        if (random) {
            setload(true)
            const data = {
                apptoken: apptoken,
                usertoken: token,
                btoken: btoken,
                amount: amount,
                packageid: pid,
                trxref: e,
                duration_days: days,
            }
            axios.post(`${endpoint}/subscribeUser_card`, data)
                .then((res) => {
                    if (res.data.success === true) {
                        random = ''
                        setload(false);
                        alert(res.data.message);
                        toast.success(res.data.message)
                        setTimeout(() => {
                            history.push('/settings/billing')
                        }, 1500);
                    } else {
                        setload(false);
                        toast.warning(res.data.message)

                    }
                })
                .catch((error) => {
                    setload(false);
                });
        } else {
            setload(false)
            toast.success('Double sausage detected, dapada')

        }
    };
    // On Success or Failure of Payment
    // On Success or Failure of Payment


    const pagename = 'settings'
    const route = "upgrade"

    return (
        <>

            <Wrapper pagename={pagename} route={route}>

                {/* Title */}
                <TitlenBack title={"BILLING"} title1={'PACKAGES'} title2={` / ${'PAYMENT'}`} none={"d-none"} />

                {/* Title */}

                <div className="mt-5 min-height-lg">
                    <div className="row">

                        <div className="col-md-12 mb-4">
                            <div className="card px-3 text-center py-3">
                                <h6>
                                    You are about to make payment for the package <span className="pry-text"> {name} </span>
                                </h6>
                            </div>
                        </div>

                        {load ?
                            <div className="mt-5 mx-auto text-center">
                                <ClipLoader color={"#023676"} loading={load} speedMultiplier="1.2" />
                                <div className="mt-4 ">
                                    <h5 className="font-weight-light">
                                        Please wait, your transaction is processing{" "}
                                    </h5>
                                    <h6> Do not refresh page!!! </h6>
                                </div>
                            </div>
                            : (
                                <>
                                    <div className="col-md-6 mb-4 text-center">
                                        <div className="card py-4 h-100">
                                            PAY WITH RENI ACCOUNT

                                            <h6 className='mt-4'>Amount: <br />
                                                <span className="h3 pry-text font-weight-bold mt-3"> ₦{amountth}</span>
                                            </h6>

                                            <button className='btn btn-pry mt-3' onClick={(e) => workModal1(walletObj)}>Pay with wallet</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4 text-center">
                                        <div className="card py-4 h-100">
                                            PAY WITH CARD

                                            <h6 className='mt-4'>Amount: <br />
                                                <span className="h3 pry-text font-weight-bold mt-3"> ₦{amountth}</span>
                                            </h6>

                                            <FlutterHookBtn />

                                        </div>
                                    </div>
                                </>
                            )}
                    </div>

                </div>

            </Wrapper>

            <PayWithWallet
                show={showremove1}
                onHide={handleCloseremove1}
                animation={false}
                id={iid}
            />

        </>
    );
}

export default MakePayment;