import React, { useState } from "react";
import Title from "../../Components/User/Global/Title";
import BizProfile from "../../Components/User/Settings/BizProfile";
import PersonalProfile from "../../Components/User/Settings/PersonalProfile";
import AllStaffs from "../../Components/User/Settings/Staff/AllStaff";
import Upload from "../../Components/User/Settings/Upload";
import StoreProfile from "../../Components/User/Settings/StoreProfile";
import Upgrade from "../../Components/User/Settings/Upgrade";
import Wrapper from "../../Wrapper";

const Settings = () => {

  let navLink = [
    { to: "#store-profile", title: "Store Profile", idTab: "store-profile-tab" },
    { to: "#staff", title: "Staff", idTab: "store-tab" },
    { to: "#upload", title: "Upload", idTab: "upload-tab" },
    { to: "#profile", title: "Personal Profile", idTab: "profile-tab" },
    { to: "#upgrade", title: "Upgrade", idTab: "upgrade-tab" },
  ];

  let tabPage = [
    { component: <StoreProfile />, id: "store-profile" },
    { component: <AllStaffs />, id: "staff" },
    { component: <Upload />, id: "upload" },
    { component: <PersonalProfile />, id: "profile" },
    { component: <Upgrade />, id: "upgrade" },
  ]

  const pagename = "settings"

  return (
    <>

      <Wrapper pagename={pagename}>
        <Title
          title={"SETTINGS"}
          link={"/settings/billing"}
          btnName={"Billing"}
          btnIcon={"bi-cash-stack"}
        />

        <div className="mt-2 white settings min-height-lg">
          <div className="nav-tab">

            <ul className="nav nav-tabs text-center" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#biz-profile"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Business Profile
                </a>
              </li>

              {navLink.map(({ to, title }, i) => {
                return (
                  <li className="nav-item" key={i}>
                    <a
                      className="nav-link"
                      id="home-tab"
                      data-toggle="tab"
                      href={to}
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="biz-profile"
              role="tabpanel"
              aria-labelledby="biz-profile-tab"
            >
              <BizProfile />
            </div>

            {tabPage.map(({ component, id }, i) =>
              <div
                className="tab-pane fade"
                id={id}
                role="tabpanel"
                aria-labelledby={`${id}-tab`}
              >
                {component}
              </div>

            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Settings;
