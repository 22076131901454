import React, { useState } from 'react';
import AddWaybill from './AddWaybill';

function WaybillTitle(props) {

  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1() {
    handleShowremove1();
  }

  return (
    <div className="dash-title">
      <div className="row align-items-center">
        <div className="col pry-bold-text">
          <h5>WAYBILL</h5>
        </div>
        {/* <div className="col ">
          <button className="btn btn-pry float-right" onClick={(e) => workModal1()}>
            <i className="bx bx-plus mr-2 " />
            Add New
          </button>
        </div> */}
      </div>


      {/* Add Waybill Modal */}
      <AddWaybill
        show={showremove1}
        onHide={handleCloseremove1}
        animation={false}
        getwaybill={e=>props.getwaybill()}
      />
      {/* Add Waybill Modal */}
    </div>
  );
}

export default WaybillTitle;
