import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import EditItem from "./EditItem";
import ItemTitle from "./ItemTitle";
import DeleteModal from "../Global/DeleteModal";


export default function AllServices({ type, changeType }) {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);

  // Edit Modal
  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);
  const [iid, setiid] = useState('');

  function workModal1(e) {
    setiid(e);
    handleShowremove1();
  }
  // Edit Modal

  //  Get All Items
  function getItems(e) {

    setload(true);
    const data = {
      apptoken: apptoken,
      btoken: btoken,
      stoken: stoken,
      usertoken: token,
    }
    axios
      .post(`${endpoint}/getServices`, data)
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          const items = res.data.map(({ id, item, timeago }, i) => {
            const ids = { item_id: id }
            return (
              {
                id: `${id}`,
                item: `${item}`,
                timeago: `${timeago}`,
                action:
                  <h6 className="d-flex fit-content border-bottom pointer border-success green-text"
                    onClick={(e) => workModal1(id)}
                  >
                    <i className="bi bi-pen-fill h6 mr-2"
                    /> Edit</h6>
                ,
                delete:
                  <DeleteModal
                    ids={ids}
                    name={item}
                    url={'deleteItem'}
                    Fn={getItems}
                    from={'Services'}

                  >
                    <h6 className="d-flex fit-content  border-bottom pointer border-danger red-text"
                    >
                      <i className="bi bi-trash3-fill h6 mr-2"
                      /> Delete</h6>
                  </DeleteModal>
              }
            );
          });
          setDatatable({
            ...datatable,
            rows:
              items
          })
        }
      })
      .catch((error) => {
        setload(false);
      });
  }

  useEffect(() => {
    getItems()
  }, []);
  //  Get All Items




  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Item",
        field: "item",
        width: 270,
      },
      {
        label: "Date Added",
        field: "timeago",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      },
      {
        label: "Delete",
        field: "delete",
        width: 200,
      },
    ],
    rows: []
  });



  return (
    <>
      <ItemTitle getItemFn={e => getItems()} />


      {/* Select buttons for Services or Products */}

      <div
        className="d-flex sec-bold py-2 px-2 br-sm fit-content mx-auto mt-4 justify-content-between">
        <div
          className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center 
                    ${type === 'product' ? 'pry-bold light-text' : ('')} `}
          onClick={e => changeType('product')}
        >
          PRODUCT
        </div>
        <div
          className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center ${type === 'service' ? 'pry-bold light-text' : ('')} `}
          onClick={e => changeType('service')}
        >
          SERVICES
        </div>
      </div>

      {/* Select buttons for Services or Products */}

      <div className="mt-3 min-height-lg">
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsiveLg
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          searchBottom={false}
          className="white br-md"
        />
      </div>
      {load && <AppLoader />}


      {/* Edit Item Modal */}
      <EditItem
        show={showremove1}
        onHide={handleCloseremove1}
        animation={false}
        id={iid}
        getItemFn={e => getItems()}
      />
      {/* Edit Item Modal */}
    </>

  );
}
