import React from 'react';


const ReportCard = ({ data }) => {

    return (
        <>
            <div className="mt-4">
                <h5 className="pry-text text-center mb-4"> Report Card </h5>
                {
                    data?.length === 0 &&
                    <div className="col-md-6 mt-5 text-center grey-text mx-auto">
                        <i className='bi bi-file-earmark-bar-graph display-4' />
                        <p>
                            No report data available
                        </p>
                    </div>}
                <div className="row">
                    {data.map(({ month_year, revenue_th, expense_th, grossProfit_th, netProfit_th, costPrice_th, creditors_th, debtors_th, carryOverNetProfit_th, cashAtHand_th, vat_th }, i) => {

                        const data = [
                            {
                                name: "Revenue",
                                color: "red",
                                icon: "bi-bank",
                                value: `₦ ${ revenue_th || 0.00 }`
                            },
                            {
                                name: "Cost Price",
                                color: "yellow",
                                icon: "bi-cash",
                                value: `₦ ${ costPrice_th || 0.00 }`
                            },
                            {
                                name: "Gross Profit",
                                color: "pink",
                                icon: "bi-cash-stack",
                                value: `₦ ${ grossProfit_th || 0.00 }`
                            },
                            {
                                name: "Expenses",
                                color: "red",
                                icon: "bi-cash-coin",
                                value: `₦ ${ expense_th || 0.00 }`
                            },
                            {
                                name: "Debt",
                                color: "green",
                                icon: "bi-arrow-right-square",
                                value: `₦ ${ creditors_th || 0.00 }`
                            },
                            {
                                name: "Credit",
                                color: "pink",
                                icon: "bi-arrow-left-square",
                                value: `₦ ${ debtors_th || 0.00 }`
                            },
                            {
                                name: "Net Profit",
                                color: "yellow",
                                icon: "bi-wallet",
                                value: `₦ ${ netProfit_th || 0.00 }`
                            },
                            {
                                name: "Balance Brought Down",
                                color: "red",
                                icon: "bi-arrow-down-square",
                                value: `₦ ${ carryOverNetProfit_th || 0.00 }`
                            },
                            {
                                name: "Total (NP+BBD)",
                                color: "green",
                                icon: "bi-wallet2",
                                value: `₦ ${ cashAtHand_th || 0.00 }`
                            },
                            {
                                name: "VAT",
                                color: "yellow",
                                icon: "bi-coin",
                                value: `₦ ${ vat_th || 0.00 }`
                            },
                        ];
                        return (
                            <div className="col-md-6 mb-4" key={i}>
                                <div className="card br-md">
                                    <div className="py-3 pry light-text pl-3" style={{ borderRadius: "1rem 1rem 0 0" }} >
                                        Report for {month_year}
                                    </div>
                                    <div className="card-body">
                                        {data.map(({ name, color, value, icon }, i) =>
                                            <>
                                                <div className="d-flex justify-content-between my-2" key={i}>
                                                    <p className="m-0">
                                                        <i className={` ${ color }-bold-text ${ color }-light ${ icon } bi py-1 px-1 mr-2 br-sm`} />
                                                        {name}:
                                                    </p>
                                                    <p className="m-0 text-wrap w-50">
                                                        {value}
                                                    </p>
                                                </div>
                                                <hr className='m-0 p-0' />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );

                    }).reverse()}
                </div>
            </div>

        </>
    );
};

export default ReportCard;