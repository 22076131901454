import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import axios from "axios";
import { ClipLoader } from 'react-spinners';



const Features = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [btoken] = useState(localStorage.getItem("btoken"));
    const [load, setload] = useState(false);
    const [feature, setfeature] = useState(false);
    const [sending, setsending] = useState();
    const [showalert, setshowalert] = useState(false);
    const [alertt, setalertt] = useState("");


    useEffect(() => {
        if (props.work === true) {
            checkForFeature(props.value)
        }
    }, []);

    // Form Fields
    const [formField, setformField] = useState({});
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
        confirmF(feature ? false : true)
    }
    // Form Fields


    const checkForFeature = () => {
        setload(true);

        const data = {
            usertoken: props.staff,
            apptoken: apptoken,
            btoken: btoken,
            feature: props.value
        }
        axios
            .post(`${endpoint}/checkUserAccess`, data)
            .then((res) => {
                if (res.data.success === false) {

                    setload(false);
                   
                    setalertt(res.data.message)
                    setfeature(res.data.success)
                } else {
                    setload(false);
                    setalertt(res.data.message)
                    setfeature(res.data.success)

                }
            })
            .catch((error) => {
                setalertt(error.message)
                setload(false);
            });
    }

    // Add a feature
    function addFeature(e) {
        setsending(true);
        const data = new FormData();
        data.append("usertoken", props.staff);
        data.append("btoken", btoken);
        data.append("feature", props.value);
        data.append("status", e);
        data.append("apptoken", apptoken);

        axios
            .post(`${endpoint}/updateUserAccess`, data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                if (res.data.success === false) {
                    setsending(false);
                    setshowalert(true);
                    toast.success(res.data.message);
                    setfeature(feature)
                    checkForFeature()
                } else {
                    setsending(false);
                    setshowalert(true);
                    checkForFeature()
                    toast.success(res.data.message);
                }
            })
            .catch((error) => {
                setsending(false);
                setshowalert(true);
                toast.error("Network Error!");
                setfeature(feature)
            });

    }

    function confirmF(e) {
        if (window.confirm(`Are you sure you want to confirm?`)) {
            addFeature(e);
        }
    }
    // Add a feature




    return (

        <>
            {load ?
                <ClipLoader color={"#023676"} loading={props.load} speedMultiplier="1.2" />
                : <>
                    {sending ?
                        <ClipLoader color={"#023676"} loading={props.load} speedMultiplier="1.2" /> : <>

                            <label className="col-md-5 sec-bold pry-bold-text font-weight-bold p-3 mb-4 ml-1 " for={props.value}>
                                <div className="custom-control custom-radio mb-2 ">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        name={props.value}
                                        id={props.value}
                                        value={props.value}
                                        onChange={e => handleChange(e)}
                                        checked={feature ? true : false}

                                    />
                                    <label className="custom-control-label" for={props.value}>
                                        <span className="text-uppercase">
                                            {props.name}
                                        </span>
                                    </label> <br />
                                    <small>{alertt}</small>
                                </div>
                            </label>

                        </>}

                </>}


        </>
    )
        ;
}

export default Features;



