import React, { useState } from 'react';
import Chart from "react-apexcharts";



const ReportChart = ({ revenue, getChartData }) => {

    const [chartType, setchartType] = useState('area');

    // To get the Months and Amount of Revenue and Expense
    const allRevMon = revenue.map(({ month_year }) => { return month_year })
    const allRevAmount = revenue.map(({ revenue }) => { return revenue })
    const allExpAmount = revenue.map(({ expense }) => { return expense })
    const allGPAmount = revenue.map(({ grossProfit }) => { return grossProfit })
    const allNPAmount = revenue.map(({ netProfit }) => { return netProfit })
    const allCPAmount = revenue.map(({ costPrice }) => { return costPrice })
    // To get the Months and Amount of Revenue and Expense


    // Chart Details
    // Chart Details
    const bizChart = {
        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: allRevMon

            }
        },
        series: [
            {
                name: "Revenue",
                data: allRevAmount
            },
            {
                name: "Expenses",
                data: allExpAmount
            },
            {
                name: "Cost Price",
                data: allCPAmount
            },
            {
                name: "Gross Profit",
                data: allGPAmount
            },
            {
                name: "Net Profit",
                data: allNPAmount
            },
        ],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 1,
                opacityTo: 1,
            }
        },
    };

    // Chart Details
    // Chart Details

    return (
        <>
            <div className="my-2">
                <h5 className='text-center mb-4 pry-text'>
                    Chart
                </h5>
                <div className="">
                </div>
            </div>

            <div className="my-1 border light-bg pb-5 br-md">

                <div className="col-md-5 col-10 mx-auto py-4">
                        <h6>
                            Months:
                        </h6>
                        <select className="custom-select" onChange={(e) => getChartData(e.target.value)}>
                            <option value="1">1 month</option>
                            <option value="2">2 months</option>
                            <option value="3">3 months</option>
                            <option value="4">4 months</option>
                            <option value="5">5 months</option>
                            <option value="6">6 months</option>
                            <option value="7">7 months</option>
                            <option value="8">8 months</option>
                            <option value="9">9 months</option>
                            <option value="10">10 months</option>
                            <option value="11">11 months</option>
                            <option value="12" selected>12 months</option>
                            </select>
                        </div>
          
                <Chart
                    options={bizChart.options}
                    series={bizChart.series}
                    type={chartType}
                />
            </div>

        </>
    );
}

export default ReportChart;













{/* <div className="col-md-4">
                            <h6>
                                CHART TYPE:
                            </h6>
                            <select className="browser-default custom-select" onChange={(e) => setchartType(e.target.value)}>
                                <option value="bar" >BAR CHART</option>
                                <option value="area">AREA CHART</option>
                                <option value="line">LINE CHART</option>
                            </select>
                        </div> */}