import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import Navbar from '../../Components/Homepage/Navbar/Navbar';

const NoPageAccess = () => {
  let history = useHistory()
  if (localStorage.getItem('token')) {
    history.push('/dashboard')
  }

  return (
    <>
      <Navbar />

      <main className="pagenotfound light-bg">
        <div className="container">
          <div className="col-md-7 mx-auto text-center ">
            <div className="sec-bold br-md py-5 container">
              <h5 className="pry-text font-weight-light"> You need to be signed-in to access this page. </h5>
              <Link to="/login">
                <button className="btn pry light-text mt-3 btn-lg"> Sign In </button>
              </Link>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NoPageAccess;
