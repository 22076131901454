import React from 'react';
import AllSupplier from '../../Components/User/Supplier/AllSupplier';
import Wrapper from '../../Wrapper';


const Suppliers = () => {

    const pagename = 'suppliers'
    return ( 
        <>
            <Wrapper pagename={pagename}>
                <AllSupplier />
            </Wrapper>
        
        
        </>
     );
}
 
export default Suppliers;