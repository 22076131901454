import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";

function CreateStore() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"))
  const [issending, setissending] = useState(false);
  let history = useHistory();

  // Form Fields
  const [formField, setformField] = useState({ name: '', address: '' });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //   Setup Store Function
  //   Setup Store Function
  function setupStore(e) {
    e.preventDefault();
    if (formField.name && formField.address && btoken) {
      setissending(true);
      const data = new FormData();
      data.append("storename", formField.name);
      data.append("location", formField.address);
      data.append("usertoken", token);
      data.append("btoken", btoken);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/createStore`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            localStorage.setItem("stoken", res.data.stoken);
            history.push("/dashboard")
            setTimeout(() => {
              toast.success(res.data.message);
            }, 10);
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }
  //   Setup Store Function
  //   Setup Store Function

  //  Get Business Details Function
  const getbizData = () => {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getBusinessDetails`, data,)
      .then((res) => {
        if (res.data.success == false) {
        } else {

          localStorage.setItem('bData', JSON.stringify(res.data.details))
        }
      }).catch((error) => {
      });
  };
  useEffect(() => {
    getbizData();
  }, 0);
  //  Get Business Details Function

  // Route to Create Biz if Btoken is not available in the local storage
  if (!btoken) {
    history.push("/create-business");
  }
  // Route to Create Biz if Btoken is not available in the local storage

  return (
    <>
      <Navbar />

      <section className="login">
        <div className="container">
          <div className="form col-md-6 ml-auto mr-auto shadow br-sm light-bg">
            <div className="container">
              <div className="text-center mb-5 pry-text">
                <h5>Add a Store / Branch</h5>
                <small>Add a store / branch to your business.</small>
              </div>
              <form enctype="multipart/form-data">
                <div className="row justify-content-center text-left">
                  <div className="col-md-12 ">
                    <label className="mb-0">Store Name:</label>
                    <input
                      type="text"
                      className=" input-style"
                      name="name"
                      placeholder="e.g. Fireswitch Tech"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Store Address:</label>
                    <textarea
                      type="text"
                      className=" input-style"
                      name="address"
                      placeholder="Address"
                      onChange={handleChange}
                      required
                      autoComplete="false"
                    />
                  </div>

                  <div className="col-md-12 mx-auto text-center">
                    <div className="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button type="submit" className="btn btn-pry m-0 w-100" disabled>
                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-pry m-0 w-100"

                            onClick={(e) => setupStore(e)}
                          >
                            Submit
                          </button>
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      <small>
                        {" "}
                        By clicking the button above, you agree to our{" "}
                        <span className="pry-text">
                          <Link>Terms & Conditions</Link>
                        </span>{" "}
                        and{" "}
                        <span className="pry-text">
                          <Link>Privacy Policy </Link>
                        </span>{" "}
                        .
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
      />
    </>
  );
}

export default CreateStore;
