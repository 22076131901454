import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import FilterReport from '../BizReport/FilterReport';
import { useHistory, useParams } from 'react-router-dom';

const StoreFilterReport = () => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [load, setload] = useState(false);
    const [data, setdata] = useState('');
    const id = useParams()
    let history = useHistory()
    

    // Check for Empty Fields 
    useEffect(() => {
        if (id?.id) {
        } else {
            toast.warning('Choose Store!')
            history.push('/reports/stores')
        }
    }, []);
    // Check for Empty Field


    //  Get Filter Chart Data
    function filterData(e, to, from) {
        e.preventDefault()
        setload(true);
        const data = {
            apptoken,
            usertoken: token,
            btoken,
            stoken: id?.id,
            to, from
        }
        axios.post(`${endpoint}/filterSReports`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setdata(res.data.data)
                }
            })
            .catch((error) => {
                setload(false);
            });
    }

    //  Get Filter Chart Data
    return (
        <>

            <FilterReport
                filterData={filterData}
                data={data}
                load={load}
            />


        </>
    );
}

export default StoreFilterReport;