import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";



const EditItem = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);

  const [key, setkey] = useState("");

  // Form Fields
  const [formField, setformField] = useState({ item_name: "", details: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  // Get Item Detailsall
  const getItemData = () => {
    setload(true)
    const data = new FormData();
    data.append("item_id", props.id);
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getItemDetail`, data)
      .then((res) => {
        if (res.data.success == false) {
          setload(false)
        } else {
          setformField({ item: res.data.item_name, des: res.data.details });

          setload(false)
        }
      }).catch((error) => {
        setload(false)
      });
  };
  useEffect(() => {
    getItemData();
  }, [props.show]);
  // Get Item Details

  //  Edit Item Function
  //  Edit Item Function
  function itemEdit(e) {
    e.preventDefault();
    if (formField.des && formField.item) {
      setissending(true);
      const data = {
        details: formField.des,
        name: formField.item,
        sid: key,
        item_id: props.id,
        apptoken: apptoken,
        usertoken: token,
        btoken: btoken
      }

      axios.post(`${endpoint}/editItem`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            props.getItemFn();
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }

  //  Edit Item Function
  //  Edit Item Function


  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Edit Item - {formField.item}
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                    {load && <span className="d-flex justify-content-center align-items-center"><i className="bx bx-loader bx-spin bx-sm pry-text mr-3" /> Loading Data... </span> }
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Item name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="item"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.item}
                        />
                      </div>
                    
                      <div className="col-md-12 ">
                        <label className="mb-0"> Details:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="des"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.des}
                        />
                      </div>

                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry px-5"

                                onClick={(e) => itemEdit(e)}
                              >
                                submit
                              </button>
                            </>
                          )}
                          <button
                            type="button"
                            className="btn btn-border px-5"
                            onClick={props.onHide}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditItem;
