import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import PayForFree from './PayForFree';
import { useFetch } from '../../Global/useFetch';


const YearlyPackages = () => {

    let history = useHistory();
    let location = useLocation();
    const [path] = useState(location.pathname);

    //  Get All packages
    const { loading, data } = useFetch('getPackages_yearly');

    //  Get All packages

    // Route to Paymet Page
    function payPage(e, a, id) {
        const obj = { e };
        history.push({
            pathname: `/settings/packages/pay/${ id }`,
            state: e
        });
    }
    // Route to Paymet Page

    // Pay With Wallet

    const [showremove1, setShowremove1] = useState(false);
    const handleCloseremove1 = () => setShowremove1(false);
    const handleShowremove1 = () => setShowremove1(true);
    const [iid, setiid] = useState('');

    function workModal1(e) {
        setiid(e);
        handleShowremove1();
    }
    // Pay With Wallet


    return (
        <>
            <div className="packages ">

                {loading === true ?
                    <div className="text-center my-5 pb-5">
                        <ClipLoader color={"#023676"} loading={loading} speedMultiplier="1.2" />
                    </div> : (
                        <>
                            {
                                data?.length === 0 &&
                                <div className="col-md-6 mt-5 text-center grey-text mx-auto">
                                    <i className='bi bi-shield-exclamation display-4' />
                                    <p>
                                        No business plan available
                                    </p>
                                </div>}
                            <div className="row my-5">

                                {data?.data?.map((item, i) => {
                                    const { amount_th, features, name, duration_days, id } =
                                        item;

                                    return (

                                        <div className="col-md-6 col-lg-4 mb-4 text-left" key={i}>
                                            <div className="card pry-border h-100 py-5 px-3 br-md">
                                                <div className="card-body">
                                                    <h5 className='text-right bi bi-box-seam pry-text' />
                                                    <p className="pry-bold-text font-weight-bold mb-3">{name}</p>
                                                    <h3 className="pry-text mb-3"> ₦ {amount_th}</h3>
                                                    <p className="pry-bold-text mb-4"> {duration_days} days</p>
                                                    <hr className='pry-border' />
                                                    <div className="">
                                                        <p className=" mb-3"> Includes:</p>

                                                        <ul className="list-unstyled">
                                                            {features.map((item, i) => {
                                                                const { details } =
                                                                    item;
                                                                return (

                                                                    <li className="list-item mb-2">
                                                                        <i className='bi bi-check br-md pry-bld-text sec-bold me-2' /> {details}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-center px-4">
                                                    {path === '/' ?
                                                        <Link to="/register">
                                                            <button className="pry-text br-xlg sec-bold btn shadow-none w-100 m-0 mb-3 font-weight-bold">
                                                                Get Started
                                                            </button>
                                                        </Link>
                                                        :
                                                        <>
                                                            {path === '/about' ?
                                                                <Link to="/register">
                                                                    <button className="pry-text br-xlg sec-bold btn shadow-none w-100 m-0 mb-3 font-weight-bold">
                                                                        Get Started
                                                                    </button>
                                                                </Link> :
                                                                <>
                                                                    {
                                                                        name === 'Free Tier' ?
                                                                            <button className="pry-text br-xlg sec-bold btn shadow-none w-100 m-0 mb-3 font-weight-bold"
                                                                                onClick={(e) => workModal1(item)}>Start for Free ✨
                                                                            </button>
                                                                            :
                                                                            <button className="pry-text br-xlg sec-bold btn shadow-none w-100 m-0 mb-3 font-weight-bold"
                                                                                onClick={e => payPage(item, e, id)}>Subscribe
                                                                            </button>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </>
                    )}
            </div>

            <PayForFree
                show={showremove1}
                onHide={handleCloseremove1}
                animation={false}
                id={iid}
            />
        </>
    );
};

export default YearlyPackages;