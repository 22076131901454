import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useFetch } from "../../Global/useFetch";
import { Button, PopoverBody, UncontrolledPopover, PopoverHeader } from "reactstrap";


const ItemUpload = () => {


    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [token] = useState(localStorage.getItem("token"));

    const [issending, setissending] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [isFilePicked, setisFilePicked] = useState(false);
    const [itemCategory, setitemCategory] = useState('');

    const formatter = Intl.NumberFormat('en', {
        style: 'unit', unit: 'kilobyte'
    })

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setisFilePicked(true);
    };


    //  Get All Item Categories
    const datas = { btoken, stoken }

    const { data } = useFetch('getItemCategories', datas)

    const categories = data?.data?.map(({ category, id }, i) => {
        return (
            <option value={id} key={i}> {category} </option>
        )
    });
    //  Get All Item Categories


    function uploadFile(e) {
        e.preventDefault();
        if (selectedFile) {
            setissending(true)
            const data = new FormData();
            data.append("apptoken", apptoken);
            data.append("btoken", btoken);
            data.append("stoken", stoken);
            data.append("usertoken", token);
            data.append("icid", itemCategory);
            data.append("file", selectedFile);
            axios.post(`${endpoint}/uploadData-CSVItems`,
                data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then((res) => {
                    if (res.data.success === false) {
                        setissending(false)
                        toast.warn(res.data.message);
                    } else {
                        setissending(false)
                        toast.success(res.data.message);
                    }
                })
                .catch((error) => {
                    setissending(false)
                    toast.error("Network Error!");
                });
        } else {
            toast.error("Empty Fields!");
            setissending(false)
        }
    }

    return (
        <div>

            <form enctype="multipart/form-data" onSubmit={(e) => uploadFile(e)}>
                <div className="row justify-content-center">
                    <div className="col-md-12 ">
                        <label>Item Category: </label>
                        <select
                            name="item_id"
                            className="input-style"
                            onChange={e => setitemCategory(e.target.value)}
                        >
                            <option value="">
                                Select Item Category
                            </option>
                            {categories}
                        </select>
                    </div>

                    <div className="col-md-12 ">
                        <label> CSV File: </label>
                        <Button
                            id="PopoverFocus"
                            type="button"
                            className="shadow-none"
                            color="none p-0"

                        >
                            <i className="bi bi-cloud-arrow-down-fill pry-text h6" />
                        </Button>
                        <UncontrolledPopover
                            placement="top"
                            target="PopoverFocus"
                            // trigger="focus"
                        >
                            <PopoverHeader className="sec-bold pry-bold-text">
                                NOTE
                            </PopoverHeader>
                            <PopoverBody
                                className="">
                                Download Format:
                                <a href="http://system.reniaccounts.com/archive/sampleItemCSV.csv" target="_blank">
                                <button className="btn btn-sm pry light-text">
                                    Download
                                </button>
                                </a>
                             </PopoverBody>
                        </UncontrolledPopover>
                        <input
                            type="file"
                            className=" input-style"
                            placeholder="Enter Email"
                            onChange={changeHandler}
                            pattern="^.+\.(xlsx|xls|csv)$"
                        />
                        {isFilePicked ? (
                            <div>
                                <p>Filename: {selectedFile?.name}</p>
                                <p>Filetype: {selectedFile?.type}</p>
                                <p>Size: {formatter.format(selectedFile?.size)}</p>
                            </div>
                        ) : (
                            <p>Select a file to show details</p>
                        )}
                    </div>

                    <div className="col-md-12  mx-auto text-center">
                        <div className="mb-4">

                            <button
                                type="button"
                                className="btn btn-pry m-0 w-lg-75 w-md-100"
                                onClick={uploadFile}
                                disabled={issending}
                            >
                                {issending ? "loading..." : "Upload"}
                            </button>
                        </div></div>
                </div>
            </form>
        </div>
    )
}

export default ItemUpload