import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import WaybillTitle from './WaybillTitle';
import { useHistory } from "react-router-dom";

export default function AllWaybill() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);
  const [allWaybill, setallWaybill] = useState([]);
  const [waybillId, setWaybillId] = useState('');
  const history = useHistory();

  //  Get All waybill
  function getwaybill(e) {
    setload(true);
    const data = {
      usertoken: token,
      btoken: btoken,
      stoken: stoken,
      apptoken: apptoken,
    };
    axios
      .post(`${ endpoint }/wayBill.fetch`, data)
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          setallWaybill(res.data.data);
        }
      })
      .catch((error) => {
        setload(false);
      });
  }
  useEffect(() => {
    getwaybill();
  }, []);

  const doWaybill = (item) => {
    setWaybillId(item?.token);
    history.push({
      pathname: `/waybill/${ item?.invid }`,
      state: {obj: 'this'},
    });
  };

  useEffect(() => {
    const waybill = allWaybill?.map((item, i) => {
      const { id, real_date, invid, driverName, driverNumber, vehicleNumber, rName, rNumber } = item;
      return (
        {
          id: `${ id }`,
          real_date: `${ real_date }`,
          invid: `${ invid }`,
          driverName: `${ driverName }`,
          driverNumber: `${ driverNumber }`,
          vehicleNumber: `${ vehicleNumber }`,
          rName: `${ rName }`,
          rNumber: `${ rNumber }`,
          action:
            <h6 className="d-flex fit-content border-bottom pointer border-primary pry-text"
              onClick={() => doWaybill(item)}
            >
              <i className="bi bi-eye-fill h6 mr-2"
              /> View </h6>

        }
      );
    });

    setDatatable({
      ...datatable,
      rows:
        waybill
    });
  }, [allWaybill]);
  //  Get All waybill


  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Date",
        field: "real_date",
        width: 200,
      },
      {
        label: "Invoice ID",
        field: "invid",
        width: 200,
      },
      {
        label: "Driver's Name",
        field: "driverName",
        width: 200,
      },
      {
        label: "Driver's Number",
        field: "driverNumber",
        width: 200,
      },
      {
        label: "Vehicle's Number",
        field: "vehicleNumber",
        width: 200,
      },
      {
        label: "Receiver's Name",
        field: "rName",
        width: 200,
      },
      {
        label: "Receiver's Number",
        field: "rNumber",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      }

    ],
    rows: []
  });

  return (
    <>
      <WaybillTitle getwaybill={e => getwaybill()} />

      <div className="mt-3 min-height-lg light-bg br-md">
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsive
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          searchBottom={false}
          className="white"
        />
      </div>

      {load ? (<AppLoader />) : (
        <> </>)}

      {/* Invoice Reciept */}
      <div className="mt-5">
        <iframe src={`/waybill/${ waybillId }`} name="frame2" className="d-none w-100"></iframe>
      </div>
      {/* Invoice Reciept */}
    </>

  );
}
