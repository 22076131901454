import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AppLoader from '../../AppLoader'

function StoreProfile() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  const [sname, setsname] = useState("");
  const [address, setaddress] = useState("");

  const [load, setload] = useState(false);
  const [issending, setissending] = useState(false);

  const getStoreData = () => {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("stoken", stoken);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getStoreDetails`, data,)
      .then((res) => {
        if (res.data.success == false) {
          setload(false);
        } else {
          setaddress(res.data.location);
          setsname(res.data.name);
          setload(false);
        }
      }).catch((error) => {
        setload(false);
      });
  };
  useEffect(() => {
    getStoreData();
  }, []);
  //   Get Settings


  //  Update Business Details Function
  function storeUpdate(e) {
    e.preventDefault()
    if (sname == "") {
      toast.error("Empty Fields!");
      setissending(false)
    } else {
      e.preventDefault();
      setissending(true)
      const data = {
        storename: sname, location: address, apptoken, usertoken: token, btoken, stoken
      }
      axios.post(`${endpoint}/updateStore`,
        data)
        .then((res) => {
          if (res.data.success === false) {
            setissending(false)
            toast.warn(res.data.message);
          } else {
            setissending(false)
            getStoreData();
            toast.success(res.data.message);
            seteditme(true)
          }
        })
        .catch((error) => {
          setissending(false)
          toast.error("Network Error!");
        });
    }
  }
  //  Update Business Details Function





  // Edit Function

  const [editme, seteditme] = useState(true);
  function onEdit() {
    seteditme(false)
    if (editme === true) {
      toast.success('You can now edit...')
    } else { }
  }
  // Edit Function

  return (
    <>

      <div className="form mt-1">
        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" > STORE PROFILE </h6>
        </div>
        <h6 className="pry-text sec fit-content br-sm p-2 mt-2">
          Store ID: <span className="font-weight-bold"> {stoken}</span>
        </h6>
        <div className="text-right mb-4">
          <button className="btn shadow-none" onClick={onEdit}>
            <h6 className=" border-bottom border-primary pry-text">
              <i className="bi bi-pen-fill h6"
              /> EDIT
            </h6>
          </button>
        </div>
        <div className="container">

          <form onSubmit={(e) => storeUpdate(e)}>
            <div className="row justify-content-center">
              <div className="col-md-12 ">
                <label> Store Name: </label>
                <input
                  type="text"
                  className=" input-style"
                  placeholder="Enter Name"
                  onChange={(e) => setsname(e.target.value)}
                  value={sname}
                  required
                  disabled={editme ? true : false}
                />
              </div>
              <div className="col-md-12 ">
                <label> Store Location: </label>
                <textarea
                  type="text"
                  disabled={editme ? true : false}
                  className="input-style textarea-style"
                  placeholder="Store Location"
                  onChange={(e) => setaddress(e.target.value)}
                  value={address}
                ></textarea>
              </div>
              {!editme &&
                <div className="col-md-12  mx-auto text-center">
                  <div className="mb-4">
                    {issending ? (
                      <>
                        <button
                          className="btn btn-pry m-0 w-lg-75 w-md-100"
                          disabled
                        >
                          Loading...
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-pry m-0 w-lg-75 w-md-100"
                        >
                          submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
          </form>
        </div>
      </div>
      {load ? <AppLoader /> : <></>}

    </>
  );
}

export default StoreProfile;
