
export const data = [
    {
        name: 'Inventory',
        id: "#inventory",
        usage: [
            {
                title: "Create Inventory",
                content: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis",
                img: "https://renicloud.infura-ipfs.io/ipfs/QmX1NSSnJpKpyCfBAMZvndtchGWid9e4U2H6GzXQxXiJwj"
            },
            {
                title: "Edit Inventory",
                content: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis",
                img: "https://renicloud.infura-ipfs.io/ipfs/QmX1NSSnJpKpyCfBAMZvndtchGWid9e4U2H6GzXQxXiJwj"
            },
        ]
    },
    {
        name: 'Expenses',
        id: "#expenses",
        usage: [
            {
                title: "Create expenses",
                content: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis",
                img: "https://renicloud.infura-ipfs.io/ipfs/QmX1NSSnJpKpyCfBAMZvndtchGWid9e4U2H6GzXQxXiJwj"
            },
            {
                title: "Edit expenses",
                content: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis",
                img: "https://renicloud.infura-ipfs.io/ipfs/QmX1NSSnJpKpyCfBAMZvndtchGWid9e4U2H6GzXQxXiJwj"
            },
        ]
    },
]