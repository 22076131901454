import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useFetch1 } from "../../Global/useFetch1";
import Select from "react-select";


const AddServicesFromSales = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  const [issending, setissending] = useState(false);
  const [icid, seticid] = useState("");


  const [formField, setformField] = useState({ item_name: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }

  const datas = { btoken, stoken };

  const { loading1, data1, fetchData1 } = useFetch1('getItemCategories', datas);

  const categories = data1?.data?.map(({ category, id }, i) => {
    return (
      { label: category, value: id }
    );
  });

  function inventAdd(e) {
    e.preventDefault();
    if (formField.item_name) {
      setissending(true);

      const data = {
        item_name: formField.item_name,
        btoken,
        stoken,
        usertoken: token,
        apptoken,
        icid,
      };

      axios
        .post(`${ endpoint }/createServiceFromSales`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.getServices();
            props.onHide();
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error !!!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }

  function clear() {
    setformField({ item_name: "" });
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton>
            <h6 className="font-weight-bold pry-text ml-auto">
              Create new Service
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Category:</label>
                        <Select
                          options={categories}
                          isSearchable={true}
                          onChange={e => seticid(e.value)}
                          placeholder='Select category'

                        />

                      </div>
                      <div className="col-md-12 ">
                        <label className="mb-0"> Service name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="item_name"
                          placeholder="e.g. Copy Writing"
                          onChange={handleChange}
                          required
                          value={formField.item_name}
                        />
                      </div>


                      <div className="col-md-10 mx-auto text-center">

                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"

                                onClick={(e) => inventAdd(e)}
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddServicesFromSales;
