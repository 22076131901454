import React from 'react';
import DashDetails from '../../Components/User/Dashboard/DashDetails';
import Title from '../../Components/User/Global/Title';
import Wrapper from '../../Wrapper';

const Dashboard = () => {

    const pagename = "dashboard"

    return (
        <>
      <Wrapper pagename={pagename}>
                <Title title={"DASHBOARD"} none={"d-none"} />
                <DashDetails />
            </Wrapper>

        </>
    );
}

export default Dashboard;