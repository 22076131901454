import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../../AppLoader";
import { useHistory } from "react-router-dom";


export default function AllInvoice() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);

  let history = useHistory();

  //  Get All nvoice
  function getInvoice(e) {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("apptoken", apptoken);

    axios.post(`${ endpoint }/getSalesRecords`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          const invoice = res.data.map(({ sales_date, invid, customer_name, customer_mail, qty, timeago, total_amount_th, customer_phone }, i) => {
            let customerName = !customer_name ? 'Nil' : customer_name;

            return (
              {
                invid: `${ invid }`,
                sales_date: `${ sales_date }`,
                customer_name: `${ customerName }`,
                total_amount_th: <span className="green-text"> ₦{total_amount_th}  </span>,
                customer_phone: `${ customer_phone }`,
                customer_mail: `${ customer_mail }`,
                qty: `${ qty }`,
                timeago: `${ timeago }`,
                action:
                  <h6 className="d-flex fit-content  border-bottom pointer border-primary pry-text"
                    onClick={(e) => doFood(invid, sales_date, customer_name, customer_mail, customer_phone)}
                  >
                    <i className="bi bi-eye-fill h6 mr-2"
                    /> View </h6>

              }
            );
          });

          setDatatable({
            ...datatable,
            rows: invoice
          });
        }
      })
      .catch((error) => {
        setload(false);
      });
  }
  useEffect(() => {
    getInvoice();
  }, []);

  const doFood = (inv) => {
    const obj = { inv: inv };
    history.push({
      pathname: `/sales/invoice/${ inv }`,
      state: obj,
    });
  };


  //  Get All nvoice
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "Invoice ID",
        field: "invid",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Date Created",
        field: "sales_date",
        width: 270,
      },
      {
        label: "Period Created",
        field: "timeago",
        width: 270,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        width: 270,
      },
      {
        label: "Customer Email",
        field: "customer_mail",
        width: 270,
      },
      {
        label: "Customer Phone",
        field: "customer_phone",
        width: 270,
      },
      {
        label: "Total Amount",
        field: "total_amount_th",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      }
    ],
    rows: []
  });
  //  Get All invoice

  return (
    <>

      <div className="mt-5 dash-title">
        <div className="col pry-bold-text">
          <h5>All Invoice </h5>
        </div>
      </div>


      <div className="mt-3 min-height-lg">
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsive
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          // striped
          bordered
          searchBottom={false}
          className="white br-md"
        />
      </div>
      {load ? (<AppLoader />) : (<> </>)}

    </>

  );
}
