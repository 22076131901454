import React, { useState } from "react";
import { useEffect } from "react";
import InventoryUpload from "./Upload/InventoryUpload";
import ItemUpload from "./Upload/ItemUpload";


function Upload() {

  const [chooseData, setchooseData] = useState('');
  const [choosedData, setchoosedData] = useState('item');


  let upload = [
    { type: "Item", name: "item", component: <ItemUpload /> },
    { type: "Inventory", name: "inventory", component: <InventoryUpload /> },
  ];
  const uploadData = upload.map(({ name, type }, i) => {
    return (
      <option value={name} key={i}> {type} </option>
    )
  });

  useEffect(() => {
    setchoosedData(upload.find((x) => x.name === chooseData))
  }, [chooseData]);

  return (
    <>

      <div className="form mt-1">
        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" > UPLOAD <span className="text-uppercase"> {choosedData?.name || "DATA"} </span>
          </h6>
        </div>

        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-6 ">
              <label> What Data do you want to Upload?: </label>
              <select
                className="input-style"
                onChange={e => setchooseData(e.target.value)}
              >
                <option value="">
                  Select Data Type
                </option>
                {uploadData}
              </select>
            </div>
          </div>
          {chooseData &&
          choosedData?.component}


        </div>
      </div>

    </>
  );
}

export default Upload;
