import React from 'react';
import Title from '../../Components/User/Global/Title';
import AllNotifications from '../../Components/User/Notification/AllNotifications';
import Wrapper from '../../Wrapper';

const Notification = () => {
    return (
        <>
            <Wrapper
                pagename={'dashboard'}
                route={'upgrade'}
                        >

                <Title title={"NOTIFICATIONS"} none={"d-none"} />

                <AllNotifications />

            </Wrapper>

        </>
    );
}

export default Notification;