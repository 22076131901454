import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AppLoader from "../../../AppLoader";
import Select from "react-select";
import OrderDetails from "./OrderDetails";
import AddIntentoryFromSales from "./AddIntentoryFromSales";

const ProductItemDetails = ({ props }) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));

    const [load, setload] = useState(false);

    const [obj, setobj] = useState([]);
    const [allinventory, setallinventory] = useState([]);

    //  Get All Inventory
    function getInvent(e) {
        setload(true);
        const data = new FormData();
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);
        axios
            .post(`${endpoint}/getInventory`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                } else {
                    setload(false);
                    setallinventory(res.data);
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getInvent()
    }, []);

    const invent = allinventory?.map((({ item_name, item_id }, i) => {
        return { label: item_name, value: item_id }
    }));
    //  Get All Inventory

    // Add and subtract Button
    const [qty, setqty] = useState(Number());

    function add() {
        var newQty = parseInt(document.getElementById('number').value);
        newQty = isNaN(newQty) ? 0 : newQty;
        newQty++;
        setqty(newQty);
        setTableItems({ ...tableItems, qty: newQty })
    }
    function des() {
        var newQty = parseInt(document.getElementById('number').value);
        newQty = isNaN(newQty) ? 0 : newQty;
        newQty < 1 ? (newQty = 1) : <></>;
        newQty--;
        setqty(newQty);
        setTableItems({ ...tableItems, qty: newQty })
    }

    // Add and subtract Button

    // Quantity Add
    function qtyAdd(e) {
        setqty(e)
        setTableItems({ ...tableItems, qty: e })
    }
    // Quantity Add


    // Item Form
    const [tableItems, setTableItems] = useState({
        invtid: "", item_id: "", qty: 1, price: 0, amount: Number(), item_name: "", description: ""
    })

    // Add New Button
    const addNew = (e) => {
        e.preventDefault();

        if (+parseFloat(qty).toFixed(2) > +availableQty) {
            toast.error(`only ${availableQty} ${tableItems.item_name}(s) is available.`)
        } else {
            if (tableItems.price == 0 || qty <= 0) {
                toast.warn('Select an item or a quantity..')
            } else {
                calc();
                setobj([...obj, tableItems]);
                document.getElementById("itemForm").reset();
                setTableItems({
                    invtid: "", item_id: "", qty: 1, price: 0, amount: Number(), item_name: "", description: ""
                })
                setqty((Number()))

            }
        }
    };
    const handleChange = (e) => {
        setTableItems({ ...tableItems, [e.target.name]: e.target.value })

    }
    // Add New Button
    // Item Form


    // Get Item Price and Calc Amount
    const [id, setid] = useState();
    const [availableQty, setavailableQty] = useState(0);
    const getItemPrice = (e) => {
        if (e === "0") {
            let pricee = 0;
            let item_name = "Empty Field";
            let description = ""
            setTableItems({ invtid: id, item_id: e, price: pricee, item_name: item_name, amount: pricee, qty: qty, description: description })
            calc()
        } else {
            // props.salesFn();
            let priceIndex = allinventory.findIndex(x => x.item_id === e)
            let pricee = allinventory[priceIndex].selling_price;
            let availableQty = allinventory[priceIndex].qty;
            let id = allinventory[priceIndex].id;
            let description = allinventory[priceIndex].details;
            setavailableQty(availableQty)
            setid(id)
            let item_name = allinventory[priceIndex].item_name;
            setTableItems({ invtid: id, item_id: e, price: pricee, item_name: item_name, amount: pricee, qty: qty, description: description })
            calc()
        }
    }
    const calc = () => {
        tableItems.amount = tableItems.price * qty;
    }
    calc()
    // Get Item Price and Calc Amount


    // Add Item and Inventory Modal 
    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    function workModal() {
        handleShowremove();
    }
    // Add Item and Inventory Modal

    const [showDes, setshowDes] = useState(false);
    const [editPrice, seteditPrice] = useState(false);

    return (
        <>
            {/* Item Details */}
            {/* Item Details */}


            <form autoComplete="off" id="itemForm">
                <div className="row">
                    <div className="col-md-4 col-lg-5">
                        <label className="mt-3 m-0"> Inventory:</label>
                        <Select
                            options={invent}
                            isSearchable={true}
                            onChange={e => getItemPrice(e.value)}
                            placeholder='Select Inventory'
                        />
                        <div className="d-flex ">
                            <input name="des" type="checkbox" id="des" value="des"
                                onClick={(e) => setshowDes(!showDes)}
                                checked={showDes}
                            />
                            <label className="ml-2 m-0 small" for="des"> Description </label>
                        </div>

                        <p
                            className="red-text pointer small"
                            onClick={(e) => workModal()}
                        >
                            Inventory not found? Click here to add new inventory
                        </p>
                        {showDes &&
                            <textarea
                                type="text"
                                className="input-style textarea-style"
                                name="description"
                                rows="3"
                                required
                                disabled
                                value={tableItems.description}
                            ></textarea>
                        }

                    </div>

                    <div className="col-md-3 col-lg-2">
                        <label className="mt-3 m-0">Qty: </label>

                        <div className="d-flex align-items-center br-sm ">
                            <i className={`bi bi-file-minus-fill p-1 pry-text h4 mt-2 `} onClick={des} />
                            <input
                                className=" input-style pb-3 px-0 mb-2 text-center  d-flex align-items-center"
                                name="qty"
                                type="number"
                                placeholder="qty"
                                onChange={e => qtyAdd(e.target.value)}
                                value={qty}
                                required
                                id="number"
                            />
                            <i className={`bi bi-file-plus-fill p-1 pry-text h4 mt-2`} onClick={add} />
                        </div>
                    </div>
                    <div className="col">
                        <label className="mt-3 m-0">Price:</label>

                        <input
                            name="price"
                            type="number"
                            className="input-style pl-4"
                            placeholder="price"
                            onChange={handleChange}
                            value={tableItems.price}
                            required
                            disabled={editPrice ? false : true}
                        />
                        <div className="d-flex ">
                            <span className="withSign">₦</span>
                            <input name="editPrice" type="checkbox" id="editPrice" value="editPrice"
                                onClick={(e) => seteditPrice(!editPrice)}
                                checked={editPrice}
                            />
                            <label className="ml-2 m-0 small" for="editPrice"> Edit Price </label>
                        </div>
                    </div>
                    <div className="col ">
                        <label className="mt-3 m-0">Amount:</label>

                        <input
                            name="amount"
                            type="number"
                            className=" input-style pl-4"
                            placeholder="amount"
                            onChange={handleChange}
                            value={tableItems.amount}
                            required
                            disabled
                        />
                        <span className="withSign">₦</span>
                    </div>
                </div>

            </form>
            <div className="text-center mx-auto mb-4">
                <button
                    type="submit"
                    className="btn btn-pry m-0 w-md-100"
                    onClick={e => addNew(e)}
                >
                    Add New
                </button>
            </div>

            {/* Item Details */}
            {/* Item Details */}


            {/* Order Details */}
            {/* Order Details */}

            <OrderDetails
                obj={obj}
                setobj={setobj}
                props={props}
                api={'addSalesItem'}
            />

            {/* Order Details */}
            {/* Order Details */}

            {load ? <AppLoader /> : <></>}


            {/* Add Inventory Modal */}
            <AddIntentoryFromSales
                show={showremove}
                onHide={handleCloseremove}
                animation={false}
                getInvent={e => getInvent()}
            />
            {/* Add Inventory Modal */}



        </>
    );
}

export default ProductItemDetails;