import React, { useState } from 'react';
import BackButton from '../BackButton';
import AddItem from './AddItem';

function ItemTitle(props) {

  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1() {
    handleShowremove1();
  }


  return (
    <div className="dash-title">
      <div className="row align-items-center">
        <div className="col pry-bold-text d-flex align-items-center">
          <BackButton title={"INVENTORY"} />
          <span className="h6 mt-1">/ ITEMS</span>
        </div>
        <div className="col ">
          <button className="btn btn-pry float-right " onClick={(e) => workModal1()}>
            <i className="bx bx-plus mr-2 " />
            Add New
          </button>
        </div>
      </div>


      {/* Add Item Modal */}
      <AddItem
        show={showremove1}
        onHide={handleCloseremove1}
        onShow={handleShowremove1}
        animation={false}
        getItemFn={e => props.getItemFn()}
      />
      {/* Add Item Modal */}
    </div>
  );
}

export default ItemTitle;
