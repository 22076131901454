import React, { useEffect, useState } from "react";
import AddInventory from './AddInventory';
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";
import EditInventory from "./EditInventory";
import DeleteModal from "../Global/DeleteModal";
import { Link } from "react-router-dom";


export default function AllInventory() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);

  // Edit Modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);
  const [iid, setiid] = useState('');

  function workModal(e) {
    setiid(e);
    handleShowremove();
  }
  // Edit Modal

  // Check Access
  // Check Access
  const checkfeature = (feature) => {
    const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature);

    if (data >= 0) {
      return true;
    } else {
      return false;
    }
  };
  // Check Access
  // Check Access


  //  Get All Inventory
  function getInvent(e) {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${ endpoint }/getInventory`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          const inventory = res.data.map(({ id, item_name, details, batch_no, expiry_date, timeago, selling_price_th, cost_price_th, qty, unit }, i) => {
            const ids = { invtid: id };
            let batchNo = batch_no === '' ? 'Nil' : batch_no;


            return (
              {
                id: `${ id }`,

                item_name: `${ item_name }`,

                details: `${ details }`,

                qty: `${ (unit === "0" || unit === '' || unit === undefined) ? qty : qty + ' ' + unit }`,

                selling_price_th: `₦${ selling_price_th }`,

                cost_price_th: `₦${ cost_price_th }`,

                batch_no: `${ batchNo }`,

                expiry_date: `${ expiry_date }`,

                timeago: `${ timeago }`,

                action: checkfeature('inventory_edit') &&
                  <>
                    <h6 className="d-flex fit-content  border-bottom pointer border-success green-text"
                      onClick={(e) => workModal(id)}
                    >
                      <i className="bi bi-pen-fill h6 mr-2"
                      /> Edit</h6>
                  </>
                ,
                delete: checkfeature('inventory_delete') &&
                  <>
                    <DeleteModal
                      ids={ids}
                      name={item_name}
                      url={'deleteInventory'}
                      Fn={getInvent}
                      from={'Inventory'}

                    >
                      <h6 className="d-flex fit-content  border-bottom pointer border-danger red-text"
                      >
                        <i className="bi bi-trash3-fill h6 mr-2"
                        /> Delete</h6>
                    </DeleteModal>
                  </>
              }
            );
          });

          setDatatable({
            ...datatable,
            // columns: [
            //   {
            //     label: `${ checkfeature('inventory_edit') ? 'Action' : '' }`,
            //     field: "action",
            //     width: 200,
            //   },
            //   {
            //     label: `${ checkfeature('inventory_delete') ? 'Delete' : '' }`,
            //     field: "delete",
            //     width: 200,
            //   },
            // ],
            rows:
              inventory
          });
        }
      })
      .catch((error) => {
        setload(false);
      });
  }
  useEffect(() => {
    getInvent();
  }, []);


  //  Get All Inventory
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Batch No",
        field: "batch_no",
        width: 270,
      },
      {
        label: "Inventory",
        field: "item_name",
        width: 270,
      },
      {
        label: "Details",
        field: "details",
        width: 270,
      },
      {
        label: "Quantity",
        field: "qty",
        width: 270,
      },
      {
        label: "Cost Price",
        field: "cost_price_th",
        width: 270,
      },
      {
        label: "Selling Price",
        field: "selling_price_th",
        width: 270,
      },
      {
        label: "Expiry Date",
        field: "expiry_date",
        width: 270,
      },
      {
        label: "Date Added",
        field: "timeago",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 200,
      },
      {
        label: "Delete",
        field: "delete",
        width: 200,
      },
    ],
    rows: []
  });
  //  Get All Inventory



  // Add Inventory Modal 
  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1() {
    handleShowremove1();
  }
  // Add Inventory Modal

  return (
    <>

      <div className="mt-5 dash-title">
        <div className="row align-items-center">
          <div className="col pry-bold-text">
            <h5>All Inventory</h5>
          </div>
          <div className="col ">
            <button className="btn btn-pry float-right " onClick={(e) => workModal1()}>
              <i className="bx bx-plus mr-2 " />
              Add Inventory
            </button>
            <button
              onClick={() => window.frames['frame1'].print()}
              className="btn btn-pry float-right">
              <i className="bx bx-printer mr-2 " />
              Print
            </button>
          </div>
        </div>


        {/* Add Inventory Modal */}
        <AddInventory show={showremove1} onHide={handleCloseremove1} animation={false}
          getInventoryFn={e => getInvent()} />
        {/* Add Inventory Modal */}
      </div>


      <div className="mt-3" >
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsive
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          searchBottom={false}
          className="white br-md"
        />
      </div>
      {load ? <AppLoader /> : <> </>}


      <EditInventory
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        id={iid}
        getInventoryFn={e => getInvent()}
      />


      {/* Invoice Reciept */}
      <div className="mt-5">
        <iframe src={`/inventory/print`} name="frame1" className="d-none w-100"></iframe>
      </div>
      {/* Invoice Reciept */}
    </>

  );
}
