import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";

function UserReg() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);
  let history = useHistory();

  // Form Fields
  const [formField, setformField] = useState({});
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields


  //   Regsiter Function
  //   Regsiter Function
  function register(e) {
    e.preventDefault();
    if (formField.name && formField.email && formField.pword && formField.cpword && formField.phone) {
      setissending(true);
      const data = new FormData();
      data.append("name", formField.name);
      data.append("email", formField.email);
      data.append("pword", formField.pword);
      data.append("cpword", formField.cpword);
      data.append("phone", formField.phone);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/register`, data, {
          headers: {
            "content-type": "multipart/form-pldata",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            history.push("/login")
            setTimeout(() => {
              toast.success(`${res.data.message}, Check your email to activate your account.`);
            }, 10);
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }
  //   Regsiter Function
  //   Regsiter Function

  // Password Show Function 
  const [eye, seteye] = useState(false);
  const [eye1, seteye1] = useState(false);

  function pwd() {
    var x = document.getElementById("pwd");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    seteye(!eye)

  }
  function cpwd() {
    var x = document.getElementById("cpwd");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    seteye1(!eye1)

  }


  return (
    <>
      <Navbar />

      <section className="login">
        <div className="container">
          <div className="form col-md-6 ml-auto mr-auto shadow br-sm light-bg">
            <div className="container">
              <div className="text-center mb-5 pry-text">
                <h5>Get Started </h5>
                <small>Open a RENI account today to begin your journey.</small>
              </div>
              <form enctype="multipart/form-data">
                <div className="row justify-content-center text-left">
                  <div className="col-md-12 ">
                    <label className="mb-0"> Name:</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className=" input-style"
                        name="name"
                        placeholder="e.g. Jane Doe"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Email:</label>
                    <div className="input-group">
                      <input
                        type="email"
                        className=" input-style"
                        name="email"
                        placeholder="example@example.com"
                        onChange={handleChange}
                        autoComplete="false"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Phone Number:</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className=" input-style"
                        name="phone"
                        placeholder="080123456789"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Password:</label>
                    <div className="input-group">
                      <input
                        type="password"
                        className=" input-style"
                        name="pword"
                        placeholder="************"
                        onChange={handleChange}
                        required
                        autoComplete="false"
                        id="pwd"
                      />
                      <a
                        className={`bi toggle-eye ${eye ? "bi-eye-slash" : "bi-eye"}`}
                        id="togglePassword"
                        onClick={pwd}
                      >

                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Comfirm Password:</label>
                    <div className="input-group">
                      <input
                        type="password"
                        className=" input-style"
                        name="cpword"
                        placeholder="************"
                        onChange={handleChange}
                        required
                        id="cpwd"
                      />
                      <a
                        className={`bi toggle-eye ${eye1 ? "bi-eye-slash" : "bi-eye"}`}
                        id="togglePassword"
                        onClick={cpwd}
                      >

                      </a>
                    </div>
                  </div>

                  <div className="col-md-12 mx-auto text-center">
                    <div className="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button type="submit" className="btn btn-pry m-0 w-100" disabled>
                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-pry m-0 w-100"
                            onClick={(e) => register(e)}
                          >
                            {" "}
                            Create Account
                          </button>
                        </>
                      )}
                    </div>

                    <div className="text-center">
                      <small>
                        {" "}
                        By clicking the button above, you agree to our{" "}
                        <span className="pry-text">
                          <Link>Terms & Conditions</Link>
                        </span>{" "}
                        and{" "}
                        <span className="pry-text">
                          <Link>Privacy Policy </Link>
                        </span>{" "}
                        .
                      </small>
                      <hr className="m-2" />

                      <small>
                        Already have an account?
                        <span className="pry-text">
                          <Link to="/login"> Login</Link>
                        </span>
                        .
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
      />
    </>
  );
}

export default UserReg;
