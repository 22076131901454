import React from 'react';
import { Link } from 'react-router-dom';
import img from '../Homepage/img/inv_mngt.png';
import img1 from '../Homepage/img/fin_mngt.png';
import img2 from '../Homepage/img/fin_rep.png';


const WhatWeOffer = () => {

    const offer = [
        {
            img: img,
            title: 'Inventory Management',
            content: 'Reni accounting enables firms to identify what goods to order, in what quantity, and when. The process identifies and responds to trends to ensure that there is always sufficient inventory to fulfil client orders and that a shortage is properly communicated. The software indicates the expiration date of each inventory item and provides a report on the performance of each item to assist you in making a more informed product selection based on the volume of orders they receive. You will never run out of stock with Reni!'
        },
        {
            img: img1,
            title: 'Financial Management',
            content: 'Reni account assists you in managing your business accounting records by tracking your sales and allows you to record your daily spending so that you may offer a financial overview of your business activities. You can make invoices and email them to customers or print them out as proof of payment, as well as manage all essential financial indicators pertaining to the operations and profitability of your firm.'
        },
        {
            img: img2,
            title: 'Financial Report',
            content: 'Reni account provides a comprehensive report of your financial transactions for any given time period. It eliminates the burden associated with calculating your profit, cost of goods, and other accounting indicators. The reports give information on finances, product performance, retail performance, and business performance as a whole.'
        },
    ];
    return (
        <>
            <main className="whatweoffer">
                <div className="container">

                    <div className=" text-center first">
                        <div className="light-bg pt-1 px-5 fit-content mx-auto mb-4"></div>
                        <h3 className="pb-5">
                            What you’ll love to use everyday
                        </h3>
                    </div>

                    <div className="row">
                        {
                            offer.map(({ img, title, content }, i) =>
                                <div className="col-md-4 mb-3 h-100">
                                    <div className="card shadow-none">
                                        <img src={img} alt="Image" />
                                        <div className="card-body">
                                            <h4 className="my-4">
                                                {title}
                                            </h4>
                                            <p className="text-justify">
                                                {content}
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            )
                        }
                    </div>


                    {/* <div className="row align-items-center mb-5">
                        <div className="col-md-6 text-sm-left text-center">
                            <img src={img} alt="showcase" width='80%' />
                        </div>
                        <div className="col-md-6 ">
                            <h4 className="my-4">
                                Inventory Management
                            </h4>
                            <p className="">
                                Reni accounting enables firms to identify what goods to order, in what quantity, and when. The process identifies and responds to trends to ensure that there is always sufficient inventory to fulfil client orders and that a shortage is properly communicated. The software indicates the expiration date of each inventory item and provides a report on the performance of each item to assist you in making a more informed product selection based on the volume of orders they receive. You will never run out of stock with Reni!
                            </p>

                        </div>
                    </div>

                    <div className="row align-items-center mb-5 whatweoffer_1">
                        <div className="col-md-6 text-sm-right text-center">
                            <img src={img1} alt="showcase" width='80%' />
                        </div>
                        <div className="col-md-6 ">
                            <h4 className="my-4">
                                Financial Management
                            </h4>
                            <p className="">
                                Reni account assists you in managing your business accounting records by tracking your sales and allows you to record your daily spending so that you may offer a financial overview of your business activities. You can make invoices and email them to customers or print them out as proof of payment, as well as manage all essential financial indicators pertaining to the operations and profitability of your firm.
                            </p>

                        </div>
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-md-6 text-sm-left text-center">
                            <img src={img2} alt="showcase" width='80%' />
                        </div>
                        <div className="col-md-6 ">
                            <h4 className="my-4">
                                Financial Report
                            </h4>
                            <p className="">
                                Reni account provides a comprehensive report of your financial transactions for any given time period. It eliminates the burden associated with calculating your profit, cost of goods, and other accounting indicators. The reports give information on finances, product performance, retail performance, and business performance as a whole.
                            </p>

                        </div>
                    </div> */}

                    <div className="my-5 text-center">
                        <Link to="/register">
                            <button className="btn pry light-text br-sm btn-lg">
                                Get Started
                            </button>
                        </Link>
                    </div>
                </div>
            </main>

        </>
    );
};

export default WhatWeOffer;