import React from 'react';
import { Link } from 'react-router-dom';


const OtherProducts = () => {
    return (
        <>
            <main className="products sec" id='products'>

                <div className="container">
                    <div className="mb-5 text-center">
                        <div className="pry pt-1 px-5 fit-content mx-auto mb-4"></div>
                        <h3 className="pry-text">
                            Enjoy other Products and services from us
                        </h3>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mb-4 ">
                            <div className="red-light py-4 br-md h-100">
                                <div className="card-body red-bold-text">

                                    <i className='bi bi-shield-lock-fill h2 py-3 red-light-text' />

                                    <h5 className='py-4'>Reni Trust</h5>
                                    <p className='py-3'>
                                        ReniTrust is an escrow wallet. ReniTrust is a comprehensive solution to the lack of TRUST in Internet transactions in Africa. We aid online retailers in gaining the trust of prospective clients. We go to considerable measures to ensure that our clients' financial transactions are entirely risk-free and secure.
                                    </p>

                                    <a href='https://renitrust.com' target='_blank' className='red-bold-text font-weight-bold'>
                                        visit site
                                        <i className='bi bi-link-45deg h5 py-3' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 ">
                            <div className="sec-bold py-4 br-md h-100">
                                <div className="card-body pry-bold-text">

                                    <i className='bi bi-cart-fill h2 py-3 pry-text' />

                                    <h5 className='py-4'>Reni Store</h5>
                                    <p className='py-3'>
                                        Reni Store is an e-commerce platform with a dynamic retail process that carries a vast selection of products, including those for personal care, mobile devices, fashion, and more. The store experience is optimised to provide shoppers with a sense of control and decisiveness. Our escrow system supports the online store, making all transactions risk-free.
                                    </p>

                                    <Link href='!#' className='font-weight-bold'>
                                        <a href='https://reni.store' target='_blank' className='pry-text'>
                                            visit site
                                        </a>
                                    </Link>
                                    <i className='bi bi-link-45deg h5 py-3 pry-text' />

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 ">
                            <div className="green-light-bg py-4 br-md h-100 ">
                                <div className="card-body green-bold-text">

                                    <i className='bi bi-shield-lock-fill h2 py-3 green-light-text' />

                                    <h5 className='py-4'>Reni Pay</h5>
                                    <p className='py-3'>
                                        ReniPay is a blockchain based wallet. ReniPay is a comprehensive solution to the lack of TRUST in Internet transactions in Africa. We aid online retailers in gaining the trust of prospective clients. We go to considerable measures to ensure that our clients' financial transactions are entirely risk-free and secure.
                                    </p>

                                    <Link href='!#' className='font-weight-bold'>
                                        <a href='#!' target='_blank' className='green-bold-text'>
                                            coming soon...
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
};

export default OtherProducts;