import React from "react";
import Wrapper from "../../Wrapper";
import AllWaybill from "../../Components/User/Waybill/AllWaybill";

function Waybill() {

  const pagename = "inventory";

  return (
    <>
      <Wrapper pagename={pagename} >

        <AllWaybill />

      </Wrapper>
    </>
  );
}

export default Waybill;
