import React, { useState } from "react";
import AllItems from "../../Components/User/Items/AllItems";
import AllServices from "../../Components/User/Items/AllServices";
import Wrapper from "../../Wrapper";

function Item() {

  const pagename = "inventory"

  // Sales Type Function
  const [type, settype] = useState('product');
  function changeType(e) {
    settype(e)
  }
    // Sales Type Function
  return (
    <>
      <Wrapper pagename={pagename} >


        {type === 'product' ? 
        <AllItems type={type} changeType={changeType} /> :
        <AllServices type={type} changeType={changeType} />     
      }

      </Wrapper>
    </>
  );
}

export default Item;
