import React from 'react';
import AllPackages from '../User/Billing/Packages/AllPackages';


const OurPricing = () => {

    return (

        <>
            <div className="pricing" id='pricing'>
                <div className="container">
                    <div className=" text-center first pb-5">
                        <div className="pry pt-1 px-5 fit-content mx-auto mb-4"></div>
                        <h3 className="pry-text">
                            Pricing plan
                        </h3>
                    </div>

                    <AllPackages />
                
                    
                </div>
            </div>


        </>
    );
}

export default OurPricing;