import React, { useState } from "react";
import { Link } from "react-router-dom";

function Upgrade() {

  return (
    <>
      <div className="form mt-1">
        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" > UPGRADE </h6>
        </div>

        <div className="container">
          <div className="text-center mb-4">
            <Link to='/settings/packages'>
              <button className="btn btn-pry">choose a package</button>
            </Link>
          </div>
        
        </div>
      </div>

    </>
  );
}

export default Upgrade;
