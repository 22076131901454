import React from "react";
import { th } from "../../Global/addCommaToNumber";

function ReportData({ data, title }) {


    const obj = [
        { title: "Total Revenue", header: "red-bold-text red-light bi-bank", textColor: "red-light-text", noColor: "red-bold-text", no: ` ₦${ th(data?.totalRevenue || 0.00) }` },
        { title: "Total Quantity", header: "green-bold-text green-light bi-boxes", textColor: "green-light-text", noColor: "green-bold-text", no: ` ${ th(data?.totalQuantity || 0.00) }` },
        { title: "Total Cost Price", header: "yellow-bold-text yellow-light bi-cash", textColor: "yellow-light-text", noColor: "yellow-bold-text", no: ` ₦${ th(data?.totalCostPrice || 0.00) }` },
        { title: "Gross Profit", header: "pink-bold-text pink-light bi-cash-stack", textColor: "pink-light-text", noColor: "pink-bold-text", no: ` ₦${ th(data?.grossProfit || 0.00) }` },
        { title: "Total Expenses", header: "red-bold-text red-light bi-cash-coin", textColor: "red-light-text", noColor: "red-bold-text", no: ` ₦${ th(data?.totalExpenses || 0.00) }` },
        { title: "Net Profit", header: "green-bold-text green-light bi-wallet", textColor: "green-light-text", noColor: "green-bold-text", no: ` ₦${ th(data?.netProfit || 0.00) }` },
        { title: "Total Credit", header: "yellow-bold-text yellow-light bi-bank2", textColor: "yellow-light-text", noColor: "yellow-bold-text", no: ` ₦${ th(data?.totalDebtors || 0.00) }` },
        { title: "Total Debt", header: "pink-bold-text pink-light bi-bank2", textColor: "pink-light-text", noColor: "pink-bold-text", no: ` ₦${ th(data?.totalCreditors || 0.00) }` },
    ];

    return (
        <>
            <div className=" mt-4">
                <h5 className="pry-text text-center font-weight-light">{title} </h5>
                <div className="row justify-content-center mt-5">

                    {obj.map(({ title, header, textColor, noColor, no }, i) => (
                        <div className="col-md-4 col-lg-2 mb-5" key={i}>
                            <div className="br-sm white shadow-sm h-100">
                                <div className={`br-sm w-25 ml-3`} style={{ position: "relative", bottom: "0.8rem" }}>
                                    <i className={`bi h4 py-2 px-3 br-sm ${ header }`} />
                                </div>
                                <div className={` ${ textColor } my-3 container`}>
                                    <span className="font-weight-light small m-0 p-0">
                                        {title}
                                    </span>
                                    <h5 className={`py-1 ${ noColor } text-break pb-3`}>
                                        {no} </h5>
                                </div>
                            </div>
                        </div>
                    )
                    )}
                </div>
            </div>
        </>
    );
}

export default ReportData;
