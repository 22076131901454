import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { data } from './HowToUseData';

const HTUDetails = () => {
    let location = useLocation()

    let key = location.hash
    const [details, setdetails] = useState('');
    const [newDetails, setnewDetails] = useState('');

    useEffect(() => {
        setdetails(data.filter((e) => e.id.includes(key)))
    }, [location]);
    useEffect(() => {
        setnewDetails(...details)
    }, [details]);

    return (
        <>
            <section className='py-5'>

                <div className="py-3">
                    <h4 className="pry-text">
                        {newDetails?.name} Page
                    </h4>
                    <div className="underline"></div>
                </div>

                <div className="">
                    <ol>
                        {newDetails?.usage?.map(({ title, content, img }, i) => {
                            return (
                                <li key={i}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className='font-weight-bold'>
                                                {title}
                                            </p>
                                            <p className="">
                                                {content}
                                            </p>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img src={img} alt="inventory" width="50%" />
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            </section>
        </>
    );
}

export default HTUDetails;