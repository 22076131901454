import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../../AppLoader";
import BackButton from '../../BackButton';
import Wrapper from "../../../../Wrapper";
import { th } from '../../Global/addCommaToNumber';
import { Link } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import AddWaybill from "../../Waybill/AddWaybill";

const InvoiceData = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [load, setload] = useState(false);
    const [allinvdata, setallinvdata] = useState([]);
    const [invid] = useState(props.match.params.id);
    const [cdata, setcdata] = useState("");
    const [issending, setissending] = useState(false);
    const [bData] = useState(JSON.parse(localStorage.getItem("bData")));

    const [cname, setcname] = useState('');
    const [cphone, setcphone] = useState('');
    const [cmail, setcmail] = useState('');
    const [details, setdetails] = useState('');
    const [vatData, setvatData] = useState('');
    const [waybillData, setwaybillData] = useState('');

    //  Get All nvoice
    function getInvDetails(e) {
        setload(true);
        const data = new FormData();
        data.append("invid", invid);
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);

        axios.post(`${ endpoint }/getSalesData`, data,)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    getInvData();
                    setcdata(res.data);
                    setcname(res.data.customer_name);
                    setcmail(res.data.customer_mail);
                    setcphone(res.data.customer_phone);
                    setdetails(res.data.details);
                    setvatData(res.data.taxVatData);
                    setwaybillData(res.data.wayBill);

                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getInvDetails();
    }, []);

    function getInvData(e) {
        setload(true);
        const data = new FormData();
        data.append("invid", invid);
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);

        axios.post(`${ endpoint }/getSalesItem`, data,)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setallinvdata(res.data);
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    // useEffect(() => {

    // }, []);

    //  Update Business Details Function

    function customerUpdate(e) {
        e.preventDefault();

        e.preventDefault();
        setissending(true);
        const data = {
            cname: cname,
            cphone: cphone,
            cmail: cmail,
            details: details,
            usertoken: token,
            btoken: btoken,
            invid: invid,
            stoken: stoken,
            apptoken: apptoken,
        };
        axios.post(`${ endpoint }/updateCustomerSalesRecord`,
            data)
            .then((res) => {
                if (res.data.success === false) {
                    setissending(false);
                    toast.warn(res.data.message);
                } else {
                    setissending(false);
                    toast.success(res.data.message);
                    seteditme(true);
                }
            })
            .catch((error) => {
                setissending(false);
                toast.error("Network Error!");
            });
    }
    //  Update Business Details Function

    // Edit Function

    const [editme, seteditme] = useState(true);
    function onEdit() {
        seteditme(!editme);
        if (editme === true) {
            toast.success('You can now edit...');
        }
    }
    // Edit Function


    // Check Access
    // Check Access
    const checkfeature = (feature) => {
        const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature);

        if (data >= 0) {
            return true;
        } else {
            return false;
        }
    };
    // Check Access
    // Check Access

    const pagename = "sales";

    const [showDes, setshowDes] = useState(false);
    const [showQr, setshowQr] = useState(false);

    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    function workModal() {
        handleShowremove();
    }

    return (
        <>
            <Wrapper pagename={pagename}>

                {/* Title */}
                <div className="dash-title mb-4">
                    <div className="">
                        <div className="row align-items-center border-bottom">
                            <div className="col pry-bold-text d-flex align-items-center">
                                <BackButton />
                                <h5 className='mt-1'> SALES INVOICE </h5>
                            </div>
                            {!waybillData &&
                                <div className="mr-3 mb-2">
                                    <button
                                        type="submit"
                                        className="btn btn-pry m-0"
                                        onClick={(e) => workModal(e)}
                                    >
                                        Add Waybill
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Title */}

                {/* Customer Details */}
                {checkfeature('sales_edit') &&
                    <>
                        <div className="text-right">
                            <button className="btn shadow-none"
                                onClick={onEdit}
                            >
                                <h6 className=" border-bottom border-primary pry-text">
                                    <i className="bi bi-pen-fill h6 m-0"
                                    /> EDIT
                                </h6>
                            </button>
                        </div>
                    </>
                }
                <div className="row justify-content-center">
                    <div className="col-md-4 ">
                        <label className="m-0"><small>  Invoice Number:</small> </label>
                        <input
                            className=" input-style input-sm"
                            value={invid}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-6">
                        <label className="m-0"><small>  Date:</small> </label>
                        <input
                            className=" input-style input-sm"
                            value={cdata.sales_date}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-6">
                        <label className="m-0"><small>  Customer Phone:</small> </label>
                        <input
                            type="number"
                            className=" input-style input-sm"
                            value={cphone || 'Nil'}
                            disabled={editme ? true : false}
                            onChange={(e) => setcphone(e.target.value)}

                        />
                    </div>
                    <div className="col-md-6 ">
                        <label className="m-0"><small>  Customer Name:</small> </label>
                        <input
                            type="text"
                            className=" input-style input-sm"
                            value={cname || 'Nil'}
                            disabled={editme ? true : false}
                            onChange={(e) => setcname(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 ">
                        <label className="m-0"><small>  Customer Email:</small> </label>
                        <input
                            type="email"
                            className=" input-style input-sm"
                            value={cmail || 'Nil'}
                            disabled={editme ? true : false}
                            onChange={(e) => setcmail(e.target.value)}
                        />
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="d-flex justify-content-between">

                            <div className="">
                                <input type="checkbox" id="des"
                                    onClick={(e) => setshowDes(!showDes)}
                                    checked={showDes}
                                />
                                <label className="ml-2 m-0 small" for="des"> Description </label>
                            </div>
                            <div className="">
                                <input type="checkbox" id="qr"
                                    onClick={(e) => setshowQr(!showQr)}
                                    checked={showQr}
                                />
                                <label className="ml-2 m-0 small" for="qr"> Qr Code </label>
                            </div>
                        </div>

                        {showDes &&
                            <>
                                <textarea
                                    type="text"
                                    className="input-style textarea-style"
                                    name="details"
                                    rows="3"
                                    onChange={(e) => setdetails(e.target.value)}
                                    required
                                    placeholder="Description (optional) "
                                    value={details}
                                    disabled={editme ? true : false}

                                ></textarea>
                            </>
                        }
                    </div>

                    {!editme &&
                        <div className="col-md-12  mx-auto text-center">
                            <div className="mb-4">
                                {issending ? (
                                    <>
                                        <button
                                            className="btn btn-pry m-0 w-lg-75 w-md-100"
                                            disabled
                                        >
                                            Loading...
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            type="submit"
                                            className="btn btn-pry m-0 w-lg-75 w-md-100"
                                            onClick={(e) => customerUpdate(e)}
                                        >

                                            submit
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    {showQr &&
                        <>
                            <div className="mb-5">
                                <QRCodeSVG value={`https://reniaccounts.com/invoice/${ invid }`}
                                    bgColor="#f1f6fd"
                                    fgColor="#023676"
                                    level="H"
                                />
                            </div>
                        </>
                    }
                </div>
                {/* Customer Details */}



                {/* Item Details */}

                <MDBTable small responsive bordered>
                    <MDBTableHead color="pry" textWhite>
                        <tr>
                            <th className="text-center">No</th>
                            <th>Description</th>
                            <th className="text-center">Qty</th>
                            <th className="text-right pr-2">Price</th>
                            <th className="text-right pr-2">Amount</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody color="white" >
                        {allinvdata.map((item, i) => (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="pt-3-half">
                                    {item.item_name}
                                </td>
                                <td className="text-center">
                                    {item.qty}
                                </td>
                                <td className="text-right pr-2">
                                    ₦{item.price_th}
                                </td>
                                <td className="text-right pr-2">
                                    ₦{item.amount_th}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="text-center">=</td>
                            <td className="pt-3-half">
                                VAT ({bData.vat_rate || 0.00}%)
                            </td>
                            <td className="text-center">

                            </td>
                            <td className="text-right pr-2">

                            </td>
                            <td className="text-right pr-2">
                                ₦{vatData?.value || 0.00}
                            </td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>

                {/* Item Details */}


                <h6 className="text-right mt-3 font-weight-light pry-bold-text border-bottom pb-2 "> TOTAL :
                    <span className="ml-5 mr-2 font-weight-bold">
                        ₦{vatData ?
                            vatData?.vat_type === "inclusive" ?
                                cdata.total_amount_th :
                                th(Number(cdata.total_amount) + Number(vatData?.value))
                            : cdata.total_amount_th
                        }
                    </span>
                </h6>

                <h6 className="alert alert-primary fit-content">
                    Attendant Name: {cdata.agentName || 'Nil'}
                </h6>

                <div className="text-center mt-4 ">
                    <button
                        className="btn btn-pry m-0 w-lg-75 w-md-100"
                        onClick={() => window.frames['frame1'].print()}
                    >
                        <i className="bi bi-receipt" />  Print Receipt
                    </button>

                    <Link to={`/invoice/${ invid }`} target="_blank">
                        <button className="btn btn-pry p-0 py-1 px-2">
                            <i className="bi bi-link-45deg h4 p-0 m-0" />
                        </button>
                    </Link>
                </div>

                {/* Invoice Reciept */}
                <div className="mt-5">
                    <iframe src={`/invoice/${ invid }`} name="frame1" className="d-none w-100"></iframe>
                </div>
                {/* Invoice Reciept */}



                {load ? <AppLoader /> : <></>}


                {/* Add Waybill Modal */}
                <AddWaybill
                    show={showremove}
                    onHide={handleCloseremove}
                    animation={false}
                    invid={invid}
                    getwaybill={e => props.getwaybill()}
                    getInvDetails={getInvDetails}
                />
                {/* Add Waybill Modal */}
            </Wrapper>



        </>
    );
};

export default InvoiceData;
