import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./HomeNav.css";
import logo from '../img/renifinance.png';

const Navbar = () => {
  let navLink = [
    { title: "About Us", to: "about" },
    { title: "Product", to: "/#products" },
    { title: "FAQs", to: "#" },
  ];

  // Logout Function
  let history = useHistory();
  const HandleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("btoken");
    localStorage.removeItem("stoken");
    localStorage.removeItem("uname");
    localStorage.removeItem("umail");
    localStorage.removeItem("ppic");
    localStorage.removeItem("bData");
    localStorage.removeItem("sname");
    localStorage.removeItem("stores");
    localStorage.removeItem("features");
    localStorage.removeItem("user");
    localStorage.removeItem("bname");
    localStorage.removeItem("active");
    setTimeout(() => {
      toast.success('Logout successful! Redirecting...');
    }, 10);
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };
  // Logout Function


  return (
    <>
      <div className="homenav">
        <nav className="navbar shadow-none navbar-expand-lg fixed-to light-bg " id="navbar">
          <div className="container">
            <Link to="/" className="navbar-brand mt-2 mb-3 pry-text">
              <img src={logo} width="40px" alt="logo" loading="lazy" />

            </Link>

            <a
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#basicExampleNav"
              aria-controls="basicExampleNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon toggler">
                <i className="bi bi-list-nested h3 pry-text " />
              </span>
            </a>

            <div className="collapse navbar-collapse" id="basicExampleNav">
              <ul className="navbar-nav ml-auto text-center animated fadeIn align-items-center">
                {navLink.map(({ title, to }, i) => {
                  return (
                    <li className="nav-item" key={i}>
                      <Link ClassName="active" to={`${ to }`}>
                        {title}
                      </Link>
                    </li>
                  );
                })}

                {localStorage.getItem('token') === null ? (
                  <>
                    <li className=" btn-contact">
                      <Link to="/login" ClassName=" pry-text">
                        <button className="btn ">
                          Login
                        </button>
                      </Link>
                    </li>
                    <li className=" btn-contact">
                      <Link to="/register">
                        <button className="btn pry light-text">
                          Get Started
                        </button>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    {localStorage.getItem('btoken') && localStorage.getItem('stoken') ?
                      <li className=" btn-contact">
                        <Link to="/dashboard" ClassName=" pry-text">
                          <button className="btn pry white-text">
                            Dashboard
                          </button>
                        </Link>
                      </li> : <></>
                    }
                    <li className=" btn-contact">
                      <button onClick={HandleLogout} className="btn">
                        <i className="bx bx-log-out-circle mr-2" />
                        Logout
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
