import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { formatDate } from "../Global/GetDate";
import Select from "react-select";
import { useFetch } from "../Global/useFetch";
import { useFetch1 } from "../Global/useFetch1";
import { ClipLoader } from "react-spinners";
import AddCategory from "./AddCategory";

const AddExp = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [key, setkey] = useState("");
  const [eid, seteid] = useState("");

  // Form Fields
  const [formField, setformField] = useState({ details: "", amount: "", datetime: `${formatDate(new Date())}` });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //  Add Expenses Function
  //  Add Expenses Function
  function expAdd(e) {
    e.preventDefault()
    if (formField.amount && formField.details) {
      setissending(true);
      const data = new FormData();
      data.append("amount", formField.amount);
      data.append("details", formField.details);
      data.append("sid", key);
      data.append("eid", eid);
      data.append("datetime", formField.datetime);
      data.append("usertoken", token);
      data.append("stoken", stoken);
      data.append("btoken", btoken);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/recordExpenses`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.error(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.getexpenses()
          }
        })
        .catch((error) => {
          toast.error("Network Error!");
          setissending(false);
        });
    } else {
      toast.error('Empty Fields!')
    }
  }
  //  Add Expenses Function
  //  Add Expenses Function

  //  Get All Supplier

  const datas = { btoken, stoken }

  const { loading, data } = useFetch('getSuppliers', datas)

  const suppliers = data?.suppliers?.map(({ name, id }, i) => {
    return (
      { label: name, value: id }
    )
  });
  //  Get All Supplier

  //  Get All Exp Categories

  const { loading1, data1 } = useFetch1('getExpenseCategories', datas)

  const categories = data1?.categories?.map(({ name, id }, i) => {
    return (
      { label: name, value: id }
    )
  });
  //  Get All Exp Categories


  function clear() {
    setformField({ details: "", amount: "", datetime: `${formatDate(new Date())}` })
  }

  // Open Add Item Modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);

  // Close Add Inventory Modal
  const [removeaddinvent, setremoveaddinvent] = useState("");
  const handleremoveaddinvent = () => setremoveaddinvent(props.onHide);

  function workModal() {
    handleShowremove();
    handleremoveaddinvent();
  }
  // Open Add Item Modal

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Create a new Expenses
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data" id="itemForm">
                    <div className="row justify-content-center text-left">

                      <div className="col-md-12 ">
                        <label className="mb-0"> Category:</label>
                        <ClipLoader color={"#023676"} loading={loading} speedMultiplier="1.2" size="15" />

                        <Select
                          options={categories}
                          isSearchable={true}
                          onChange={e => seteid(e.value)}
                          placeholder='Select category'

                        />
                        <p
                          className="red-text text-right pointer small"
                          onClick={(e) => workModal()}
                        >
                          Category not found? Click here to add new
                        </p>
                      </div>
                      <div className="col-md-12">
                        <label className="mb-0"> Expenses Details:</label>
                        <textarea
                          type="text"
                          className="input-style textarea-style"
                          name="details"
                          placeholder="e.g. Bought 5 litres of Fuel"
                          rows="3"
                          onChange={handleChange}
                          required
                          value={formField.details}
                        ></textarea>
                      </div>

                      <div className="col-md-12 ">
                        <label className="mb-0"> Supplier <span className="small"> <i> (optional)</i></span>:</label>
                        <ClipLoader color={"#023676"} loading={loading} speedMultiplier="1.2" size="15" />

                        <Select
                          options={suppliers}
                          isSearchable={true}
                          onChange={e => setkey(e.value)}
                          placeholder='Select supplier'

                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Price:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="amount"
                          placeholder="e.g. 10000"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.amount}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="m-0">  Date:</label>
                        <input
                          type="date"
                          name="datetime"
                          className=" input-style"
                          onChange={handleChange}
                          value={formField.datetime}
                          required
                        />
                      </div>
                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button
                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"
                                onClick={(e) => expAdd(e)}
                              >
                                submit
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* Add Item Modal */}
      <AddCategory
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        getExpCat={e => props.getExpCat()}

      />
      {/* Add Item Modal */}
    </>
  );
};

export default AddExp;
