import React from 'react';
import { useState } from 'react';
import AllCreditor from '../../Components/User/Creditor/AllCreditor';
import AllDebtor from '../../Components/User/Debtor/AllDebtor';
import Wrapper from '../../Wrapper';


const Debtor = () => {

    const pagename = "debt"

    const [type, settype] = useState('debtor');

    function changeType(e) {
        settype(e)
    }

    return (
        <>
            <Wrapper pagename={pagename}>
                {/* Select buttons for Services or Products */}

                <div
                    className="d-flex sec-bold py-2 px-2 br-sm fit-content mx-auto mt-4 justify-content-between">
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center 
                    ${type === 'debtor' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('debtor')}
                    >
                        DEBTORS
                    </div>
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center ${type === 'creditor' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('creditor')}
                    >
                        CREDITORS
                    </div>
                </div>

                {/* Select buttons for Services or Products */}
                {type === 'debtor' ?
                    <AllDebtor /> :
                    <AllCreditor />
                }

            </Wrapper>

        </>
    );
}

export default Debtor;