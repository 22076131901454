import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';


const StoreItemHistory = () => {

    const id = useParams()

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [load, setload] = useState(false);
    const [itemHistory, setitemHistory] = useState([]);
    const [key, setkey] = useState('');

    function getChartData(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
            stoken: id.id,
            month: e,
        }
        axios.post(`${endpoint}/storeItemHistory`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                  
                        setload(false);
                        setitemHistory(res.data.items)                   
                }
            })
            .catch((error) => {
                setload(false);
            });

    }
    useEffect(() => {
        getChartData()
    }, []);




    return (
        <>
            <h5 className="pry-text ">
                Item History
            </h5>

            <div className="light-bg br-md">

                <div className="col-md-8 py-2">
                    <input type="search" className='input-style h-25 m-0 sec' placeholder='search by name' onChange={(e)=>setkey(e.target.value)} />
                </div>

                <table className='table text-center br-md border-0 p-2 table-responsive'>
                    <thead>
                        <tr className=''>
                            <th className='border-0 grey-text text-left'>
                                Product Name
                            </th>                        
                            <th className='border-0 grey-text text-left'>
                               Cost Price
                            </th>
                            <th className='border-0 grey-text text-left'>
                               Selling Price
                            </th>
                            <th className='border-0 grey-text'>
                                Quantity Available
                            </th>
                            <th className='border-0 grey-text'>
                                Qty Sold
                            </th>
                            <th className='border-0 grey-text'>
                                Amount Sold
                            </th>
                         
                        </tr>
                        {/* <hr className='m-0 p-0' /> */}
                    </thead>


                    {itemHistory?.filter((x) => x.name.toLowerCase().includes(key.toLowerCase())).map(({ name, cost_price, selling_price, qty, total_sales_th, total_sold_th }, i) => {
                            return (
                                <tr className='' key={i}>
                                    <td className="border-0 font-weight-bold text-left">{name}</td>
                                    <td className="border-0 font-weight-bold text-left">{cost_price}</td>
                                    <td className="border-0 font-weight-bold text-left">{selling_price}</td>
                                    <td className="border-0 ">{qty}</td>
                                    <td className="border-0 ">{total_sold_th}</td>
                                    <td className="border-0 ">{total_sales_th}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </>
    );
}

export default StoreItemHistory;