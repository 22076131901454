import React from 'react';
import { ToastContainer } from 'react-toastify';
import ContactSupport from './Components/User/Global/ContactSupport';
import AdminNavbar from './Components/User/Navbar/AdminNavbar';
import UserFooter from './Components/User/UserFooter';

const Wrapper = ({ children, pagename, route }) => {

    return (
        <>
            <AdminNavbar
                pagename={pagename}
                route={route}
             
            />
            <div className='main-dash'>
                <div className='container-fluid'>
                    {children}
                </div>
            </div>
            <UserFooter />
            <ContactSupport/>
            <ToastContainer
                hideProgressBar={true}
                autoClose={1000}
            />
        </>
    );
}

export default Wrapper;