import React, { useState } from "react";

function PersonalProfile() {
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [name] = useState(user?.fullname);
  const [email] = useState(user?.email);
  const [phone] = useState(user?.phone);
  const [acct_no] = useState(user?.accno);
  const [pic] = useState(user?.photo);



  // Route to Reni Trust

  function editConfirm(e) {
    e.preventDefault();
    if (window.confirm('You will redirected to your Renitrust Account, Confirm?')) {
      window.open('https://renitrust.com/user/profile', '_blank');
    }
  }

  // Route to Reni Trust




  return (
    <>

      <div className="form mt-1">
        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" > PERSONAL PROFILE </h6>
        </div>

        <div className="container">
          <center>
            <div className=" py-4 ppic mb-4" >
              <img src={pic} alt={name} width="100%" />
            </div>
          </center>
          <form>
            <div className="row justify-content-center">
              <div className="col-md-6 ">
                <label> Name: </label>
                <input
                  type="text"
                  className="input-style"
                  placeholder="Enter Name"
                  disabled
                  value={name}
                />
              </div>

              <div className="col-md-6 ">
                <label> Email: </label>
                <input
                  type="text"
                  className=" input-style"
                  placeholder="Enter Email"
                  disabled
                  value={email}
                />
              </div>
              <div className="col-md-6 ">
                <label> Phone Number: </label>
                <input
                  type="number"
                  className=" input-style"
                  placeholder="e.g. 08012345678"
                  disabled
                  value={phone}
                />
              </div>

              <div className="col-md-6 ">
                <label> Account Number: </label>
                <input
                  type="number"
                  className=" input-style"
                  placeholder="Account Number"
                  disabled
                  value={acct_no}
                />
              </div>


              <div className="col-md-12  mx-auto text-center">
                <div className="mb-4">

                  <button
                    type="button"
                    className="btn btn-pry m-0 w-lg-75 w-md-100"

                    onClick={(e) => editConfirm(e)}
                  >
                    Manage your Reni Account
                  </button>
                </div></div>
            </div>
          </form>
        </div>
      </div>

    </>
  );
}

export default PersonalProfile;
