import React, { useState } from 'react';
import Wrapper from '../../../../Wrapper';
import AppLoader from '../../../AppLoader';
import TitlenBack from '../../Global/TitlenBack';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import StoreItemHistory from './StoreItemHistory';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ReportChart from '../BizReport/ReportChart';
import ReportCard from '../BizReport/ReportCard';
import ReportData from '../BizReport/ReportData';


const EachStoreReport = () => {

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [revenue, setrevenue] = useState([]);
    const id = useParams()


    // Page Feature
    const pagename = "reports"
    // Page Feature

    const [load, setload] = useState(false);
    let history = useHistory()
    const sr = useLocation()

    // Check for Empty Fields 
    useEffect(() => {
        if (sr.state) {
        } else {
            toast.warning('Choose Store!')
            history.push('/reports/stores')
        }
    }, []);
    // Check for Empty Field

    //  Get All Chart Data
    function getChartData(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
            stoken: id.id,
            month: e,
        }
        axios.post(`${endpoint}/storeChart-byMonth.php`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    if (res.data.data.chart === false) {
                        setload(false);
                        setrevenue([])
                    } else {
                        setload(false);
                        setrevenue(res.data.data.chart)
                    }
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getChartData()
    }, []);

    //  Get All Chart Data
    

    return (
        <>
            <Wrapper pagename={pagename}>
                <TitlenBack title={"REPORT"} title1={'STORE'} title2={` / ${sr.state?.storename}`} none={"d-none"} />

                <ReportData data={sr.state} title={'Store Report'}    />
                <div className="text-right">
                    <Link to={`/reports/store/filter/${id.id}`}>
                        <button className="btn btn-pry">
                            Filter Report
                        </button>
                    </Link>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-7 biz-report mb-5">
                        <ReportChart
                            revenue={revenue}
                            getChartData={getChartData}
                        />
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <StoreItemHistory />
                    </div>
                    <div className="col-md-12">
                        <ReportCard data={revenue} />
                    </div>



                </div>
            </Wrapper>
            {load && (<AppLoader />)}
        </>
    );
}

export default EachStoreReport;