import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { formatDate } from "../Global/GetDate";

const EditDebtor = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [issending, setissending] = useState(false);

    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));

    const { data } = props

    const { id: did, name, mail, phone, due_date_2: duedate, amount, paid_status } = data

    // Form Fields
    const [formField, setformField] = useState({ name: "", mail: "", phone: "", amount: "", duedate: "" });

    useEffect(() => {
        if (data) {
            setformField({ name, mail, phone, amount, duedate: formatDate(new Date(duedate)) })
        }
    }, [data]);


    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields

    //  Edit Debt Function
    //  Edit Debt Function
    function debtorEdit(e) {
        e.preventDefault();
        if (formField.name && formField.mail && formField.phone && formField.amount && formField.duedate) {
            setissending(true);
            const data = {
                did,
                name: formField.name,
                mail: formField.mail,
                phone: formField.phone,
                amount: formField.amount,
                duedate: formField.duedate,
                stoken,
                btoken,
                apptoken,
            }
            axios
                .post(`${endpoint}/editDebt`, data)
                .then((res) => {
                    if (res.data.success === false) {
                        toast.warn(res.data.message);
                        setissending(false);
                    } else {
                        setissending(false);
                        toast.success(res.data.message);
                        props.getDebtor();
                        props.onHide()
                    }
                })
                .catch((error) => {
                    setissending(false);
                    toast.error("Network Error!");
                });
        } else {
            setissending(false);
            toast.error("Empty Fields!");
        }
    }

    //  Edit Debt Function
    //  Edit Debt Function

    //  Mark Debt Paid Function
    //  Mark Debt Paid Function
    function markPaid(e) {
        e.preventDefault();
        if (window.confirm('Confirm, you will mark this debt as paid?')) {
            if (did) {
                setissending(true);
                const data = {
                    did,
                    stoken,
                    btoken,
                    apptoken,
                }
                axios
                    .post(`${endpoint}/markDebtAsPaid`, data)
                    .then((res) => {
                        if (res.data.success === false) {
                            toast.warn(res.data.message);
                            setissending(false);
                        } else {
                            setissending(false);
                            toast.success(res.data.message);
                            props.getDebtor();
                            props.onHide()
                        }
                    })
                    .catch((error) => {
                        setissending(false);
                        toast.error("Network Error!");
                    });
            } else {
                setissending(false);
                toast.error("Select a Debtor!");
            }
        }
    }

    //  Mark Debt Paid Function
    //  Mark Debt Paid Function



    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={true}
                centered
                size="lg"
            >
                <Modal.Body style={{ backgroundColor: "transparent!important" }}>
                    <Modal.Header closeButton >
                        <h6 className="font-weight-bold pry-text ml-auto">
                            Create a new Debtor
                        </h6>
                    </Modal.Header>

                    <section className="addinvent ">
                        <div className="container">
                            <div className="text-center">
                                <div className="form">
                                    {paid_status === "0" ?
                                    <form className="text-left row justify-content-between">
                                        <span className="h5">
                                            Status:
                                            <h6 className="ml-2 badge badge-warning br-sm p-1"
                                            > UNPAID</h6>
                                        </span>
                                        <span className="h5">
                                            <label htmlFor="status" className="mb-0">
                                                Mark Paid:
                                            </label>

                                            <input type="checkbox" name="status" id="status"
                                                className="ml-2"
                                                onChange={(e) => markPaid(e)}
                                            />
                                        </span>
                                    </form>
                                    :
                                        <span className="h5">
                                            Status:
                                            <h6 className="ml-2 badge badge-success br-sm p-1"
                                            > PAID</h6>
                                        </span>

                                    }
                                    <hr />
                                    <form onSubmit={(e) => debtorEdit(e)}>
                                        <div className="row justify-content-center text-left">
                                            <div className="col-md-12 ">
                                                <label className="mb-0"> Debtor name:</label>
                                                <input
                                                    type="text"
                                                    className=" input-style"
                                                    name="name"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    value={formField.name}
                                                    required
                                                />

                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mb-0"> Email:</label>
                                                <input
                                                    type="email"
                                                    className=" input-style"
                                                    name="mail"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    value={formField.mail}
                                                    required
                                                />

                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mb-0"> Phone:</label>
                                                <input
                                                    type="number"
                                                    className=" input-style"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    value={formField.phone}
                                                    required
                                                />

                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mb-0"> Amount:</label>
                                                <input
                                                    type="number"
                                                    className=" input-style"
                                                    name="amount"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    value={formField.amount}
                                                    required
                                                    placeholder="10000"
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mb-0"> Due Date:</label>
                                                <input
                                                    type="date"
                                                    className=" input-style"
                                                    name="duedate"
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    value={formField.duedate}
                                                    required
                                                />

                                            </div>

                                            <div className="col-md-10 mx-auto text-center">
                                                <div className="user-btn mb-4 mr-auto text-center">
                                                    {issending ? (
                                                        <>
                                                            <button
                                                                className="btn btn-pry m-0 w-100"
                                                                disabled
                                                            >
                                                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-pry m-0 w-100"
                                                            >
                                                                submit
                                                            </button>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditDebtor;
