import React, { useState, useEffect } from "react";
import Wrapper from '../../../Wrapper';
import AppLoader from "../../AppLoader";
import axios from "axios";
import { toast } from "react-toastify";
import ReportData from './BizReport/ReportData';
import ReportChart from '../Report/BizReport/ReportChart'
import TitlenBack from "../Global/TitlenBack";
import ReportCard from "./BizReport/ReportCard";
import { Link } from "react-router-dom";

function BizReport(props) {

    // Page Feature
    const pagename = "reports"
    // Page Feature

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [load, setload] = useState(false);
    const [bizreports, setbizreports] = useState('');
    const [revenue, setrevenue] = useState([]);
    const [currentMonthReport, setcurrentMonthReport] = useState({});


    //  Get All report
    function getReports(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
        }
        axios.post(`${endpoint}/reports`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setbizreports(res.data.Overview)
                }
            })
            .catch((error) => {
                setload(false);
            });

    }
    useEffect(() => {
        getReports()
    }, []);
    //  Get All report



    //  Get All Chart Data
    function getChartData(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
            month: e,
        }
        axios.post(`${endpoint}/businessChart-byMonth.php`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    if (res.data.data.chart === false) {
                        setload(false);
                        setrevenue([])
                    } else {
                        setload(false);
                        setrevenue(res.data.data.chart)
                    }
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getChartData()
    }, []);
    useEffect(() => {
        if (revenue) {
            setcurrentMonthReport(
                {
                    netProfit: revenue[revenue.length - 1]?.netProfit,
                    totalExpenses: revenue[revenue.length - 1]?.expense,
                    grossProfit: revenue[revenue.length - 1]?.grossProfit,
                    totalCostPrice: revenue[revenue.length - 1]?.costPrice,
                    totalQuantity: 0,
                    totalRevenue: revenue[revenue.length - 1]?.revenue,
                }
            )
        }
    }, [revenue]);
    //  Get All Chart Data

    let month = revenue[revenue.length - 1]?.month_year || ''
    return (
        <>
            <Wrapper pagename={pagename}>

                <TitlenBack title={"REPORT"} title1={'BUSINESS'} none={"d-none"} />


                <ReportData data={currentMonthReport} title={`${month} Business Report`} />
                <ReportData data={bizreports} title={'All Time Business Report'} />
                <div className="text-right">
                    <Link to="/reports/business/filter">
                        <button className="btn btn-pry">
                            Filter Report
                        </button>
                    </Link>
                </div>

                <div className="row justify-content-center biz-report">

                    <div className="col-md-12 py-5 ">
                        <ReportChart
                            revenue={revenue}
                            getChartData={getChartData}
                        />
                    </div>

                </div>

                <ReportCard data={revenue} />

            </Wrapper>
            {load ? (<AppLoader />) : (
                <> </>)}
        </>
    );
}

export default BizReport;
