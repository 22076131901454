import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';


export const useFetch = (url, datas) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));

    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);

    function fetchData(e) {
        setloading(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            ...datas
        };
        axios.post(`${ endpoint }/${ url }`, data
        )
            .then((res) => {
                if (res.data.success === false) {
                    setloading(false);
                    // toast.warn(res.data.message);
                } else {
                    setloading(false);
                    setdata(res.data);
                }
            })
            .catch((error) => {
                setloading(false);
            });

    }

    useEffect(() => {
        fetchData();
    }, []);

    return { loading, data, fetchData };
};
