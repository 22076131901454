import React from 'react';
import { Link } from 'react-router-dom';


const UpgradeBtn = () => {
    return (
        <>
            {localStorage.getItem('active') ?
                (
                    <>
                        {localStorage.getItem('active') === '1' ?
                            <></> :
                            <Link to="/settings/packages">
                                <button className="btn btn-yellow btn-sm br-md pry-bold-text "><i className="bi bi-gem " /> Upgrade </button>
                            </Link>
                        }
                    </>
                ) : <></>

            }

        </>
    );
}

export default UpgradeBtn;