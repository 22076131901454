import React from 'react';
import ProductItemDetails from "./ProductItemDetails";
import ServicesItemDetails from "./ServicesItemDetails";



const ItemDetails = (props) => {

  return (
    <>

    {props.type === 'product' ?
       <ProductItemDetails 
        props={props}
      /> : 
      <ServicesItemDetails 
        props={props}
      />
    }

    </>
  );
};

export default ItemDetails;
