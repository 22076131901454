import React, { useState } from 'react';

const ContactSupport = () => {

    const [links, setlinks] = useState(false);
    const [showIcon, setshowIcon] = useState(true);

    function showLinks() {
        setlinks(true)
        setshowIcon(false)
    }
    function hideLinks() {
        setlinks(false)
        setshowIcon(true)
    }

    const support = [
        {
            url: "http://t.me/TheFireSwitchTech",
            icon: "bi bi-telegram",
            color: "",
            bg_color: "",
            name: "Telegram"
        },
        {
            url: "http://wa.me/2348037837313",
            icon: "bi bi-whatsapp",
            color: "light-text",
            bg_color: "green-bold",
            name: "Whatsapp"
        },
        {
            url: "mailto:hello@reni.tech",
            icon: "bxl-gmail",
            color: "light-text",
            bg_color: "red-bold",
            name: "email"
        },
    ]

    return (
        <div className='contact-support '>
            {showIcon &&
                <div className="" title='Support'>
                    <i
                        className="bi bi-chat-right-dots bx-tada-hover pry light-text bx m-0 p-2 bx-sm mr-3 br-xlg"
                        onClick={(e) => showLinks()}
                    ></i>
                </div>
            }


            {links &&
                <>
                    <div className="support-link"
                        onClick={() => hideLinks()}
                    >
                        <div className="">
                            <div className=" py-3 px-2 mr-3 mb-2 first"
                                // onClick={() => showLinks()}
                            >
                                {support.map(({ url, color, icon, name, bg_color }, i) =>
                                    <>
                                        <div className="cs-icon"
                                            key={i}
                                            title={name}
                                        >
                                            <a href={url} target="_blank" >
                                                <i
                                                    className={`bx ${icon} bx-tada-hover shadow-none m-0 bx-md mb-3 ${color} p-1 br-sm ${bg_color}`}
                                                ></i>
                                            </a>
                                        </div>
                                    </>
                                )

                                }
                            </div>
                            <div className=""
                                title='Close'
                            >
                                <i
                                    className="bi bi-x pry bx-tada-hover light-text bx m-0 p-1 bx-md ml-1 mb-5 br-xlg"
                                    onClick={(e) => hideLinks()}
                                ></i>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ContactSupport