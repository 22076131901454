import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";


const AddCategory = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  // Form Fields
  const [formField, setformField] = useState({ des: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //  Add Item Function
  //  Add Item Function
  function CategoryAdd(e) {
    e.preventDefault();
    if (formField.des) {
      setissending(true);
      const data = {
        name: formField.des,
        usertoken: token,
        stoken,
        btoken,
        apptoken,
      };
      axios
        .post(`${ endpoint }/addExpenseCategory`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.getExpCat();
            props.onHide();
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }

  //  Add Item Function
  //  Add Item Function
  function clear() {
    setformField({ des: "" });
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Create a new Category
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form onSubmit={(e) => CategoryAdd(e)}>
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="des"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.des}
                          required
                        />
                      </div>

                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button
                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"
                              >
                                submit
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default AddCategory;
