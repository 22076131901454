import React from 'react';
import AllCustomers from '../../Components/User/Customers/AllCustomers'
import Title from '../../Components/User/Global/Title';
import Wrapper from '../../Wrapper';

function Customer() {
    const pagename = "customers"

    return (
        <>
            <Wrapper pagename={pagename}>
                <Title title={"CUSTOMERS"} none={"d-none"} />
                <AllCustomers />
            </Wrapper>
        </>
    );
}

export default Customer;