import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, PopoverBody, UncontrolledPopover, PopoverHeader } from "reactstrap";


function AddStaff(props) {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));

  const [sname, setsname] = useState("");
  const [mail, setmail] = useState("");
  const [phone, setphone] = useState("");
  const [store, setstore] = useState("");
  const [allstore, setallstore] = useState([]);

  const [issending, setissending] = useState(false);

  //  Update Business Details Function
  function staffAdd(e) {
    e.preventDefault();
    if (sname && mail && phone && store) {
      setissending(true)
      const data = {
        name: sname,
        mail: mail,
        stoken: store,
        phone: phone,
        apptoken: apptoken,
        btoken: btoken
      }
      axios.post(`${endpoint}/addStaff`,
        data)
        .then((res) => {
          if (res.data.success === false) {
            setissending(false)
            toast.warn(res.data.message);
          } else {
            setissending(false)
            toast.success(res.data.message);
            resetForm();
            props.getStaff()
            props.staffPage()
          }
        })
        .catch((error) => {
          setissending(false)
          toast.error("Network Error!");
        });
    } else {
      toast.error("Empty Fields!");
      setissending(false)
    }
  }
  //  Update Business Details Function

  function resetForm() {
    setsname("")
    setmail("")
    setphone("")
    setstore("0")
  }

  //  Get All Store
  function getStore(e) {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getStores`, data)
      .then((res) => {
        if (res.data.success === false) { } else {
          setallstore(res.data);
        }
      })
      .catch((error) => { });
  }
  useEffect(() => {
    getStore()
  }, []);

  const stores = allstore.map((({ storename, stoken }, i) => {
    return <option key={i} value={stoken}> {storename} </option>;
  }));
  //  Get All Store


  return (
    <>


      <div className="" id="addstaff">
        <div className="container">

          <form autoComplete="off" id="staffForm" className="staffform" onSubmit={(e) => staffAdd(e)}>
            <div className="row justify-content-center">
              <div className="col-md-6 ">
                <label> Staff Name: </label>
                <input
                  type="text"
                  className="input-style"
                  placeholder="e.g. Jane Doe"
                  onChange={(e) => setsname(e.target.value)}
                  value={sname}
                  required
                />
              </div>

              <div className="col-md-6 ">

                <label> Staff email: </label>
                <Button
                  id="PopoverFocus"
                  type="button"
                  className="shadow-none"
                  color="none p-0"

                >
                  <i className="bi bi-info-circle-fill pry-text h6" />
                </Button>
                <UncontrolledPopover
                  placement="top"
                  target="PopoverFocus"
                  trigger="focus"
                >
                  <PopoverHeader className="sec-bold pry-bold-text">
                    NOTE
                  </PopoverHeader>
                  <PopoverBody
                    className="">
                    If the new staff has a RENI account, input the RENI account email, else put the staff email and a new RENI account will be created by default after adding the staff.
                  </PopoverBody>
                </UncontrolledPopover>
                <input
                  type="email"
                  className=" input-style"
                  placeholder="e.g. example@email.com"
                  onChange={(e) => setmail(e.target.value)}
                  value={mail}
                  required
                />
              </div>
              <div className="col-md-6 ">
                <label> Phone Number: </label>
                <input
                  type="number"
                  className=" input-style"
                  placeholder="e.g. 08012345678"
                  onChange={(e) => setphone(e.target.value)}
                  value={phone}
                  required
                />
              </div>

              <div className="col-md-6 ">
                <label> Choose Store: </label>
                <select
                  name="item_id"
                  className="input-style"
                  onChange={e => setstore(e.target.value)}
                >
                  <option value="">
                    Select Store
                  </option>
                  {stores}
                </select>
              </div>

              <div className="col-md-12  mx-auto text-center">
                <div className="mb-4">
                  {issending ? (
                    <>
                      <button
                        className="btn btn-pry m-0 w-lg-75 w-md-100"
                        disabled
                      >
                        <i className="bx bx-loader bx-spin bx-sm white-text" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-pry m-0 w-lg-75 w-md-100"
                      >
                        submit
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </>
  );
}

export default AddStaff;
