import React, { useState } from 'react';
import Wrapper from '../../../../Wrapper';

const Test = () => {
    const [num, setnum] = useState(Number());

    function add() {
        var a = parseInt(document.getElementById('number').value, 10);
        a = isNaN(a) ? 0 : a;
        a++;
        setnum(a);
        document.getElementById('number').value = a;
    }
    function des() {
        var a = parseInt(document.getElementById('number').value, 10);
        a = isNaN(a) ? 0 : a;
        a < 1 ? (a = 1) : <></>;
        a--;
        setnum(a);
        document.getElementById('number').value = a;
    }

    return (
        <>
            <Wrapper pagename={'dashboard'}>


                {/* <div className="text-center">

                    <button className='btn'
                        onClick={add}
                    >+</button>
                    <input type="text"
                        value={num}
                        className="text-center"
                        id="number"
                    />
                    <button className='btn'
                        onClick={des}
                    >-</button>
                </div> */}

            </Wrapper>

        </>
    );
}

export default Test;