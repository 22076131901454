import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";


export default function BillReport() {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [load, setload] = useState(false);


    //  Get All report
    function getTransactions(e) {
        setload(true);
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
        };
        axios.post(`${ endpoint }/subscriptionHistory`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    console.log(res.data.data);
                    const report = res.data.data?.map(({ active, amount_th, duration_days, expire_date, time_date, package_name }, i) => {
                        return (
                            {
                                id: `${ i + 1 }`,
                                packageid: <span> {package_name} </span>,
                                time_date: `${ time_date }`,
                                expire_date: `${ expire_date }`,
                                active: `${ active == '0' ? 'expired' : 'active' }`,
                                amount_th: <span className="red-text"> ₦{amount_th} </span>,
                                duration_days: <span> {duration_days} days  </span>,
                            }
                        );
                    }).reverse();


                    setDatatable({
                        ...datatable,
                        rows: report
                    });
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getTransactions();
    }, []);

    //  Get All report

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: "S/N",
                field: "id",
                width: 150,
                attributes: {
                    "aria-controls": "DataTable",
                    "aria-label": "id",
                },
            },
            {
                label: "Package",
                field: "packageid",
                width: 200,
            },
            {
                label: "Amount",
                field: "amount_th",
                width: 270,
            },
            {
                label: "Date ",
                field: "time_date",
                width: 200,
            },
            {
                label: "Expiry ",
                field: "expire_date",
                width: 200,
            },
            {
                label: "Status ",
                field: "active",
                width: 200,
            },
            {
                label: "Duration ",
                field: "duration_days",
                width: 200,
            },

        ],
        rows: []
    });


    return (
        <>
            <div className="my-3">
                <h5 className="pry-bold-text">
                    Business Report
                </h5>
            </div>
            <div className="mt-3">
                <MDBDataTableV5
                    hover
                    fullPagination
                    entries={5}
                    pagesAmount={4}
                    data={datatable}
                    searchTop
                    responsive
                    theadColor="sec-bold pry-bold-text"
                    maxHeight="700px"
                    searchBottom={false}
                    className="white br-md"
                />
            </div>
            {load ? (<AppLoader />) : (
                <> </>)}
        </>

    );
}
