import React from 'react';
import { Link } from 'react-router-dom';


function Showcase() {
    return (
        <>
            <section className="showcase pry-bold light-text">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-7 pt-5 first">
                            <h3 className="my-4 display-4 ">
                                Manage your business  without stress.
                            </h3>
                            <p className="">
                                Reni Brand is a business suite that specializes in developing solutions for small and medium-sized enterprises. We are continually creating concepts and developing business solutions to facilitate business operations and boost productivity.
                            </p>
                            <Link to="/register">
                                <button className="btn pry white-text br-sm">
                                    Get Started
                                </button>
                            </Link>
                        </div>
                        {/* <div className="col-md-5">
                            <img src={img} alt="showcase" width='100%' className='mt-5' />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Showcase;
