import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import { toast } from "react-toastify";
import AppLoader from "../../AppLoader";


export default function AllCustomers() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);

  //  Get All customers
  function getcustomers(e) {
    setload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${ endpoint }/getCustomers`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);


          const customers = res.data.map(({ id, time_recorded, customer_phone, customer_name, customer_mail }, i) => {
            let customerName = !customer_name ? 'Nil' : customer_name;

            return (
              {
                id: `${ id }`,
                time_recorded: `${ time_recorded }`,
                customer_name: `${ customerName }`,
                customer_phone: `${ customer_phone }`,
                customer_mail: `${ customer_mail }`,
              }
            );
          });


          setDatatable({
            ...datatable,
            rows: customers
          });
        }
      })
      .catch((error) => {
        setload(false);
      });

  }
  useEffect(() => {
    getcustomers();
  }, []);

  //  Get All customers


  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Id",
        },
      },
      {
        label: "Time Added",
        field: "time_recorded",
        width: 200,
      },
      {
        label: "Name",
        field: "customer_name",
        width: 200,
      },
      {
        label: "Phone",
        field: "customer_phone",
        width: 270,
      },
      {
        label: "Email ",
        field: "customer_mail",
        width: 200,
      },
    ],
    rows: []
  });

  return (
    <>

      <div className="mt-3 min-height-lg">
        <MDBDataTableV5
          hover
          fullPagination
          entries={10}
          pagesAmount={4}
          data={datatable}
          pagingTop
          searchTop
          responsive
          theadColor="sec-bold pry-bold-text"
          maxHeight="700px"
          searchBottom={false}
          className="white br-md"
        />
      </div>
      {load ? (<AppLoader />) : (
        <> </>)}
    </>

  );
}
