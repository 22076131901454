import React, { useState } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { th } from "../../Global/addCommaToNumber";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import ConfirmSales from './ConfirmSales';
import { useEffect } from 'react';


const OrderDetails = ({ obj, setobj, props, api }) => {
    let history = useHistory();

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [bData] = useState(JSON.parse(localStorage.getItem("bData")));

    const [sending, setsending] = useState(false);
    const [vat_slider, setvat_slider] = useState(false);

    // Function to calc total

    let x = 0;
    function calcTotal() {
        const total = obj.map(({ amount }) => x += amount);
        return total[total.length - 1];
    }
    let total_sum = calcTotal();
    // Function to calc total


    // The VAT Rate 
    let vat_rate = (+bData.vat_rate / 100) || 0.00;
    let vat_value = total_sum * vat_rate;
    let total_vat = vat_slider ? th(total_sum) : th(total_sum + vat_value);
    // The VAT Rate 



    //   Add Item Details Function
    function salesAdd(e) {
        e.preventDefault();
        if (props.time === '') {
            toast.error('Select a date..');
        } else {
            if (obj == 0) {
                toast.warn('Select an item or a quantity..');
            } else {
                setsending(true);
                const data = {
                    invid: props.invid,
                    apptoken,
                    usertoken: token,
                    stoken,
                    btoken,
                    type: props.type,
                    obj,
                    pay_mode: props.pay_mode,
                    tax_vat: vat_value,
                    vat_type: props.vat_type
                };
                axios.post(`${ endpoint }/${ api }`, data)
                    .then((res) => {
                        if (res.data.success === false) {
                            setsending(false);
                            toast.warn(res.data.message);
                        } else {
                            setsending(false);
                            history.push(`/sales/invoice/${ props.invid }`);
                            setTimeout(() => {
                                toast.success(res.data.message);
                            }, 100);
                        }
                    })
                    .catch((error) => {
                        toast.error("Network Error!");
                        setsending(false);
                    });
            }
        }
    }

    //   Add Item Details Function


    // Del Items or Order

    const delItem = (i) => {
        obj.splice(i, 1);
        setobj([...obj]);
        setTimeout(() => {
            toast.success('Item Deleted');
        }, 100);
    };
    const delAllItem = () => {
        if (obj.length === 0) {
            toast.warn('Order is Empty!');
        } else {
            setobj([]);
            toast.success('Order Deleted');
        }
    };
    // Del Items or Order

    const payOptions = [
        { name: "POS" },
        { name: "Cash" },
        { name: "Card" },
        { name: "Transfer" },
        { name: "Others" },
    ];


    // Open Modal 
    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    function workModal(e) {
        handleShowremove();
        props.salesFn();
    }
    // Open Modal 
    useEffect(() => {
        props.setvat_type(vat_slider ? "inclusive" : "exclusive");
    }, [vat_slider]);

    return (
        <>

            <div className="my-4">
                <h6 className="pry-bold-text br-sm p-2 sec-bold "> Order Details</h6>
            </div>

            <MDBTable small responsive bordered>
                <MDBTableHead color="sec-bold pry-bold-text" >
                    <tr>
                        <th>No.</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>Delete</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody color="white" >
                    {obj.map((item, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td className="pt-3-half">
                                {item.item_name}
                            </td>
                            <td>
                                {item.qty}
                            </td>
                            <td>
                                ₦{th(item.price)}
                            </td>
                            <td>
                                ₦{th(item.amount)}
                            </td>
                            <td className="text-center">
                                <i className="bx bxs-trash red-text ml-3 " onClick={(e) => delItem(i)} />
                            </td>
                        </tr>
                    ))}

                    {obj == 0 ?
                        <> </>
                        :
                        <>
                            <tr >
                                <td>=</td>
                                <td className="font-weight-bold py-3">
                                    VAT ({bData.vat_rate || 0.00}%)
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td className='font-weight-bold py-3'>
                                    ₦{th(vat_value)}
                                </td>
                                <td className="text-center">

                                </td>
                            </tr>
                            <tr >
                                <td>=</td>
                                <td className="font-weight-bold py-3">
                                    Total
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td className='font-weight-bold py-3'>
                                    ₦{total_vat}
                                </td>
                                <td className="text-center">

                                </td>
                            </tr>
                        </>
                    }


                </MDBTableBody>
            </MDBTable>


            {obj == 0 ?
                <div className="text-center pry-text">
                    <i className="bx bi-exclamation-circle bx-md mt-5" /> <br />
                    order is empty ...
                </div> :
                <>
                    <div className="text-right">
                        <button className="btn btn-danger btn-sm" onClick={delAllItem}>
                            <i className="bx bxs-trash light-text" /> Clear All
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-6 ">
                            <label className="mt-3 m-0">Mode of Payment:</label>
                            <select
                                name="item_id"
                                className="input-style"
                                onChange={((e) => props.setpay_mode(e.target.value))}
                            >
                                <option value="">
                                    Select Mode of Payment
                                </option>
                                {payOptions.map(({ name }, i) => <option value={name} key={name}>{name}</option>)}
                            </select>
                        </div>
                        <div className="col-md-6 ">
                            <label className="mt-3 m-0">VAT Type:</label>
                            <br />
                            <div className="d-flex align-items-center">
                                <label class="switch mt-3">
                                    <input
                                        type="checkbox"
                                        onChange={((e) => setvat_slider(e.target.checked))}

                                    />
                                    <span class="slider round"></span>
                                </label>
                                <h5 className='mt-2 ml-2 font-weight-light'>
                                    
                                    {vat_slider ? 'INCLUSIVE' : 'EXCLUSIVE'}
                                    </h5>
                            </div>
                            <div className="small pb-3">Toggle to select <span className='font-weight-bold'>  {vat_slider ? 'Exclusive' : 'Inclusive'}</span></div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-pry m-0 w-md-100"
                            onClick={(e) => workModal()}
                        >Proceed</button>
                    </div>



                </>}

            <ConfirmSales
                show={showremove}
                onHide={handleCloseremove}
                animation={false}
                obj={obj}
                props={props}
                salesAdd={salesAdd}
                sending={sending}
                vat_value={vat_value}
                total_vat={total_vat}

            />

        </>
    );
};

export default OrderDetails;