import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";


const AddWaybill = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  // Form Fields
  const [formField, setformField] = useState({
    invid: props.invid,
    vehicleNumber: "",
    driverName: "",
    driverNumber: "",
    receiverName: "",
    receiverNumber: "",
    rAddress: "",
  });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //  Add Waybill Function
  //  Add Waybill Function
  function waybillAdd(e) {
    e.preventDefault();
    if (formField.vehicleNumber && formField.driverName && formField.driverNumber && formField.receiverName && formField.receiverNumber && formField.rAddress) {
      setissending(true);
      const data = {
        driverName: formField.driverName,
        driverNumber: formField.driverNumber,
        vehicleNumber: formField.vehicleNumber,
        rName: formField.receiverName,
        rNumber: formField.receiverNumber,
        rAddress: formField.rAddress,
        invid: formField.invid,
        usertoken: token,
        stoken: stoken,
        btoken: btoken,
        apptoken: apptoken
      };
      axios.post(`${ endpoint }/wayBill.add`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.error(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            clear();
            props.onHide();
            props.getInvDetails();
            props.getwaybill();
          }
        })
        .catch((error) => {
          toast.error("Network Error!");
          setissending(false);
        });
    } else {
      toast.error('Empty Fields!');
    }
  }
  //  Add Waybill Function
  //  Add Waybill Function

  function clear() {
    setformField({
      vehicleNumber: "",
      driverName: "",
      driverNumber: "",
      receiverName: "",
      receiverNumber: "",
      rAddress: "",
    });
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Create a new waybill
            </h6>
          </Modal.Header>

          <section className=" ">
            <div className="container">
              <div className="text-center">
                <div className="">
                  <form id="itemForm">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Driver's Name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="driverName"
                          placeholder="e.g. Jane Doe"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.driverName}
                        />
                      </div>

                      <div className="col-md-12 ">
                        <label className="mb-0"> Driver's Number:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="driverNumber"
                          placeholder="e.g. 08012345678"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.driverNumber}
                        />
                      </div>

                      <div className="col-md-12 ">
                        <label className="mb-0"> Vehicle's Number:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="vehicleNumber"
                          placeholder="e.g. AQW1233AQ"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.vehicleNumber}
                        />
                      </div>

                      <div className="col-md-12 ">
                        <label className="mb-0"> Receiver's Name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="receiverName"
                          placeholder="e.g. Jane Doe"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.receiverName}
                        />
                      </div>
                      <div className="col-md-12 ">
                        <label className="mb-0"> Receiver's Number:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="receiverNumber"
                          placeholder="e.g. 08012345678"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.receiverNumber}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="mb-0"> Receiver's Address:</label>
                        <textarea
                          type="text"
                          className="input-style textarea-style"
                          name="rAddress"
                          placeholder="e.g. 123, ABC Street, XYZ City"
                          rows="3"
                          onChange={handleChange}
                          required
                          value={formField.rAddress}
                        ></textarea>
                      </div>
                      {/* <div className="col-md-12 ">
                        <label className="mb-0"> Category:</label>
                        <ClipLoader color={"#023676"} loading={loading} speedMultiplier="1.2" size="15" />

                        <Select
                          options={categories}
                          isSearchable={true}
                          onChange={e => seteid(e.value)}
                          placeholder='Select category'

                        />
                      </div> */}

                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button
                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"
                                onClick={(e) => waybillAdd(e)}
                              >
                                submit
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default AddWaybill;
