import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const EditInventory = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [load, setload] = useState(false);
  const [unit, setunit] = useState('');
  // Form Fields
  const [formField, setformField] = useState({ item_name: "", qty: "", batch_no: "", expiry_date: "", cost_price: "", selling_price: "", details: "", qty_limit: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  // Get Inventory Details
  const getInventoryData = () => {
    setload(true)
    const data = {
      invtid: props.id,
      apptoken: apptoken,
      usertoken: token,
      btoken: btoken
    }
    axios.post(`${endpoint}/getInventoryDetail`,
      data)
      .then((res) => {
        if (res.data.success == false) {
          setload(false)
        } else {
          setformField({
            item_name: res.data.item_name,
            details: res.data.details,
            batch_no: res.data.batch_no,
            qty: res.data.qty,
            cost_price: res.data.cost_price,
            selling_price: res.data.selling_price,
            qty_limit: res.data.qty_limit
          });
          setunit(res.data.unit ? res.data.unit : '')
          setload(false)
        }
      }).catch((error) => {
        setload(false)
        toast.error(error.name)
      });
  };
  useEffect(() => {
    getInventoryData();
  }, [props.show]);
  // Get Inventory Details

  //  Add Inventory Function
  //  Add Inventory Function
  function inventoryEdit(e) {
    e.preventDefault();
    if (formField.qty < "1") {
      toast.warn('Quantity must be greater than 1');
      setissending(false);
    } else {
      if (formField.details && formField.qty && formField.cost_price && formField.selling_price) {
        setissending(true);
        const data = {
          details: formField.details,
          batch_no: formField.batch_no,
          qty: formField.qty,
          expiry_date: formField.expiry_date,
          cost_price: formField.cost_price,
          selling_price: formField.selling_price,
          qty_limit: formField.qty_limit,
          invtid: props.id,
          apptoken: apptoken,
          usertoken: token,
          btoken: btoken
        }
        axios.post(`${endpoint}/editInventory`,
          data)
          .then((res) => {
            if (res.data.success === false) {
              toast.warn(res.data.message);
              setissending(false);
            } else {
              setissending(false);
              toast.success(res.data.message);
              getInventoryData();
              props.getInventoryFn();
            }
          })
          .catch((error) => {
            setissending(false);
            toast.error("Network Error!");
          });
      } else {
        setissending(false);
        toast.error("Empty Fields!");
      }
    }
  }

  //  Add Inventory Function
  //  Add Inventory Function

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Edit Inventory - {formField.item_name}
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    {load ? <span className="d-flex justify-content-center align-items-center"><i className="bx bx-loader bx-spin bx-md pry-text mr-3" /> Loading Data... </span> :
                      <></>}
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Item name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="item_name"
                          onChange={handleChange}
                          value={formField.item_name}
                          disabled
                        />
                      </div>
                      <div className="12"></div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Cost Price
                          {
                            (unit === "0" || unit === '' || unit === undefined) ? '' :
                              <span className="small"> <i> (per {unit})</i></span>
                          }
                          :</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="cost_price"
                          placeholder="e.g. 1000"
                          onChange={handleChange}
                          required
                          value={formField.cost_price}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Selling Price
                          {
                            (unit === "0" || unit === '' || unit === undefined) ? '' :
                              <span className="small"> <i> (per {unit})</i></span>
                          }
                          :</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="selling_price"
                          placeholder="e.g. 1500"
                          onChange={handleChange}
                          required
                          value={formField.selling_price}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Qty
                          {
                            (unit === "0" || unit === '' || unit === undefined) ? '' :
                              <span className="small"> <i> (per {unit})</i></span>
                          }
                          :</label>

                        <input
                          autoComplete="off"
                          type="number"
                          className=" input-style"
                          name="qty"
                          onChange={handleChange}
                          required
                          value={formField.qty}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Quantity Limit <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="number"
                          className=" input-style"
                          onChange={handleChange}
                          name="qty_limit"
                          required
                          value={formField.qty_limit}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Batch No <span className="small"> <i> (optional)</i></span>: </label>
                        <input
                          type="text"
                          className=" input-style"
                          name="batch_no"
                          onChange={handleChange}
                          required
                          value={formField.batch_no}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Expiry Date  <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="date"
                          className=" input-style"
                          name="expiry_date"
                          onChange={handleChange}
                          value={formField.expiry_date}
                        />
                      </div>


                      <div className="col-md-12">
                        <label className="mb-0"> Item Description:</label>
                        <textarea
                          type="text"
                          className="input-style textarea-style"
                          name="details"
                          rows="3"
                          onChange={handleChange}
                          required
                          value={formField.details}
                        ></textarea>
                      </div>

                      <div className="col-md-10 mx-auto text-center">

                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry px-5 m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry px-5 m-0 w-100"
                                onClick={(e) => inventoryEdit(e)}
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditInventory;
