import React, { useState } from 'react';
import AddExp from './AddExp';

function ExpTitle(props) {

  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1() {
    handleShowremove1();
  }

  return (
    <div className="dash-title">
      <div className="row align-items-center">
        <div className="col pry-bold-text">
          <h5>EXPENSES</h5>
        </div>
        <div className="col ">
          <button className="btn btn-pry float-right" onClick={(e) => workModal1()}>
            <i className="bx bx-plus mr-2 " />
            Add New
          </button>
        </div>
      </div>


      {/* Add Inventory Modal */}
      <AddExp
        show={showremove1}
        onHide={handleCloseremove1}
        animation={false}
        getexpenses={e=>props.getexpenses()}
        getExpCat={e=>props.getExpCat()}
      />
      {/* Add Inventory Modal */}
    </div>
  );
}

export default ExpTitle;
