import React, { useState } from 'react';
import { Link } from "react-router-dom";
import gif from "../../404.gif";
import Wrapper from "../../Wrapper";
import AllPackages from '../../Components/User/Billing/Packages/AllPackages'

const NoUserAccess = () => {

  const [token] = useState(localStorage.getItem("token"));
  const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
  const [owner_token] = useState(bData?.owner_token);


  const route = "upgrade"
  const pagename = "dashboard"


  return (
    <>
      <Wrapper pagename={pagename} route={route}>
        {owner_token === token ?
          <main className="pagenotfond whie">

            <div className=" text-center">
              <h5 className="pry-text"> This Business is Inactive </h5>
              <div className="d-flex align-items-center justify-content-center" >
                <Link to="/settings/billing">
                  <button className="btn pry light-text mt-3"> Upgrade Business </button>
                </Link>
              </div>
              <AllPackages />
            </div>
          </main>

          :
          <main className="pagenotfound white">
            <div className=" text-center">
              <img src={gif} className="gif" width="50%" alt="pagenotfound" />
            </div>

            <div className=" text-center">
              <h5 className="pry-text"> This Business is Inactive </h5>
              <div className="d-flex align-items-center justify-content-center" >
                <Link to="#">
                  <button className="btn pry light-text mt-3"> Contact Business Admin </button>
                </Link>
              </div>
            </div>
          </main>
        }
      </Wrapper>
    </>
  );
};

export default NoUserAccess;
