import React, { useState } from 'react';
import MonthlyPackages from './MonthlyPackages';
import YearlyPackages from './YearlyPackages';


const AllPackages = () => {

    // Sales Type Function
    const [type, settype] = useState('monthly');
    function changeType(e) {
        settype(e)
    }
    // Sales Type Function


    return (
        <>
            <div className="packages ">

                {/* Select buttons for Monthly or Yearly */}

                <div
                    className="d-flex sec-bold py-2 px-2 br-sm fit-content mx-auto mt-4 justify-content-between">
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center 
                    ${type === 'monthly' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('monthly')}
                    >
                        Monthly
                    </div>
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center ${type === 'annually' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('annually')}
                    >
                        Annually
                    </div>
                </div>
                    <p className='pry-bold-text text-center my-3'>
                        Only pay for 11 months, get one month for free when you choose Annually
                    </p>

                {/* Select buttons for Monthly or Yearly */}

                {type === 'monthly' ?
                    <MonthlyPackages
                    /> :
                    <YearlyPackages
                    />
                }

            </div>
        </>
    );
}

export default AllPackages;