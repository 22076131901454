import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { th } from "../../Global/addCommaToNumber";

const ConfirmSales = (props) => {
    const [bData] = useState(JSON.parse(localStorage.getItem("bData")));

    const { salesAdd, props: data, obj, sending, total_vat, vat_value } = props

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={true}
                centered
                size="lg"
            >
                <Modal.Body style={{ backgroundColor: "transparent!important" }}>
                    <Modal.Header closeButton>
                        <h6 className="font-weight-bold pry-text ml-auto">
                            Confirm Order
                        </h6>
                    </Modal.Header>

                    <section className="addinvent">
                        <div className="container-fliud">
                            <div className="">
                                {/* Customer details */}
                                <div className="my-4">
                                    <h6 className="pry-bold-text pb-2 border-primary border-bottom "> Customer Details</h6>
                                </div>
                                <div className="my-4">
                                    <p className="mb-1">
                                        Name: {data?.customer?.customer_name}
                                    </p>
                                    <p className="mb-1">
                                        Phone: {data?.customer?.customer_phone}
                                    </p>
                                    <p className="mb-1">
                                        Email: {data?.customer?.customer_mail}
                                    </p>
                                    <p className="mb-1">
                                        Description: {data?.customer?.details}
                                    </p>
                                </div>

                                {/* Customer details */}



                                {/* Order Details */}
                                <div className="my-4">
                                    <h6 className="pry-bold-text pb-2 border-primary border-bottom "> Order Details</h6>
                                </div>
                                <MDBTable small responsive bordered>
                                    <MDBTableHead color=" pry-bold-text" >
                                        <tr>
                                            <th>No.</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody color="white" >
                                        {obj.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td className="pt-3-half">
                                                    {item.item_name}
                                                </td>
                                                <td>
                                                    {item.qty}
                                                </td>
                                                <td>
                                                    ₦{th(item.price)}
                                                </td>
                                                <td>
                                                    ₦{th(item.amount)}
                                                </td>
                                            </tr>
                                        ))}

                                        {obj == 0 ?
                                            <> </>
                                            :
                                            <>
                                                <tr >
                                                    <td>=</td>
                                                    <td className="font-weight-bold py-3">
                                                        VAT ({bData.vat_rate || 0.00}%)
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td className='font-weight-bold py-3'>
                                                        ₦{th(vat_value)}
                                                    </td>

                                                </tr>
                                                <tr >
                                                    <td>=</td>
                                                    <td className="font-weight-bold py-3">
                                                        Total
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td className='font-weight-bold py-3'>
                                                        ₦{total_vat}
                                                    </td>

                                                </tr>
                                            </>
                                        }


                                    </MDBTableBody>
                                </MDBTable>
                                {/* Order Details */}
                                <p className="mb-1">
                                    Payment: {data?.pay_mode}
                                </p>
                                <p className="mb-1">
                                    VAT type: <span className="text-capitalize"> {data?.vat_type} </span>
                                </p>
                                <div className="mb-4 border-primary border-bottom "></div>
                                {sending ?
                                    <div className="text-center">
                                        <button className="btn btn-pry m-0 w-md-100" disabled>
                                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                                        </button>
                                    </div> :
                                    <div className="d-flex justify-content-center">
                                        <div className="text-center mx-1">
                                            <button className="btn btn-outline-primary m-0 w-md-100"
                                                onClick={() => props.onHide()}>Cancel</button>
                                        </div>
                                        <div className="text-center mx-1">
                                            <button type="submit" className="btn btn-pry m-0 w-md-100"
                                                onClick={e => salesAdd(e)}>Confirm</button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConfirmSales;
