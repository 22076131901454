import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EditSupplier = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));

  // Form Fields
  const [formField, setformField] = useState({ name: "", mail: "", phone: "" });
  const { data } = props

  const { id, name, mail, phone } = data

  useEffect(() => {
    if (data) {
      setformField({ name, mail, phone, })
    }
  }, [data]);

  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //  Edit Supplier Function
  //  Edit Supplier Function
  function supplierEdit(e) {
    e.preventDefault();
    if (formField.name ) {
      setissending(true);
      const data = {
        id,
        name: formField.name,
        mail: formField.mail,
        phone: formField.phone,
        stoken,
        btoken,
        apptoken,
      }
      axios
        .post(`${endpoint}/editSupplier`, data)
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            toast.success(res.data.message);
            props.getSupplier();
            props.onHide()
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }

  //  Edit Supplier Function
  //  Edit Supplier Function


  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Create a new Supplier
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form" onSubmit={(e) => supplierEdit(e)}>
                  <form enctype="multipart/form-data">
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Supplier name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="name"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.name}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Email  <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="mail"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.mail}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label className="mb-0"> Phone  <span className="small"> <i> (optional)</i></span>:</label>
                        <input
                          type="number"
                          className=" input-style"
                          name="phone"
                          onChange={handleChange}
                          autoComplete="off"
                          value={formField.phone}
                        />
                      </div>

                      <div className="col-md-10 mx-auto text-center">
                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button
                                className="btn btn-pry m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry m-0 w-100"
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSupplier;
