import React, { useState, useEffect } from "react";
import AddStaff from "./AddStaff";
import axios from "axios";
import { toast } from "react-toastify";
import AppLoader from "../../../AppLoader";
import StaffFeature from "./StaffFeature";
import DeleteModal from "../../Global/DeleteModal";


function AllStaffs() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
  const [owner_token] = useState(bData?.owner_token);
  const [allstaff, setallstaff] = useState([]);
  const [load, setload] = useState();
  const [deleting, setdeleting] = useState(false);


  //  Get All Staff
  function getStaff(e) {
    setload(true);
    const data = {
      stoken: stoken,
      apptoken: apptoken,
      btoken: btoken
    }
    axios.post(`${endpoint}/getStaff`, data
    )
      .then((res) => {
        if (res.data.success === false) {
          setload(false);
          toast.warn(res.data.message);
        } else {
          setload(false);
          setallstaff(res.data);
        }
      })
      .catch((error) => {
        setload(false);
      });
  }
  useEffect(() => {
    getStaff();
  }, []);
  //  Get All Staff


  // Change Page between Add and All Staff
  const [butn, setbutn] = useState("0");

  function AddStaffPage() {
    var x = document.getElementById("hide");
    if (x.className === "hide") {
      setbutn("1");
      x.className = "show";
    }
  }

  function CloseStaffPage() {
    var x = document.getElementById("hide");
    if (x.className === "show") {
      setbutn("0");
      x.className = "hide";
    }
  }
  // Change Page between Add and All Staff


  // Open Staff Features Modal
  const [showremove, setShowremove] = useState(false);
  const handleCloseremove = () => setShowremove(false);
  const handleShowremove = () => setShowremove(true);

  const [features, setfeatures] = useState('');

  function workModal(item) {
    handleShowremove();
    setfeatures(item)
  }
  // Open Staff Features Modal

  //  Delete Staff Function
  //  Delete Staff Function
  function staffDel(e) {
    setdeleting(true);
    const data = {
      apptoken: apptoken,
      usertoken: e,
      btoken: btoken
    }
    axios.post(`${endpoint}/removeStaff`,
      data,
    )
      .then((res) => {
        if (res.data.success === false) {
          toast.warn(res.data.message);
          setdeleting(false);
          getStaff()
        } else {
          setdeleting(false);
          toast.success(res.data.message);
          getStaff()
        }
      })
      .catch((error) => {
        setdeleting(false);
        toast.error("Network Error!");
        getStaff()
      });
  }
  function delConfirm(staffname, usertoken) {
    if (usertoken === owner_token) {
      toast.error('You can\'t delete the business owner')
    } else if (usertoken === token) {
      toast.error('You can\'t delete yourself')
    } else {
      if (window.confirm(`Are you sure you want to delete ${staffname} ?`)) {
        staffDel(usertoken);
      }
    }
  }
  //  Delete Staff Function
  //  Delete Staff Function

  return (
    <>
      <div className="form mt-1">

        <div className="border-bottom col mt-4">
          <h6 className="font-weight-bold pry-text" >
            {butn == "0" ? "ALL STAFFS" : "ADD STAFF"}</h6>
        </div>
        <div className="text-right mb-4">
          {butn == "0" ? (
            <button className="btn btn-pry " onClick={AddStaffPage}>
              <i className="bx bx-plus mr-2 " />
              Add Staff
            </button>
          ) : (
            <button className="btn btn-pry " onClick={CloseStaffPage}>
              <i className="bx bxs-user mr-2 " />
              All Staffs
            </button>
          )}
        </div>

        <div id="hide" className="hide">
          <AddStaff getStaff={e => getStaff()} staffPage={e => CloseStaffPage()} />
        </div>
        {butn == "1" ? <> </> : <>

          <div className="container">

            {allstaff.length === 0 ? (
              <div className="grey-text text-center mt-5">
                <i className="bi bi-exclamation-circle bx-md" />
                <p>  No Staff available </p>
              </div>
            ) : (
              <>
                <div className="row text-center all-staff">
                  {allstaff.map((item, i) => {
                    const { staffname, staffmail, usertoken } =
                      item;
                    const ids = { usertoken: usertoken }
                    return (
                      <div className="col-md-6 col-lg-4" key={usertoken}>

                        <div className=" sec-bold pry-text pt-2 mb-3">
                          {/* Dropdown */}
                          <div className="ml-auto mr-3 pt-2 fit-content"
                            type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i className="bi bi-three-dots-vertical h3 text-right cursor list"
                            />
                          </div>
                          <div className="dropdown-menu pry-text text-center dropdown-menu-right">
                            <a className="dropdown-item pry-text h6"
                              onClick={e => workModal(item)}
                            >
                              <i className="bi bi-pen-fill text-right cursor list"
                              />   EDIT</a>
                            {/* <a className="dropdown-item  pry-text h6"
                              onClick={e => delConfirm(staffname, usertoken)}
                            >
                              <i className="bi bi-trash3-fill text-right cursor list"
                              />   DELETE</a> */}
                            {usertoken === owner_token ?
                              <a className="dropdown-item  pry-text h6"
                                onClick={() => toast.error('You can\'t delete the business owner')}>
                                <i className="bi bi-trash3-fill text-right cursor list" /> DELETE</a>
                              : <>
                                {usertoken === token ?
                                  <a className="dropdown-item  pry-text h6"
                                    onClick={() => toast.error('You can\'t delete yourself')}>
                                    <i className="bi bi-trash3-fill text-right cursor list" /> DELETE</a>
                                  :
                                  <>
                                    <DeleteModal
                                      ids={ids}
                                      name={staffname}
                                      url={'removeStaff'}
                                      Fn={getStaff}
                                      from={'Staffs'}

                                    >
                                      <a className="dropdown-item  pry-text h6"
                                      >
                                        <i className="bi bi-trash3-fill text-right cursor list"
                                        />   DELETE</a>
                                    </DeleteModal>

                                  </>
                                }
                              </>

                            }

                          </div>

                          {/* Dropdown */}
                          <h5 className="my-3">
                            <i className="white br-lg p-2  bi bi-person-square h4 " />
                          </h5>
                          <h6 className="font-weight-bold mb-0"> {staffname} </h6>
                          <small> {staffmail} </small>

                          <div className="pb-2 pt-5">
                            <small className="grey-text"> Logged in - 10/10/2000</small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>

        </>}

      </div>

      {(load || deleting) ? <AppLoader /> : <> </>}

      {/* Choose Staff Feature Modal */}
      <StaffFeature
        show={showremove}
        onHide={handleCloseremove}
        animation={false}
        staffdetails={features}
        getStaff={e => getStaff()}

      />
      {/* Choose Staff Feature Modal */}
    </>
  );
}

export default AllStaffs;
