import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

function GetUserData() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [bizdata, setbizdata] = useState();
  const [storedata, setstoredata] = useState();
  let history = useHistory();

  //  Get User details Function
  //  Get User details Function
  function getData(e) {
    // const data = {
    //   usertoken: token,
    //   btoken: btoken,
    //   apptoken: apptoken,
    // };

    // axios
    //   .post(`${ endpoint }/getUserData`, data)
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${ endpoint }/getUserData`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          toast.warn(res.data.message);
        } else {
          setbizdata(res.data.totalBusiness);
          setstoredata(res.data.totalStores);
        }
      })
      .catch((error) => {
        toast.error("Network Error!");
      });
  }
  useEffect(() => {
    getData();
  }, []);
  //  Get User details Function
  //  Get User details Function

  //  Get Business Details Function
  const getbizData = () => {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${ endpoint }/getBusinessDetails`, data,)
      .then((res) => {
        if (res.data.success == false) {
        } else {
          localStorage.setItem('bData', JSON.stringify(res.data.details));
          localStorage.setItem('bname', res.data.details.name);
          localStorage.setItem('active', res.data.details.active);
        }
      });
  };
  useEffect(() => {
    getbizData();
  }, []);
  //  Get Business Details Function


  //  GetStore Details Function

  function getStoreData(e) {
    const data = new FormData();
    data.append("btoken", btoken);
    data.append("stoken", stoken);
    data.append("usertoken", token);
    data.append("apptoken", apptoken);

    axios
      .post(`${ endpoint }/getStoreDetails`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
        } else {
          localStorage.setItem("sname", res.data.name);
        }
      });
  }
  useEffect(() => {
    getStoreData();
  }, []);
  //  GetStore Details Function


  // Route to Create Biz Profile and Store
  if (bizdata === 0) {
    history.push("/create-business");
    alert("You need to setup a Company Profile");
  }
  if (storedata === 0) {
    history.push("/create-store");
    alert("You need to setup a Store Profile");
  }
  // Route to Create Biz Profile and Store

  // Route to Choose Biz Profile and Store
  if (btoken === null) {
    history.push("/set-profile");
  }
  // Route to Choose Biz Profile and Store



  return (
    <>
      <div>
      </div>
    </>
  );
}

export default GetUserData;
