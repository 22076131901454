import React from "react";
import { useHistory } from "react-router-dom";

const BackButton = ({ title }) => {
  const history = useHistory();

  function gooBack() {
    history.goBack();
  }
  return (
    <>
      <div className="userback">
        <a className="d-flex " onClick={gooBack}>
          <i className="bi bi-chevron-left mr-2 h5 pry-bold-text mt-1" />
          <h5 className='mt-1 mr-2'>{title} </h5>
        </a>
      </div>
    </>
  );
};

export default BackButton;
