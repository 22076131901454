import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const TopUpInventory = (props) => {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);

  const [token] = useState(localStorage.getItem("token"));
  const [btoken] = useState(localStorage.getItem("btoken"));
  const [stoken] = useState(localStorage.getItem("stoken"));
  const [load, setload] = useState(false);
  const [unit, setunit] = useState('');
  // Form Fields
  const [formField, setformField] = useState({ qty: "" });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  // Check Access
  // Check Access
  const checkfeature = (feature) => {
    const data = JSON.parse(localStorage.getItem('features'))?.findIndex(x => x.feature === feature)

    if (data >= 0) {
      return true
    } else {
      return false
    }
  }
  // Check Access
  // Check Access


  // Get Inventory Details
  const getInventoryData = () => {
    setload(true)
    const data = {
      invtid: props.id,
      apptoken: apptoken,
      usertoken: token,
      btoken
    }
    axios.post(`${endpoint}/getInventoryDetail`,
      data)
      .then((res) => {
        if (res.data.success == false) {
          setload(false)
        } else {
          setformField({
            item_name: res.data.item_name,
            qty: res.data.qty,

          });
          setunit(res.data.unit ? res.data.unit : '')
          setload(false)
        }
      }).catch((error) => {
        setload(false)
        toast.error(error.name)
      });
  };
  useEffect(() => {
    getInventoryData();
  }, [props.show]);
  // Get Inventory Details

  //  Add Inventory Function
  //  Add Inventory Function
  function inventoryEdit(e) {
    e.preventDefault();
    if (formField.qty < "1") {
      toast.warn('Quantity must be greater than 1');
      setissending(false);
    } else {
      if (formField.qty) {
        setissending(true);
        const data = {

          qty: formField.qty,
          invtid: props.id,
          apptoken: apptoken,
          usertoken: token,
          btoken, stoken,
        }
        axios.post(`${endpoint}/topUpInventory`,
          data)
          .then((res) => {
            if (res.data.success === false) {
              toast.warn(res.data.message);
              setissending(false);
            } else {
              setissending(false);
              toast.success(res.data.message);
              getInventoryData();
              props.getInventoryFn();
            }
          })
          .catch((error) => {
            setissending(false);
            toast.error("Network Error!");
          });
      } else {
        setissending(false);
        toast.error("Empty Fields!");
      }
    }
  }

  //  Add Inventory Function
  //  Add Inventory Function

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        centered
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "transparent!important" }}>
          <Modal.Header closeButton >
            <h6 className="font-weight-bold pry-text ml-auto">
              Edit Inventory - {formField.item_name}
            </h6>
          </Modal.Header>

          <section className="addinvent ">
            <div className="container">
              <div className="text-center">
                <div className="form">
                  <form enctype="multipart/form-data">
                    {load ? <span className="d-flex justify-content-center align-items-center"><i className="bx bx-loader bx-spin bx-md pry-text mr-3" /> Loading Data... </span> :
                      <></>}
                    <div className="row justify-content-center text-left">
                      <div className="col-md-12 ">
                        <label className="mb-0"> Item name:</label>
                        <input
                          type="text"
                          className=" input-style"
                          name="item_name"
                          onChange={handleChange}
                          value={formField.item_name}
                          disabled
                        />
                      </div>
                      <div className="12"></div>

                      <div className="col-md-12 ">
                        <label className="mb-0"> Qty
                          {
                            (unit === "0" || unit === '' || unit === undefined) ? '' :
                              <span className="small"> <i> (per {unit})</i></span>
                          }
                          :</label>

                        <input
                          autoComplete="off"
                          type="number"
                          className=" input-style"
                          name="qty"
                          onChange={handleChange}
                          required
                          value={formField.qty}
                        />
                      </div>

                      <div className="col-md-10 mx-auto text-center">

                        <div className="user-btn mb-4 mr-auto text-center">
                          {issending ? (
                            <>
                              <button

                                className="btn btn-pry px-5 m-0 w-100"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin bx-sm white-text" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-pry px-5 m-0 w-100"
                                onClick={(e) => inventoryEdit(e)}
                              >
                                submit
                              </button>
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TopUpInventory;
