import Title from '../../Components/User/Global/Title';
import Wrapper from '../../Wrapper';
import React, { useState } from "react";
import axios from "axios";
import ItemDetails from "../../Components/User/Invoice-Sales/SalesRecord/ItemDetails";
import { toast } from "react-toastify";
import CustomersDetails from "../../Components/User/Invoice-Sales/SalesRecord/CustomersDetails";
import { formatDate } from '../../Components/User/Global/GetDate';

function SalesRecord() {

    // PageName for Routing  
    const pagename = "sales"
    // PageName for Routing  


    // Sales Type Function
    const [type, settype] = useState('product');
    function changeType(e) {
        settype(e)
        if (e === 'service') {
            toast.success('You have selected Services')
        }
        if (e === 'product') {
            toast.success('You have selected Products')
        }
    }
    // Sales Type Function

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);

    const [usertoken] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [customer, setcustomer] = useState([]);

    // GEN TOKEN
    const Gen = Math.floor(Math.random() * 98354673);
    const [token] = useState(`INV${Gen}`);

    const [pay_mode, setpay_mode] = useState('');
    const [vat_type, setvat_type] = useState('exclusive');

    // Form Fields
    const [formField, setformField] = useState({ invid: `${token}`, sales_time: `${formatDate(new Date())}`, customer_name: "", customer_mail: "", customer_phone: "", details: "" });
    function handleChange(e) {
        setformField((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
        // salesAdd();
    }
    // Form Fields

    //   Add Customer Details Function
    const salesAdd = () => {
        if (formField.sales_time) {
            const data = new FormData();
            data.append("invid", formField.invid);
            data.append("sales_date", formField.sales_time);
            data.append("customer_name", formField.customer_name);
            data.append("customer_phone", formField.customer_phone);
            data.append("customer_mail", formField.customer_mail);
            data.append("details", formField.details);
            data.append("pay_mode", pay_mode);
            data.append("vat_type", vat_type);
            data.append("usertoken", usertoken);
            data.append("stoken", stoken);
            data.append("btoken", btoken);
            data.append("apptoken", apptoken);

            axios
                .post(`${endpoint}/createSales`, data, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then((res) => {
                })
                .catch((error) => {
                    toast.error("Network Error!");
                });
        } else {
            toast.error("Choose date first..")
        }
    }
    //   Add Customer Details Function


    return (
        <>
            <Wrapper
                pagename={pagename}>
                <Title
                    title={"RECORD SALES"}
                    link={"/sales/invoice"}
                    btnName={"Invoice"}
                    btnIcon={"bxs-report"} />

                {/* Select buttons for Services or Products */}

                <div
                    className="d-flex sec-bold py-2 px-2 br-sm fit-content mx-auto mt-4 justify-content-between">
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center 
                    ${type === 'product' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('product')}
                    >
                        PRODUCT
                    </div>
                    <div
                        className={`col mx-1 br-sm py-1 pry-bold-text pointer text-center ${type === 'service' ? 'pry-bold light-text' : ('')} `}
                        onClick={e => changeType('service')}
                    >
                        SERVICES
                    </div>
                </div>

                {/* Select buttons for Services or Products */}

                <div
                    className="mt-3 sales-record">

                    <CustomersDetails
                        handleChange={handleChange}
                        formField={formField}
                    />

                    <ItemDetails
                        invid={formField.invid}
                        time={formField.sales_time}
                        customer={formField}
                        salesFn={e => salesAdd(e)}
                        type={type}
                        setpay_mode={setpay_mode}
                        pay_mode={pay_mode}
                        setvat_type={setvat_type}
                        vat_type={vat_type}

                    />

                </div>



            </Wrapper>
        </>
    );
}

export default SalesRecord;