import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <main className='footer'>
                <footer class="page-footer font-small">
                    <div class="container text-center text-md-left mb-5 pt-5">
                        <div class="row text-center">

                            <div class="col-md-4 mx-auto d-none d-md-block">
                                <h5 class="font-weight-bold mt-3 mb-4">Quick Links</h5>

                                <ul class="list-unstyled h5">
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/register">Sign up</Link>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/login">Sign In</Link>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="#">FAQs</Link>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="#">Privacy</Link>
                                    </li>

                                </ul>
                            </div>
                            <div class="col-md-4 mx-auto d-none d-md-block">
                                <h5 class="font-weight-bold mt-3 mb-4">Resources</h5>

                                <ul class="list-unstyled h5">
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/#products">Products</Link>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/#pricing">Pricing</Link>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/about">About us</Link>
                                    </li>
                                    {/* <li className='mb-3 sec-bold-text font-weight-light'>
                                        <Link to="/how-to-use">How To Use</Link>
                                    </li> */}

                                </ul>
                            </div>

                            <div class="col-md-4 mx-auto d-none d-md-block">
                                <h5 class="font-weight-bold mt-3 mb-4">Products</h5>

                                <ul class="list-unstyled h5">
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <a target='_blank' href='https://renitrust.com'>ReniTrust</a>
                                        <i className='bi bi-link-45deg ml-2 ' />

                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <a target='_blank' href='https://reni.store'>ReniStore</a>
                                        <i className='bi bi-link-45deg ml-2 ' />
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <a to="#">ReniAccount</a>
                                    </li>
                                    <li className='mb-3 sec-bold-text font-weight-light'>
                                        <a target='_blank' href='https://renicloud.com'>ReniCloud</a>
                                        <i className='bi bi-link-45deg ml-2 ' />
                                    </li>

                                </ul>
                            </div>
                        </div>


                        <div class="ml-auto mr-auto connect">
                            <h5 class="font-weight-bold white-text text-center mt-5 mb-4">
                                CONNECT WITH US
                            </h5>
                            <div class="text-center mb-3 boxicons">
                                <a
                                    href="https://www.facebook.com/ReniSuite/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=''
                                >
                                    <i className='border br-xlg py-3 mx-2 bx bxl-facebook bx-sm px-3 ' />
                                </a>

                                <a href="https://www.instagram.com/ReniSuite"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=''>
                                    <i className='border br-xlg py-3 mx-2 bx bxl-instagram bx-sm px-3 ' />
                                </a>

                                <a
                                    href="https://twitter.com/ReniSuite"
                                    target="_blank"
                                    rel="noreferrer"
                                    className=''
                                >
                                    <i className='border br-xlg py-3 mx-2 bx bxl-twitter bx-sm px-3 ' />
                                </a>
                                <a
                                    href='mailto:hello@reni.tech'
                                    target="_blank"
                                    rel="noreferrer"
                                    className=''
                                >
                                    <i className='border br-xlg py-3 mx-2 bx bi-envelope bx-sm px-3 ' />
                                </a>

                            </div>
                        </div>
                    </div>

                    <div class="footer-copyright light-text text-center py-3">
                        © 2022{" "}
                        <a href="https://reniaccounts.com" class="sec-bold-text ">
                            {" "}
                            reniaccounts.com{" "}
                        </a>{" "}
                        || All rights reserved

                        <hr class="my-1 w-50" />

                        <div style={{ fontSize: "smaller" }}>
                            Developed by
                            <a href="https://fireswitch.tech" class="sec-bold-text ">
                                {" "}
                                FireSwitch Technologies Ltd{" "}
                            </a>
                        </div>
                    </div>
                </footer>
            </main>
        </>
    );
}

export default Footer;