import React from 'react';
import Footer from '../Components/Homepage/Footer';
import GetStarted from '../Components/Homepage/GetStarted';
import Navbar from '../Components/Homepage/Navbar/Navbar';
import OtherProducts from '../Components/Homepage/OtherProducts';
import OurPricing from '../Components/Homepage/OurPricing';
import Showcase from '../Components/Homepage/Showcase';
import WhatWeOffer from '../Components/Homepage/WhatWeOffer';
import img from '../Components/Homepage/img/Image.png';

function Homepage() {
    return (
        <div className='white'>
            <Navbar />
            <div className=" ">
                <Showcase />
                <div className="text-center">

                <img src={img} alt="images" width='70%' className='shadow' />
                </div>
                <WhatWeOffer />
                <GetStarted />
                <OtherProducts />
                <OurPricing />
                <Footer />
            </div>
        </div>
    );
}

export default Homepage;