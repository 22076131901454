import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BillingDetails from '../../Components/User/Billing/BillingDetails';
import BillReport from '../../Components/User/Billing/BillReport';
import CurrentPlan from '../../Components/User/Billing/CurrentPlan';
import TitlenBack from '../../Components/User/Global/TitlenBack';
import Wrapper from '../../Wrapper';


const Billing = () => {

    const [token] = useState(localStorage.getItem("token"));
    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
    const [owner_token] = useState(bData?.owner_token);
    let history = useHistory()

    useEffect(() => {
        if (token !== owner_token) {
            history.push('/dashboard')
        }
    }, []);

    const pagename = "settings"
    const route = "upgrade"


    return (
        <>
            <Wrapper pagename={pagename} route={route}>
                <TitlenBack title={'SETTINGS'} title1={'BILLING'} to={'settings/packages'} btnName={'Packages'} btnIcon={' bi-gem '} />

                <main className="billing">

                    <CurrentPlan />

                    {/* Business Report */}
                    <div className="mt-5 container-fliud">
                        <BillReport />
                    </div>

                    {/* Business Report */}
                    <div className="">
                        <BillingDetails />
                    </div>
                </main>

            </Wrapper>

        </>
    )
}

export default Billing;