import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteModal = ({ children, Fn, ids, url, name, from }) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [deleting, setdeleting] = useState(false);

    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    //   Delete Note Function
    //   Delete Note Function
    function DeleteFn(e) {
        e.preventDefault();
        setdeleting(true);
        const data = {
            ...ids,
            apptoken: apptoken,
            btoken: btoken,
            stoken: stoken,
        }

        axios.post(`${endpoint}/${url}`, data)
            .then((res) => {
                if (res.data.success === false) {
                    toast.warn(res.data.message);
                    setdeleting(false);
                    Fn();
                } else {
                    setdeleting(false);
                    toast.success(res.data.message);
                    Fn();
                    handleCloseremove()
                }
            })
            .catch((error) => {
                setdeleting(false);
                toast.error("Network Error!");
            });
    }
    //   Delete Note Function
    //   Delete Note Function

    return (
        <>

            <span onClick={e => handleShowremove()}>
                {children}
            </span>
            <div className="delete-modal">

                <Modal
                    show={showremove}
                    onHide={handleCloseremove}
                    size="sm"

                >
                    <div className="modal-header d-flex justify-content-center">
                        <p className="heading m-0 fw-bolder">Confirm</p>
                    </div>
                    <Modal.Body style={{ backgroundColor: "transparent!important" }}>
                        <div className="text-center">

                            <i className="fas fa-times fa-4x animated rotateIn text-danger"></i>
                            <p className="heading">
                                Are you sure you want to delete <span> <b> {name} </b> </span> from the list of {from} ?
                            </p>

                        </div>
                    </Modal.Body>
                    <div className="modal-footer justify-content-center">
                        {deleting ?
                            <i className="bx bx-loader bx-spin bx-sm text-danger" />
                            :
                            <>
                                <a type="button" className="btn btn-outline-danger waves-effect"
                                    onClick={e => handleCloseremove()}
                                >No</a>
                                <a href="" className="btn btn-danger  waves-effect "
                                    onClick={e => DeleteFn(e)}
                                >Yes</a>
                            </>
                        }
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default DeleteModal;