import React from 'react';
import Title from '../../Components/User/Global/Title';
import Statement from '../../Components/User/Account-Statement/Statement'
import Wrapper from '../../Wrapper';

function AcctStatement() {
    const pagename = "statement"

    return (
        <>
            <Wrapper pagename={pagename}>
                <Title title={"ACCOUNT STATEMENT"} none={"d-none"} />
                <Statement />
            </Wrapper>
        </>
    );
}

export default AcctStatement;