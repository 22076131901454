import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from "react-toastify";


const BillingDetails = () => {

    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
    const [bal, setbal] = useState('');



    //  Get Account Balance
    function getBal(e) {
        const data = {
            apptoken: apptoken,
            usertoken: token,
        }
        axios.post(`${endpoint}/getUserWalletBalance`, data)
            .then((res) => {
                if (res.data.success === false) {
                    toast.warn(res.data.message);
                } else {
                    setbal(res.data.balance_th)
                }
            })
    }
    useEffect(() => {
        getBal()
    }, []);
    //  Get Account Balance


    // Go to Reni Trust Confirm
    function fundConfirm(e) {
        e.preventDefault()
        if (window.confirm('You will redirected to your Renitrust Account, Confirm?')) {
            window.open('https://renitrust.com/user/wallet/fund', '_blank');
        }
    }
    // Go to Reni Trust Confirm


    return (
        <>
            {/* Billing Details */}

            <div className="mt-5">
                <div className=" pry-bold-text">
                    <h5 className='m-0 p-0'>
                        Billing Details
                    </h5>
                    <p className='m-0 p-0'>
                        Payment and billing information for this team Account
                    </p>
                </div>
                <div className="sec-bold py-4 container br-md mt-3 pry-bold-text">
                    <h5 className='border-bottom border-primary pb-2'>
                        Payment information
                    </h5>
                    <div className="row justify-content-start">
                        <div className="col-12 col-md-2">
                            <p>Payment method:

                            </p>
                        </div>
                        <div className="col-12 col-md-6 font-weight-bold">
                            <p className='m-0 mb-2'>
                                a.     Reni Account
                            </p>
                            <span className="small sec br-md py-2 px-3">Balance: ₦{bal}</span>

                            <p className='m-0 mt-4'>
                                b.    Card Payment
                            </p>
                            <div className="row align-items-center ml-3">
                                <i className='bi bi-credit-card-2-back-fill bx-sm mr-2' />
                                <i className='bx bxl-visa bx-md mr-2' />
                                <i className='bx bxl-mastercard bx-md mr-2' />
                            </div>

                        </div>
                    </div>

                    <div className="text-left mt-4">
                        <a >
                            <button className='btn m-0 pry light-text w-md-100'
                                onClick={(e) => fundConfirm(e)}
                            > Fund Account</button>
                        </a>
                    </div>
                </div>
            </div>

            {/* Billing Details */}


            {/* Billinf Info */}

            <div className="mt-5">

                <div className="sec-bold py-4 container br-md mt-3 pry-bold-text">
                    <h5 className='border-bottom border-primary pb-2'>
                        Billing Information
                    </h5>
                    <div className="row justify-content-start mt-4">
                        <div className="col-3 col-md-2">
                            <p>Name:

                            </p>
                        </div>
                        <div className="col-6 font-weight-bold">
                            <p className='m-0 mb-2'>
                                {bData.name}
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-3 col-md-2">
                            <p>Phone:

                            </p>
                        </div>
                        <div className="col-6 font-weight-bold">
                            <p className='m-0 mb-2'>
                                {bData.phone}
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-3 col-md-2">
                            <p>Address:

                            </p>
                        </div>
                        <div className="col-6 font-weight-bold">
                            <p className='m-0 mb-2'>
                                {bData.address}

                            </p>
                        </div>
                    </div>
                    <div className="text-left mt-4">
                        <button className='btn m-0 pry light-text w-md-100' disabled> Edit Billing Info</button>
                    </div>
                </div>
            </div>

            {/* Billinf Info */}
        </>
    );
}

export default BillingDetails;