import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AllPackages from '../../Components/User/Billing/Packages/AllPackages';
import TitlenBack from '../../Components/User/Global/TitlenBack';
import Wrapper from '../../Wrapper';


const Packages = () => {

    const [token] = useState(localStorage.getItem("token"));
    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
    const [owner_token] = useState(bData?.owner_token);
    let history = useHistory()

    useEffect(() => {
        if (token !== owner_token) {
            history.push('/dashboard')
            setTimeout(() => {
                toast.info('Page not Authorized to User!!!')
            }, 10);
        }
    }, []);



    const pagename = "settings"
    const route = "upgrade"

    return (
        <>
            <Wrapper pagename={pagename} route={route}>
                <TitlenBack title={'SETTINGS'} title1={'BILLING'} title2={'/ PACKAGES'} none={'d-none'} />
                
                    <div className="container-fliud min-height-lg">
                        <div className="pt-5 pry-bold-text">
                            <h4>Change your Business plan</h4>
                            <p>Upgrade your Business Plan to suit your business needs.</p>
                        </div>
                <AllPackages />
                    </div>

                {/* <div className="">
                Have higher or different needs?
                </div>

                <div className="">
                Plan Comparison
                </div> */}
            </Wrapper>

        </>
    )
}

export default Packages;