import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";

const PayWithWallet = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [load, setload] = useState(false);

    const [token] = useState(localStorage.getItem("token"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    let history = useHistory()

    const [bal, setbal] = useState('');
    const [loading, setloading] = useState(false);



    //  Get Account Balance
    function getBal(e) {
        setloading(true)
        const data = {
            apptoken: apptoken,
            usertoken: token,
        }
        axios.post(`${endpoint}/getUserWalletBalance`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setloading(false)
                    toast.warn(res.data.message);
                } else {
                    setloading(false)
                    setbal(res.data.balance_th)
                }
            })
    }
    useEffect(() => {
        getBal()
    }, []);
    //  Get Account Balance


    // On Success or Failure of Payment
    // On Success or Failure of Payment

    const updatePay = (e) => {
        e.preventDefault()
        setload(true)
        const data = {
            apptoken: apptoken,
            usertoken: token,
            btoken: btoken,
            amount: props.id.amount,
            packageid: props.id.pid,
            duration_days: props.id.days,

        }
        axios.post(`${endpoint}/subscribeUser_wallet`, data)
            .then((res) => {
                if (res.data.success === true) {
                    setload(false);
                    toast.success(res.data.message)
                    setTimeout(() => {
                        history.push('/settings/billing')
                    }, 2000);
                } else {
                    setload(false);
                    toast.warning(res.data.message)
                }
            })
            .catch((error) => {
                setload(false);
                toast.error("Network Error!");
            });
    };
    function payConfirm(e) {
        if (window.confirm(`Are you sure you want to pay  ₦${props.id.amountth} for the subscription of ${props.id.name} package for ${props.id.days} days ?`)) {
            updatePay(e);
        }
    }
    // On Success or Failure of Payment
    // On Success or Failure of Payment

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={true}
                centered
                size="lg"
            >
                <Modal.Body style={{ backgroundColor: "transparent!important" }}>
                    <Modal.Header closeButton >
                        <h6 className="font-weight-bold pry-text ml-auto">
                        </h6>
                    </Modal.Header>

                    {load ?
                        <div className="mt-5 text-center">
                            <ClipLoader color={"#023676"} loading={load} speedMultiplier="1.2" />
                            <div className="mt-4 mb-5">
                                <h5 className="font-weight-light">
                                    Please wait for your transaction is processing{" "}
                                </h5>
                                <h6> Do not refresh page!!! </h6>
                            </div>
                        </div>
                        : (
                            <>
                                <div className="mt-3 text-center">
                                    <h5 className="font-weight-light pry-text">
                                        Payment for {props.id.name} Package
                                    </h5>
                                    <hr />
                                    <i className="bi bi-cash-coin pry-text bx bx-lg mt-5" />
                                    <h5 className=" font-weight-light pry-text mt-2">
                                        Amount
                                    </h5>
                                    <h1 className=" pry-text mt-3">
                                        ₦ {props.id.amountth}
                                    </h1>
                                    <Alert color="primary fit-content mx-auto">
                                        Account balance: ₦{bal}
                                    </Alert>
                                    <hr className="mt-5" />
                                    <button className='btn btn-pry mt-2 mb-4' onClick={e => payConfirm(e)}>Pay with wallet</button>
                                </div>
                            </>
                        )}

                </Modal.Body>
            </Modal>
        </>
    );
};

export default PayWithWallet;
