import React from 'react';
import { Link } from 'react-router-dom';
import HTUDetails from './HTUDetails';
import { data } from './HowToUseData';


const Navigation = () => {
    return (
        <>
            <div className="d-flex border py-2 justify-content-center">
                {data?.map(({ name, id }) => <Link to={`/how-to-use/${id}`} className='mx-2' key={id}>
                    {name}
                </Link>

                )
                }
            </div>

            <HTUDetails />

        </>
    );
}

export default Navigation;