import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { toast } from "react-toastify";
import reni from '../img/reni.png'

const InventoryPrint = (props) => {
    const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [token] = useState(localStorage.getItem("token"));
    const [stoken] = useState(localStorage.getItem("stoken"));
    const [btoken] = useState(localStorage.getItem("btoken"));
    const [sname] = useState(localStorage.getItem("sname"));
    const [bname] = useState(localStorage.getItem("bname"));
    const [load, setload] = useState(false);
    const [allinvdata, setallinvdata] = useState([]);
    const [cdata, setcdata] = useState("");

    //  Get All Inventory
    function getData(e) {
        setload(true);
        const data = new FormData();
        data.append("usertoken", token);
        data.append("btoken", btoken);
        data.append("stoken", stoken);
        data.append("apptoken", apptoken);

        axios
            .post(`${endpoint}/getInventory`, data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }).then((res) => {
                if (res.data.success === false) {
                    setload(false);
                    toast.warn(res.data.message);
                } else {
                    setload(false);
                    setallinvdata(res.data);
                }
            })
            .catch((error) => {
                setload(false);
            });
    }
    useEffect(() => {
        getData()
    }, []);


    return (
        <>
            <div className="white pt-5 print ">
                <div className="container">

                    {/* Title */}

                    <div className="dash-title">
                        <div className="d-flex justify-content-between border-bottom pb-3 mb-4">
                            <div className="col-md-6 pry-text ">
                                <h5 className="font-weight-light pt-3"> INVENTORY

                                </h5>
                            </div>
                            <div className="col-md-6">
                                <img src={reni} width="20%" alt="logo" className="float-right " />
                            </div>
                        </div>
                    </div>


                    {load ?
                        <div className="min-height-lg py-5 text-center">
                            <h5 className="pry-text"> Loading... </h5>
                        </div>
                        :
                        <>

                            {/* Title */}

                            <div className="text-center mb-3 border-bottom pb-4">
                                <h4 className="pry-text text-uppercase m-0">
                                    {bname}
                                </h4>
                                <h5 className="pry-text text-uppercase m-0">
                                    {sname}
                                </h5>
                            </div>

                            {/* Invoice Details */}
                            <div className="mb-3 pt-4 text-center">
                                ALL INVENTORY
                            </div>

                            {/* Invoice Details */}

                            {/* Item Details */}
                            <div className="">
                                <MDBTable small responsive bordered>
                                    <MDBTableHead >
                                        <tr>
                                            <th className="">No</th>
                                            <th>Inventory</th>
                                            <th className="">Qty</th>
                                            <th className="">Details</th>
                                            <th className="">Expiry Date</th>
                                            {/* <th className="">Cost Price</th>
                                            <th className="">Selling Price</th> */}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody color="white" >
                                        {allinvdata.map((item, i) => (
                                            <tr key={i}>
                                                <td className="">{i + 1}</td>
                                                <td className="pt-3-half">
                                                    {item.item_name}
                                                </td>
                                                <td className="">
                                                    {item.qty}
                                                </td>
                                                <td className="">
                                                    <small>
                                                        {item.details}
                                                    </small>
                                                </td>
                                                <td className="">
                                                    {item.expiry_date}
                                                </td>
                                                {/* <td className="">
                                                    ₦{item.cost_price}
                                                </td>
                                                <td className="">
                                                    ₦{item.selling_price}
                                                </td> */}
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </>
                    }
                </div>
            </div>

            {/* Item Details */}

        </>
    );
};

export default InventoryPrint;