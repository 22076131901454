import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from 'react-spinners';


function SetProfile() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [issending, setissending] = useState(false);
  const [name] = useState(localStorage.getItem('uname'));
  const [token] = useState(localStorage.getItem('token'));
  const [bload, setbload] = useState(false);
  const [sload, setsload] = useState(false);
  const [allbiz, setallbiz] = useState([]);
  const [allstores, setallstores] = useState([]);
  const [allfeatures, setallfeatures] = useState([]);
  const [sname, setsname] = useState('');
  const [stoken, setstoken] = useState('');
  const [btoken, setbtoken] = useState('');
  const [display, setdisplay] = useState(false);

  let history = useHistory();

  // Set Biz and Update Store
  function setbiztoken(e) {
    setbtoken(e)
    loadStores(e)
  }
  function loadStores(e) {
    setallstores([])
    getStore(e)
    setdisplay(true)
  }
  // Set Biz and Update Store


  // Get User Access
  // Get User Access
  function getFeatures(e) {
    setstoken(e)
    getStoreData(e)
    const data = new FormData();
    data.append("staffusertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserAccess`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
        } else {
          setallfeatures(res.data);
          localStorage.setItem('features', JSON.stringify(res.data))
          getbizData()
        }
      })
      .catch((error) => {
      });

  }
  // Get User Access
  // Get User Access


  //   Gain Access Function
  //   Gain Access Function
  function gainAccess(e) {
    e.preventDefault()
    setissending(true);
    if (btoken === '') {
      setissending(false);
      toast.error("Select a Business!");
    } else {
      if (stoken === '') {
        setissending(false);
        toast.error("Select a Store!");
      } else {
        localStorage.setItem('btoken', btoken)
        localStorage.setItem('stoken', stoken)
        setTimeout(() => {
          setissending(false);
          if (localStorage.getItem('active') === '0') {
            history.push("/settings/noAccess");
            // setTimeout(() => {
            //   toast.warn('This Business is Inactive...');
            // }, 10);
          } else {
            if (allfeatures) {
              history.push("/dashboard");
              setTimeout(() => {
                toast.success('Welcome back...');
              }, 10);
            }
          }
        }, 2000);
      }
    }
  }
  //   Gain Access Function
  //   Gain Access Function



  //  Get Business Details Function
  const getbizData = () => {
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", btoken);
    data.append("apptoken", apptoken);
    axios
      .post(`${endpoint}/getBusinessDetails`, data,)
      .then((res) => {
        if (res.data.success == false) {
        } else {
          localStorage.setItem('bData', JSON.stringify(res.data.details))
          localStorage.setItem('bname', res.data.details.name)
          localStorage.setItem('active', res.data.details.active)
        }
      }).catch((error) => {
      });
  };
  useEffect(() => {
    getbizData();
  }, 0);
  //  Get Business Details Function

  //  Get All Business
  function getBiz(e) {
    setbload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserBusinesses`, data)
      .then((res) => {
        if (res.data.success === false) {
          setbload(false);
          toast.warn(res.data.message);
        } else {
          if (res.data.businesses === false) {
            setbload(false);
            setallbiz([]);
            history.push("/create-business")
            setTimeout(() => {
              toast.warn("You need to Create a Company Profile")
            }, 10);

          } else {
            setbload(false);
            setallbiz(res.data.businesses);
          }
        }
      })
      .catch((error) => {
        setbload(false);
        toast.error("Network Error!");
      });
  }
  useEffect(() => {
    getBiz()
  }, []);
  const biz = allbiz?.map((({ name, btoken }, i) => {
    return <option key={i} value={btoken}> {name} </option>;
  }));

  //  Get All Business

  //  Get All Store
  function getStore(e) {
    setsload(true);
    const data = new FormData();
    data.append("usertoken", token);
    data.append("btoken", e);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getUserStores`, data)
      .then((res) => {
        if (res.data.success === false) {
          setsload(false);
          toast.warn(res.data.message);
        } else {
          setsload(false);
          setallstores(res.data);
        }
      })
      .catch((error) => {
        setsload(false);
        toast.error("Network Error!");
      });
  }
  const stores = allstores?.map((({ storename, stoken }, i) => {
    return <option key={i} value={stoken}> {storename} </option>;
  }));

  //  Get All Store

  //  GetStore Details Function

  function getStoreData(e) {
    const data = new FormData();
    data.append("btoken", btoken);
    data.append("stoken", e);
    data.append("usertoken", token);
    data.append("apptoken", apptoken);

    axios
      .post(`${endpoint}/getStoreDetails`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success === false) {
        } else {
          localStorage.setItem("sname", res.data.name);
        }
      })
      .catch((error) => {
      });

  }
  useEffect(() => {
    getStoreData();
  }, []);
  //  GetStore Details Function

  // For Those without a store and account is inactive
  // function toDashboard() {
  //   if (btoken) {
  //     getbizData()
  //     localStorage.setItem('btoken', btoken)
  //     setTimeout(() => {
  //       history.push('/dasboard')
  //     }, 1000);
  //   }else{
  //     toast.error("Select a Business!");
  //   }
  // }
  // For Those without a store and account is inactive

  return (
    <>
      <Navbar />

      <section className="login">
        <div className="container">
          <div className="form col-md-6 ml-auto mr-auto shadow br-sm light-bg">
            <div className="container">
              <div className="text-center mb-5 pry-text">
                <h5>Welcome Back, {name}</h5>
                <small>Select the Business and Store you want to access, Let’s get you into your RENI account.</small>
              </div>
              <form enctype="multipart/form-data">
                <div className="row justify-content-center text-left">
                  {bload && <> <ClipLoader color={"#023676"} loading={bload} speedMultiplier="1.2" size='20' /> loading business(es) </>}

                  <div className="col-md-12 ">
                    <label className="mb-0"> Business:</label>
                    <select
                      name="biz"
                      className="input-style"
                      onChange={(e) => setbiztoken(e.target.value)}
                    >
                      <option value="" selected>
                        Select Business
                      </option>
                      {biz}
                    </select>
                  </div>
                  {sload && <> <ClipLoader color={"#023676"} loading={sload} speedMultiplier="1.2" size='20' /> loading store(s) </>}
                  <div className={`col-md-12 ${display ? 'show' : 'hide'}`}>
                    <label className="mb-0"> Store:</label>
                    <select
                      name="store"
                      className="input-style"
                      onChange={(e) => getFeatures(e.target.value)}
                    >
                      <option value="">
                        Select Store
                      </option>
                      {stores}
                    </select>
                  </div>

                  <div className="col-md-12 mx-auto text-center">
                    <div className="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button type="submit" className="btn btn-pry m-0 w-100" disabled>
                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                          </button>
                        </>
                      ) : (
                        <>

                          <button
                            type="submit"
                            className="btn btn-pry m-0 w-100"

                            onClick={(e) => gainAccess(e)}
                          >

                            Submit
                          </button>
                        </>
                      )}
                    </div>

                    <hr className="m-2" />
                    <div className="text-center">
                      <small>
                        Don't have a Business
                        <span >
                          <Link to="/create-business" className="pry-text"> Create it here</Link>
                        </span>
                        .
                      </small>
                    </div>
                    {/* <div className="text-center">
                      <small>
                        Don't have a Store
                        <span className="pry-text ml-1"
                        onClick={e=>toDashboard()}
                        >
                        Dashboard 
                        </span>
                        .
                      </small>
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
      />
    </>
  );
}

export default SetProfile;
