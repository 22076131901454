import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./Components/User/User.css";
import "./Components/Homepage/Homepage.css";
import ScrollToTop from "./ScrollToTop";
import PageNotFound from "./PageNotFound";

// Homepage
import Homepage from './Pages/Homepage'
import AboutPage from './Pages/AboutPage'

// User
import Dashboard from "./Pages/UserArea/Dashboard";
import Inventory from "./Pages/UserArea/Inventory";
import Expenses from "./Pages/UserArea/Expenses";
import Customer from "./Pages/UserArea/Customer";
import Invoice from "./Pages/UserArea/Invoice";
import SalesRecord from "./Pages/UserArea/SalesRecord";
import Settings from "./Pages/UserArea/Settings";
import AcctStatement from "./Pages/UserArea/AcctStatement";
import UserLogin from "./Components/Homepage/Auth/UserLogin";
import UserReg from "./Components/Homepage/Auth/UserReg";
import CreateBizProfile from "./Components/Homepage/Auth/CreateBizProfile";
import CreateStore from "./Components/Homepage/Auth/CreateStore";
import ProtectUser from "./protectUser";
import Item from "./Pages/UserArea/Item";
import InvoiceData from "./Components/User/Invoice-Sales/Invoice/InvoiceData";
import Print from "./Components/User/Invoice-Sales/Invoice/Print";
import Test from "./Components/User/Invoice-Sales/SalesRecord/Test";
import SetProfile from "./Components/Homepage/Auth/SetProfile";
import MakePayment from "./Components/User/Billing/Packages/MakePayment";
import Billing from "./Pages/UserArea/Billing";
import Packages from "./Pages/UserArea/Packages";
import NoUserAccess from "./Pages/UserArea/NoUserAccess";
import Report from "./Pages/UserArea/Report";
import BizReport from "./Components/User/Report/BizReport";
import AllStoreReport from "./Components/User/Report/StoreReport/AllStoreReport";
import EachStoreReport from "./Components/User/Report/StoreReport/EachStoreReport";
import Notification from "./Pages/UserArea/Notification";
import Suppliers from "./Pages/UserArea/Suppliers";
import NoPageAccess from "./Pages/UserArea/NoPageAccess";
import BizFilterReport from "./Components/User/Report/BizFilterReport";
import StoreFilterReport from "./Components/User/Report/StoreReport/StoreFilterReport";
import Debtor from "./Pages/UserArea/Debtor";
import OutOfStock from "./Components/User/Inventory/OutOfStock";
import Expired from "./Components/User/Inventory/Expired";
import CheckUserAuth from "./CheckUserAuth";
import HowTOUse from "./Pages/HowToUse";
import InventoryPrint from "./Components/User/Inventory/InventoryPrint";
import Waybill from "./Pages/UserArea/Waybill";
import PrintWaybill from "./Components/User/Waybill/PrintWaybill";

function App() {


  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <CheckUserAuth />
        <Switch>
          {/* Homepage */}

          <Route exact path="/" component={Homepage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/how-to-use" component={HowTOUse} />
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/register" component={UserReg} />
          <Route exact path="/noAccess" component={NoPageAccess} />

          {/* Homepage */}

          {/* User */}

          {/* Auth */}
          <ProtectUser exact path="/create-business" component={CreateBizProfile} />
          <ProtectUser exact path="/create-store" component={CreateStore} />
          <ProtectUser exact path="/set-profile" component={SetProfile} />\
          {/* Auth */}

          <ProtectUser exact path="/notifications" component={Notification} />

          <ProtectUser exact path="/dashboard" component={Dashboard} />

          <ProtectUser exact path="/inventory" component={Inventory} />
          <ProtectUser exact path="/inventory/items" component={Item} />
          <ProtectUser exact path="/inventory/out-of-stock" component={OutOfStock} />
          <ProtectUser exact path="/inventory/expired" component={Expired} />
          <ProtectUser exact path="/inventory/print" component={InventoryPrint} />

          <ProtectUser exact path="/waybill" component={Waybill} />
          <Route exact path="/waybill/:id" component={PrintWaybill} />

          <ProtectUser exact path="/expenses" component={Expenses} />

          <ProtectUser exact path="/customers" component={Customer} />

          <ProtectUser exact path="/suppliers" component={Suppliers} />

          <ProtectUser exact path="/debt-management" component={Debtor} />

          <ProtectUser exact path="/sales" component={SalesRecord} />
          <ProtectUser exact path="/sales/invoice" component={Invoice} />
          <ProtectUser exact path="/sales/invoice/:id" component={InvoiceData} />
          <Route exact path="/invoice/:id" component={Print} />

          <ProtectUser exact path="/account-statement" component={AcctStatement} />

          <ProtectUser exact path="/reports" component={Report} />
          <ProtectUser exact path="/reports/business" component={BizReport} />
          <ProtectUser exact path="/reports/business/filter" component={BizFilterReport} />
          <ProtectUser exact path="/reports/stores" component={AllStoreReport} />
          <ProtectUser exact path="/reports/stores/:id" component={EachStoreReport} />
          <ProtectUser exact path="/reports/store/filter/:id" component={StoreFilterReport} />

          <ProtectUser exact path="/settings" component={Settings} />
          <ProtectUser exact path="/settings/billing" component={Billing} />
          <ProtectUser exact path="/settings/packages" component={Packages} />
          <ProtectUser exact path="/settings/packages/pay/:id" component={MakePayment} />
          <ProtectUser exact path="/settings/noAccess" component={NoUserAccess} />

          <ProtectUser exact path="/test" component={Test} />

          {/* User */}

          <Route exact path="*" component={PageNotFound} />


        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
