import React, { useState } from "react";
import ChooseStores from "./ChooseStores";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const NavDropdown = () => {

    const [sname] = useState(localStorage.getItem("sname"));
    const [uname] = useState(localStorage.getItem("uname"));
    const [bname] = useState(localStorage.getItem("bname"));
    const [umail] = useState(localStorage.getItem("umail"));
    const [ppic] = useState(localStorage.getItem("ppic"));
    const [bData] = useState(JSON.parse(localStorage.getItem('bData')));
    const [token] = useState(localStorage.getItem("token"));

    // Open All Stores Modal
    const [showremove, setShowremove] = useState(false);
    const handleCloseremove = () => setShowremove(false);
    const handleShowremove = () => setShowremove(true);

    function workModal() {
        handleShowremove();
    }
    // Open All Stores Modal

    // Logout Function
    let history = useHistory();
    const HandleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("btoken");
        localStorage.removeItem("stoken");
        localStorage.removeItem("uname");
        localStorage.removeItem("umail");
        localStorage.removeItem("ppic");
        localStorage.removeItem("bData");
        localStorage.removeItem("sname");
        localStorage.removeItem("stores");
        localStorage.removeItem("features");
        localStorage.removeItem("user");
        localStorage.removeItem("bname");
        localStorage.removeItem("active");
        toast.success('Logout successful! Redirecting...')
        setTimeout(() => {
            history.push("/");
        }, 2000);
    };
    // Logout Function


    // When leaving a Business to create or add a new one clear all Biz Related data from localstorage

    const clearBizData = () => {
        localStorage.removeItem("btoken");
        localStorage.removeItem("stoken");
        localStorage.removeItem("bData");
        localStorage.removeItem("bname");
        localStorage.removeItem("sname");
        localStorage.removeItem("stores");
        localStorage.removeItem("features");
        localStorage.removeItem("active");
    }
    // When leaving a Business to create or add a new one clear all Biz Related data from localstorage

    // Change Business
    const changeBiz = (e) => {
        e.preventDefault()
        clearBizData()
        toast.success('Redirecting...')
        setTimeout(() => {
            history.push("/set-profile");
        }, 1000);
    };
    function bizChangeConfirm(e) {
        if (window.confirm(`Are you sure you want to logout from this Business and Change Business ?`)) {
            changeBiz(e);
        }
    }

    // Change Business

    // Create Business
    const createBiz = (e) => {
        e.preventDefault()
        clearBizData()
        toast.success('Redirecting...')
        setTimeout(() => {
            history.push("/create-business");
        }, 1000);
    };
    function bizCreateConfirm(e) {
        if (window.confirm(`Are you sure you want to logout from this Business and Create Business ?`)) {
            createBiz(e);
        }
    }

    // Create Business

    // Route to Reni Trust
    function editConfirm(e) {
        e.preventDefault()
        if (window.confirm('You will redirected to your Renitrust Account, Confirm?')) {
            window.open('https://renitrust.com/user/profile', '_blank');
        }
    }
    // Route to Reni Trust

    return (
        <>
            <div
                className="dropdown-menu dropdown-menu-right text-center"
                aria-labelledby="navbarDropdownMenuLink"
            >
                <div className="dropdown-item pry-bold-text border-bottom px-5">

                    <center>
                        <div className="py-4 ppic mb-4" >
                            <img src={ppic} alt={uname} width="100%" />
                        </div>
                    </center>
                    <div className="">
                        <span className="font-weight-bold">{uname} </span> <br />
                        <span className="grey-text small-text">{umail} </span>
                        <a className="p-0 m-0 border-left-none" onClick={(e) => editConfirm(e)} >
                            <div className="br-xlg sec-bold-border px-3 py-1 my-3 pry-bold-text">
                                <small className="font-weight-bold">
                                    Manage your Reni Account
                                </small>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="text-left dropdown-item pry-bold-text border-bottom">
                    <div className="d-flex align-items-center justify-content-between  mt-2">
                        <p className="small-text my-0 text-truncate">
                            <i className="bx bxs-store mr-2 " />
                            {sname}
                        </p>
                        <small className="pry-text"> <i>- Store</i> </small>
                    </div>
                    <p className="small-text my-2" onClick={(e) => workModal()}>
                        <i className="bx bxs-store mr-2 " />
                        Change Store
                    </p>
                    {token === bData?.owner_token ?
                        <Link to="/create-store" className="p-0 m-0 border-left-none">
                            <p className="pry-bold-text small-text my-2">
                                <i className="bi bi-cart-plus-fill mr-2 " />
                                Create a new Store
                            </p>
                        </Link> :
                        <></>
                    }
                    <div className="border-bottom"></div>
                    <div className="d-flex align-items-center justify-content-between  mt-2">
                        <p className="small-text my-0 text-truncate">
                            <i className="bx bxs-store mr-2 " />
                            {bname}
                        </p>
                        <small className="pry-text"> <i>- Business</i> </small>
                    </div>
                    <a onClick={e => bizChangeConfirm(e)} className="p-0 m-0 border-left-none">
                        <p className="pry-bold-text small-text my-2">
                            <i className="bx bxs-store mr-2 " />
                            Change Business
                        </p>
                    </a>
                    <a onClick={e => bizCreateConfirm(e)} className="p-0 m-0 border-left-none">
                        <p className="pry-bold-text small-text my-2">
                            <i className="bi bi-cart-plus-fill mr-2 " />
                            Create a new Business
                        </p>
                    </a>
                </div>

                <div className="dropdown-item pry-bold-text py-0 pt-1">
                    <div className="br-sm px-3 py-1 my-1" onClick={HandleLogout} >
                        <small className="font-weight-bold red-text">
                            Sign out of all accounts
                        </small>
                    </div>
                </div>

            </div>


            {/* Choose Store Modal */}
            <ChooseStores
                show={showremove}
                onHide={handleCloseremove}
                animation={false}
            />
            {/* Choose Store Modal */}

        </>
    );
}

export default NavDropdown;