import React, { useState } from 'react';
import BackButton from '../BackButton';
import AddSupplier from './AddSupplier';

function SupplierTitle(props) {

  const [showremove1, setShowremove1] = useState(false);
  const handleCloseremove1 = () => setShowremove1(false);
  const handleShowremove1 = () => setShowremove1(true);

  function workModal1() {
    handleShowremove1();
  }

  return (
    <div className="dash-title">
      <div className="row align-item-center">
        <div className="col pry-bold-text d-flex align-item-center">
          <span className="h5 font-weight-bold mt-1"> SUPPLIER </span>
        </div>
        <div className="col ">
          <button className="btn btn-pry float-right " onClick={(e) => workModal1()}>
            <i className="bx bx-plus mr-2 " />
            Add New
          </button>
        </div>
      </div>


      {/* Add Supplier Modal */}
      <AddSupplier
        show={showremove1}
        onHide={handleCloseremove1}
        animation={false}
        getSupplier={e => props.getSupplier()}
      />
      {/* Add Supplier Modal */}
    </div>
  );
}

export default SupplierTitle;
