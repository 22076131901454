import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";

function CreateBizProfile() {
  const [apptoken] = useState(process.env.REACT_APP_APPTOKEN);
  const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
  const [token] = useState(localStorage.getItem("token"));
  const [issending, setissending] = useState(false);
  let history = useHistory();

  // Form Fields
  const [formField, setformField] = useState({ name: '', address: '', phone: '', no_worker: '', des: '', sname: '', slocation: '' });
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Fields

  //   Setup Business Function
  //   Setup Business Function
  function setupCompany(e) {
    e.preventDefault();
    if (formField.name && formField.address && formField.phone && formField.no_worker && formField.des && formField.sname) {
      setissending(true);

      const data = new FormData();
      data.append("name", formField.name);
      data.append("address", formField.address);
      data.append("phone", formField.phone);
      data.append("no_workers", formField.no_worker);
      data.append("description", formField.des);

      data.append("sname", formField.sname);
      data.append("slocation", formField.slocation);

      data.append("usertoken", token);
      data.append("apptoken", apptoken);

      axios
        .post(`${endpoint}/createBusinessProfile`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            toast.warn(res.data.message);
            setissending(false);
          } else {
            setissending(false);
            localStorage.setItem("btoken", res.data.btoken);
            localStorage.setItem("stoken", res.data.stoken);
            history.push("/dashboard")
            setTimeout(() => {
              toast.success(res.data.message);
            }, 10);
          }
        })
        .catch((error) => {
          setissending(false);
          toast.error("Network Error!");
        });
    } else {
      setissending(false);
      toast.error("Empty Fields!");
    }
  }
  //   Setup Business Function
  //   Setup Business Function

  return (
    <>
      <Navbar />

      <section className="login">
        <div className="container">
          <div className="form col-md-6 ml-auto mr-auto shadow br-sm light-bg mb-5">
            <div className="container">
              <div className="text-center mb-5 pry-text">
                <h5>Create a Business</h5>
                <small>Create your business to begin your journey.</small>
              </div>
              <form enctype="multipart/form-data" onSubmit={(e) => setupCompany(e)}
>
                <div className="row justify-content-center text-left">
                  <div className="col-md-12 ">
                    <label className="mb-0">Business Name:</label>
                    <input
                      type="text"
                      className=" input-style"
                      name="name"
                      placeholder="e.g. Fireswitch ltd"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-12 ">
                    <label className="mb-0"> Phone Number:</label>
                    <input
                      type="number"
                      className=" input-style"
                      name="phone"
                      placeholder="080123456789"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Number of workers:</label>
                    <input
                      type="text"
                      className=" input-style"
                      name="no_worker"
                      placeholder="e.g. 15"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Business Address:</label>
                    <textarea
                      type="text"
                      className=" input-style"
                      name="address"
                      placeholder="Address"
                      onChange={handleChange}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="col-md-12 ">
                    <label className="mb-0"> Business Description:</label>
                    <textarea
                      type="text"
                      name="des"
                      className="input-style textarea-style"
                      placeholder="e.g. We are a Tech Company in Ibadan"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <hr className="border-gray w-100" />
                  <div className="text-center mb-5 pry-text">
                    <h5>Add a Store / Branch</h5>
                    <small>Your Business needs to have a Store / Branch.</small>

                  </div>

                  <div className="col-md-12 ">
                    <label className="mb-0">Store Name:</label>
                    <input
                      type="text"
                      className=" input-style"
                      name="sname"
                      placeholder="e.g. Reni Store"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-12 ">
                    <label className="mb-0"> Store Location:</label>
                    <textarea
                      type="text"
                      className=" input-style"
                      name="slocation"
                      placeholder="Store Location"
                      onChange={handleChange}
                      autoComplete="false"
                    />
                  </div>


                  <div className="col-md-12 mx-auto text-center">
                    <div className="user-btn mb-4 mr-auto text-center">
                      {issending ? (
                        <>
                          <button type="submit" className="btn btn-pry m-0 w-100" disabled>
                            <i className="bx bx-loader bx-spin bx-sm white-text" />
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-pry m-0 w-100"
                          >
                            Submit
                          </button>
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      <small>
                        {" "}
                        By clicking the button above, you agree to our{" "}
                        <span className="pry-text">
                          <Link>Terms & Conditions</Link>
                        </span>{" "}
                        and{" "}
                        <span className="pry-text">
                          <Link>Privacy Policy </Link>
                        </span>{" "}
                        .
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
      />
    </>
  );
}

export default CreateBizProfile;
